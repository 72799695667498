.sk-coins {
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.sk-coins__row {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-template-rows: 40px 46px 40px;
  align-items: center;
  padding: 0 16px 10px;

  @media (min-width: $tablet-width) {
    grid-template-columns: 33% 1fr 40%;
    grid-template-rows: 44px 38px;
    padding-bottom: 5px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 165px 80px 114px 115px 116px 170px 1fr;
    grid-column-gap: 20px;
    grid-template-rows: 50px;
    padding-bottom: 0;
  }
}

.sk-coins__row--heading {
  display: none;

  @media (min-width: $desktop-width) {
    display: grid;
    grid-template-columns: 165px 80px 114px 115px 116px 170px 1fr;
    grid-template-rows: 40px;
    grid-column-gap: 20px;
    padding: 0 16px;
  }
}

.sk-coins__heading {
  @media (min-width: $desktop-width) {
    justify-self: end;
    width: 70px;
    height: 20px;

    background-color: #dde4ec;
    border-radius: 5px;
  }
  &--chart {
    justify-self: start;
    grid-column: 7;
  }
}

.sk-coins__heading:first-of-type {
  @media (min-width: $desktop-width) {
    justify-self: start;
    width: 60px;

    margin-left: 32px;
  }
}

.sk-coins__instrument-wrapper {
  justify-self: center;

  grid-column: 1/2;
  grid-row: 1/2;

  display: flex;
  align-items: center;
  width: 162px;

  @media (min-width: $tablet-width) {
    justify-self: auto;
  }
}

.sk-coins__logo {
  width: 20px;
  height: 20px;
  margin-right: 12px;

  background-color: #e1ebf8;
  border-radius: 5px;
}

.sk-coins__instrument {
  width: 60px;
  height: 28px;

  background-color: #e1ebf8;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 60px;
  }
}

.sk-coins__amount {
  width: 92px;
  height: 34px;

  background-color: #e1ebf8;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 90px;
  }

  @media (min-width: $desktop-width) {
    justify-self: end;

    width: 70px;
    height: 20px;
  }

  &--price {
    grid-column: 2/3;
    grid-row: 1/2;

    height: 20px;
  }

  &--change {
    display: none;

    @media (min-width: $desktop-width) {
      display: block;
      grid-column: 3/4;
      grid-row: 1/2;
    }
  }

  &--market-cap {
    grid-column: 1/2;
    grid-row: 2/3;

    justify-self: center;
    margin-right: 70px;

    @media (min-width: $tablet-width) {
      justify-self: auto;
      margin-right: 0;
    }

    @media (min-width: $desktop-width) {
      justify-self: end;

      grid-column: 4/5;
      grid-row: 1/2;
    }
  }

  &--volume {
    grid-column: 2/3;
    grid-row: 2/3;

    @media (min-width: $desktop-width) {
      grid-column: 5/6;
      grid-row: 1/2;
    }
  }
}

.sk-coins__chart {
  grid-column: 1/-1;
  grid-row: 3/4;

  height: 40px;
  width: 100%;

  @media (min-width: $tablet-width) {
    align-self: center;
    grid-column: 3/4;
    grid-row: 1/3;
  }

  @media (min-width: $desktop-width) {
    grid-column: 7;
    grid-row: 1/2;

    width: 100%;
    height: 22px;
  }
}
