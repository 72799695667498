.main {
  @media (max-width: $before-tablet-width) {
    flex-grow: 1;
  }
}

.main--landing {
  flex-grow: 1;
  min-height: 400px;


  @media (min-width: $tablet-width) {
    min-height: 400px;
  }

  @media (min-width: $desktop-width) {
    min-height: 700px;
  }
}
