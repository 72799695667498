.referrals-balance {
  min-height: 152px;
  box-sizing: border-box;

  @media (min-width: $desktop-width) {
    min-height: 160px;
  }
}

.referrals-balance__intro-wrapper {
  display: flex;
  align-items: baseline;

  column-gap: 20px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.referrals-balance__intro-commission {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.referrals-balance__intro-percent {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 17px;
    line-height: 20px;
  }
}

.referrals-balance__table {
  @include list-reset;
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 10px;

  @media (min-width: $tablet-width) {
    row-gap: 16px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 260px 230px 263px;
    row-gap: 13px;
  }
}

.referrals-balance__item {
  display: flex;
  flex-direction: column;
  width: 110px;

  @media (min-width: $tablet-width) {
    gap: 3px;
    width: auto;
  }

  &--commission {
    grid-column: 1;
    grid-row: 1;

    @media (min-width: $desktop-width) {
      grid-column: 1/4;
      grid-row: 1;
    }
  }

  &--balance {
    grid-column: 2/4;
    grid-row: 1;

    @media (min-width: $desktop-width) {
      grid-column: 2/4;
      grid-row: 1;
    }
  }

  &--referrals {
    grid-column: 1/2;
    grid-row: 2/2;

    @media (min-width: $tablet-width) {
      grid-column: 2/4;
      grid-row: 1;
    }

    @media (min-width: $desktop-width) {
      grid-column: 3/4;
      grid-row: 1;
    }
  }

  &--earnings {
    grid-column: 1/2;
    grid-row: 2/2;

    @media (min-width: $tablet-width) {
      grid-column: 3/4;
      grid-row: 1;
    }

    @media (min-width: $desktop-width) {
      grid-column: 3/4;
      grid-row: 1;
    }
  }

  &--spent {
    grid-column: 2/2;
    grid-row: 2/2;

    @media (min-width: $tablet-width) {
      grid-column: 1/4;
      grid-row: 2;
    }

    @media (min-width: $desktop-width) {
      grid-column: 4/4;
      grid-row: 1;
    }
  }

  &--invested {
    grid-column: 1/2;
    grid-row: 1/2;

    @media (min-width: $tablet-width) {
      grid-column: 1/3;
      grid-row: 1;
    }

    @media (min-width: $desktop-width) {
      grid-column: 2/4;
      grid-row: 1;
    }
  }

  &--started {
    grid-column: 2/2;
    grid-row: 1/2;

    @media (min-width: $tablet-width) {
      grid-column: 3/3;
      grid-row: 1;
    }

    @media (min-width: $desktop-width) {
      grid-column: 4/4;
      grid-row: 1;
    }
  }
}

.referrals-balance__title {
  width: 112px;
  margin-left: 26px;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  // text-wrap: nowrap;
  // white-space: nowrap;

  @media (min-width: $tablet-width) {
    width: auto;
  }
}

.referrals-balance__wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.referrals-balance__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 16px;
  margin: 0;
}

.referrals-balance__icon {
  display: flex;
  color: #828282;

  &--commission {
    width: 16px;
    height: 16px;
    margin-top: 0.5px;
  }

  &--balance {
    width: 18px;
    height: 13px;
    margin-top: 2px;
  }

  &--invested {
    width: 21px;
    height: 16px;

    margin-top: -1px;
    padding: 1px;
  }

  &--earnings {
    width: 16px;
    height: 16px;
    margin-top: 0.5px;
  }

  &--spent {
    width: 19px;
    height: 13px;
    margin-top: 2px;
  }

  &--referrals {
    width: 15px;
    height: 15px;

    margin-top: -1px;
    padding: 1px;
  }

  &--started {
    width: 16px;
    height: 15px;

    margin-top: -1px;
    padding: 1px;
  }
}

.referrals-balance__amount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 17px;
  }

  &--plus {
    color: #2bbc8b;
  }

  &--minus {
    color: #f93232;
  }
}

.refferals-summary__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(130px, 1fr));
  row-gap: 10px;
  padding: 15px 0;
  justify-items: center;
  margin: 0;

  @media (min-width: $tablet-width) {
    grid-template-columns: minmax(205px, 1fr) minmax(125px, 1fr) minmax(
        113px,
        1fr
      );
    row-gap: 15px;
    justify-items: flex-start;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 144px 219px 162px 219px;
  }
}

.refferals-summary__list:not(:last-of-type) {
  border-bottom: solid 1px #cbd5e1;
}
