.sk-coin-canvas {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-bottom: 13px;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.sk-coin-canvas__percent {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.sk-coin-canvas__percent-item {
  display: flex;
}

.sk-coin-canvas__percent-item:first-child {
  margin-right: 30px;
}

.sk-coin-canvas__percent-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;

  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $tablet-width) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.sk-coin-canvas__percent-text {
  width: 45px;
  height: 20px;

  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    height: 24px;
  }
}

.sk-coin-canvas__chart {
  height: 160px;
  margin: 10px 45px 30px;

  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    height: 246px;
  }

  @media (min-width: $desktop-width) {
    height: 230px;
  }
}

.sk-coin-canvas__controls {
  display: flex;
  justify-content: center;
  padding: 4px 0;
}

.sk-coin-canvas__controls-item {
  width: 40px;
  height: 20px;

  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    height: 25px;
  }
}

.sk-coin-canvas__controls-item:first-child {
  margin-right: 30px;
}
