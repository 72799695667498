@import "../mixins/general-mixins.scss";

.block {
  @include block-shadow;
  padding: 12px 16px 5px;

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &--active {
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.3);
  }

  &--more-bottom {
    padding-bottom: 15px;
  }

  &--chart {
    padding: 15px 10px;
  }

  &--step {
    padding: 12px 16px 15px 46px;

    @media (min-width: $tablet-width) {
      padding-left: 56px;
    }
  }
}

.block__heading-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.block__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #555555;

  margin-top: 0;
  margin-bottom: 10px;

  &--alert {
    color: #f93232;
  }
}

.block__hint {
  position: relative;
  margin-top: 2.5px;
}
