
.reviews__heading-hidden {
  display: none;
}

.reviews__list {
  @include list-reset;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;

    max-height: 720px;
    margin-bottom: 35px;
  }

  @media (min-width: $desktop-width) {
    gap: 20px;
    max-height: 560px;
  }
}

.reviews__item {
  position: relative;
  margin-bottom: 20px;
  padding: 47px 20px 20px;
  box-sizing: border-box;

  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
    padding: 49px 16px 20px 20px;

    width: calc((100% - 30px) / 2);
  }

  @media (min-width: $desktop-width) {
    width: 337px;
  }

  &--sb {
    @media (min-width: $tablet-width) {
      order: 3;
    }

    @media (min-width: $desktop-width) {
      order: 2;
    }
  }

  &--valentin {
    @media (min-width: $tablet-width) {
      order: 1;
    }

    @media (min-width: $desktop-width) {
      order: 1;
    }
  }

  &--a {
    @media (min-width: $tablet-width) {
      order: 4;
    }

    @media (min-width: $desktop-width) {
      order: 2;
    }
  }

  &--mz {
    @media (min-width: $tablet-width) {
      order: 5;
    }

    @media (min-width: $desktop-width) {
      order: 0;
    }
  }

  &--vg {
    @media (min-width: $tablet-width) {
      order: 2;
    }
  }

  &--andrew {
    @media (min-width: $desktop-width) {
      order: 4;
    }
  }
}

.reviews__icon {
  position: absolute;
  top: 14px;
  left: 20px;

  width: 142px;
  height: 25px;

  color: #3b82f6;
}

.reviews__inner-heading {
  margin-top: 0;
  margin-bottom: 8px;

  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    margin-bottom: 10px;
  }

  @media (min-width: $desktop-width) {
    line-height: 25px;
  }
}

.reviews__opinion {
  margin-top: 0;
  margin-bottom: 8px;

  font-size: 12px;
  line-height: 17px;
  color: #555555;

  @media (min-width: $tablet-width) {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 18px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.reviews__author {
  margin: 0;

  font-size: 11px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}
