.overview-started {
  display: contents;
}

.overview-started__total-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.overview-started__total-wrapper:nth-of-type(1) {
  grid-column: 1/2;
  grid-row: 1/2;
}

.overview-started__total-wrapper:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}

.overview-started .overview-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      grid-column: 1/2;
    }
  }

  &--current {
    @media (min-width: $tablet-width) {
      grid-column: 7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 7;
    }
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 5;
    }
    @media (min-width: $desktop-width) {
      min-width: 45px;
      grid-column: 5;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 6/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 8;
    }
  }

  &--your-changes {
    @media (min-width: $tablet-width) {
      grid-column: 3;
    }

    @media (min-width: $desktop-width) {
      grid-column: 3/4;
    }
  }
}
