.renew-keys {
  position: relative;
  z-index: 0;
}

.renew-keys__wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.renew-keys__message {
  min-height: 16px;
  width: 100%;
}
