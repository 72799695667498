.demo-insight {
  display: flex;
  flex-direction: column;
}


.demo-insight__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  @media (min-width: $tablet-width) {
    flex-direction: column;
    align-self: center;
    margin-bottom: 25px;
  }
}

.demo-insight__btn {
  align-self: center;
}
