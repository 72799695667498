.nav {
  @media (max-width: $before-tablet-width) {
    transition: all ease 0.5s;

    &--opened {
      flex-grow: 1;

      height: auto;
      padding-bottom: 50px;
      opacity: 1;
    }

    &--closed {
      height: 0;
      padding-bottom: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  @media (min-width: $tablet-width) {
    width: 245px;
    margin-bottom: 92px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 0;
  }
}

.nav__nav-extra {
  display: block;

  @media (min-width: $desktop-width) {
    position: absolute;
    top: 22px;
    left: 282px;

    display: flex;
  }
}

.nav__list {
  @include list-reset;

  @media (min-width: $tablet-width) {
    position: relative;
  }
}

.nav__link {
  position: relative;

  display: flex;
  padding: 16px 20px 15px 0;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    align-items: center;
    padding: 11.5px 10px 11.5px 0;
  }
}

.nav__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 29px;
  margin-right: 10px;

  @media (min-width: $tablet-width) {
    width: 60px;
    margin-right: 0;
  }
}

.nav__icon {
  display: flex;
  color: #555555;

  @media (min-width: $tablet-width) {
    color: #828282;
  }

  &--overview {
    width: 16px;
    height: 13px;

    @media (min-width: $tablet-width) {
      width: 13px;
      height: 10px;
    }
  }

  &--accounts {
    width: 20px;
    height: 14px;

    @media (min-width: $tablet-width) {
      width: 15px;
      height: 11px;
    }
  }

  &--referral {
    width: 26px;
    height: 10px;

    @media (min-width: $tablet-width) {
      width: 15px;
      height: 11px;
    }
  }

  &--affiliate {
    width: 17px;
    height: 15px;

    @media (min-width: $tablet-width) {
      width: 16px;
      height: 13px;
    }
  }

  &--payments {
    width: 20px;
    height: 12px;

    @media (min-width: $tablet-width) {
      width: 13px;
      height: 11px;
    }
  }

  &--arrow {
    position: absolute;
    top: 50%;
    right: 19px;

    width: 8px;
    height: 6px;
    transform: translate(0, -50%);
  }
}

.nav__link-text {
  font-size: 16px;
  line-height: 29px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 14px;
  }
}

.nav__sublist {
  @include list-reset;
}

.nav__sublink {
  display: flex;
  align-items: center;
  padding: 4px 20px 4px 47px;

  color: #828282;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    align-items: flex-start;
    padding: 7.5px 10px 7.5px 54px;
  }
}

.nav__sublink-logo-wrapper {
  display: flex;
  width: 30px;
  justify-content: center;
  margin-right: 13px;

  font-size: 0;
  line-height: 0;

  @media (min-width: $tablet-width) {
    align-items: center;
    height: 25px;
    margin-top: 2px;
    margin-right: 7px;
  }
}

.nav__sublink-logo {
  width: 20px;

  @media (min-width: $tablet-width) {
    width: 18px;
  }

  &--binance-spot,
  &--binance-futures {
    width: 23px;

    @media (min-width: $tablet-width) {
      width: 22px;
    }
  }

  &--dydx {
    width: 18px;

    @media (min-width: $tablet-width) {
      width: 16px;
    }
  }

  &--okx {
    width: 17px;

    @media (min-width: $tablet-width) {
      width: 15px;
    }
  }

  &--bitget-futures {
    width: 18px;

    @media (min-width: $tablet-width) {
      width: 17px;
    }
  }

  &--phemex {
    width: 16px;

    @media (min-width: $tablet-width) {
      width: 15px;
    }
  }

}

.nav__sublink-text {
  font-size: 16px;
  line-height: 29px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
  }
}

.nav__link:hover,
.nav__sublink:hover {
  background-color: #eff6ff;
}

.nav__link:focus,
.nav__sublink:focus {
  background-color: #eff6ff;
  outline: 2px solid #dbeafe;
  outline-offset: -2px;
}

.nav__link:active,
.nav__sublink:active {
  border-color: #dbeafe;
  background-color: #dbeafe;
}

.nav__sublink-note {
  align-self: flex-end;
  margin-left: 10px;

  font-size: 15px;
  line-height: 28px;
  color: #a7a7a7;

  @media (min-width: $tablet-width) {
    margin-left: 5px;
    font-size: 13px;
  }
}

.nav__link--disabled,
.nav__sublink--disabled {
  pointer-events: none;
  opacity: 0.6;
}

.nav__notifications-amount {
  display: block;
  min-width: 16px;
  margin-top: 8px;
  margin-left: 5px;
  padding: 0 2px;
  height: 16px;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;

  background-color: #2563eb;
  border-radius: 8px;

  @media (min-width: $tablet-width) {
    top: 0;
    left: 35px;

    min-width: 18px;
    height: 18px;
    margin-top: 0;

    font-size: 11px;
    line-height: 18px;

    border-radius: 11px;
  }
}
.nav__referrals-hint {
  margin-top: 2px;
  margin-left: 7px;

  @media (min-width: $tablet-width) {
    margin-top: -1px;
  }
}

// For current nav item
.nav__link.active,
.nav__sublink.active {
  background-color: #dbeafe;
}
