.start {
  display: flex;
  flex-direction: column;
}

.start__list {
  @include list-reset;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    align-self: stretch;

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 143px);
    column-gap: 55px;
    row-gap: 20px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: repeat(auto-fit, 182px);
    column-gap: 106px;
  }
}

.start__item {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 135px;
  margin-bottom: 27px;
  padding-left: 155px;
  box-sizing: border-box;

  background-image: url("../../img/circles/circle-big.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 135px 135px;

  @media (min-width: $tablet-width) {
    align-items: flex-start;
    min-height: 140px;
    margin-bottom: 0;

    padding-top: 78px;
    padding-left: 0;

    background-size: 138px 138px;
  }

  @media (min-width: $desktop-width) {
    align-items: center;
    min-height: 162px;
    padding-top: 90px;

    background-size: 162px 162px;
    background-position: 11px 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 2px;

    top: 50%;
    left: 136px;
    transform: translate(0, -50%);
    background-color: #e3ebfe;

    border-radius: 1px;
    box-shadow: 6px 0 0 0 #e3ebfe, 12px 0 0 0 #e3ebfe;

    @media (min-width: $tablet-width) {
      top: 69px;
      left: 140px;

      width: 10px;
      box-shadow: 20px 0 0 0 #e3ebfe, 26px 0 0 0 #e3ebfe, 44px 0 0 0 #e3ebfe;
      transform: none;
    }

    @media (min-width: $desktop-width) {
      top: 80px;
      left: 190px;

      width: 15px;
      box-shadow: 30px 0 0 0 #e3ebfe, 44px 0 0 0 #e3ebfe, 75px 0 0 0 #e3ebfe;
    }
  }
}

.start__item:last-child {
  margin-bottom: 0;

  @media (min-width: $tablet-width) {
    &::before {
      content: none;
    }
  }
}

.start__number {
  position: absolute;
  top: -7px;
  left: 0;

  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    top: -13px;
    left: -2px;
    font-size: 70px;
    line-height: 95px;
  }

  @media (min-width: $desktop-width) {
    top: -20px;
    left: 21px;
    font-size: 80px;
    line-height: 109px;
  }
}

.start__info {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 135px;
  min-height: 54px;
  margin: 0;
  padding: 5px;
  box-sizing: border-box;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 143px;
    padding: 8px;
  }

  @media (min-width: $desktop-width) {
    width: 200px;
    font-size: 14px;
    line-height: 20px;
  }

  &--btn-login {
    font-family: "Open Sans", Arial, sans-serif;
    color: #555555;

    border: none;
    cursor: pointer;

    @include animation-lift-shadow-onhover-add;

    &:focus {
      box-shadow: none;
      outline: 2px solid #dbeafe;
    }

    &:active {
      box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
    }
  }
}

.start__btn {
  margin: 30px auto 0;
}
