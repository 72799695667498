.account-toggles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.account-toggles__text {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;
}

.account-toggles__btn {
  margin: 0;
}

.account-toggles__label {
  @media (min-width: $tablet-width) {
    width: 60px;
    height: 25px;
    font-size: 12px;
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 20px;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;

  border-radius: 7px;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.account-toggles__icon {
  display: flex;
  width: 20px;
  height: 20px;
}

// blue
.account-toggles--blue .account-toggles__label {
  color: #2563eb;
  border: 1px solid #2563eb;
}

.account-toggles--blue input:checked + .account-toggles__label,
.account-toggles--blue input:focus + .account-toggles__label {
  color: #ffffff;
  background-color: #3b82f6;
  border: none;
}

.account-toggles--blue input:hover + .account-toggles__label {
  color: #ffffff;
  background-color: #2563eb;
  border: none;
}

.account-toggles--blue input:active + .account-toggles__label {
  background-color: #2563eb;
  border: none;
}

// green

.account-toggles--green .account-toggles__label {
  color: #24ad15;
  border: 1px solid #24ad15;
}

.account-toggles--green input:checked + .account-toggles__label,
.account-toggles--green input:focus + .account-toggles__label {
  color: #ffffff;

  background-color: #26cf28;
}

.account-toggles--green input:hover + .account-toggles__label {
  color: #ffffff;
  background-color: #24ad15;
}

.account-toggles--green input:active + .account-toggles__label {
  background-color: #26cf28;
}
