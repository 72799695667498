.referrals-link {
  padding-top: 8px;
  padding-bottom: 18px;
}

.referrals-link__wrapper {
  display: flex;
  align-items: center;

  min-height: 35px;
  margin-bottom: 10px;
  padding-left: 0;

  @media (min-width: $tablet-width) {
    margin-bottom: 15px;
  }
}

.referrals-link__icon {
  display: flex;
  width: 35px;
  height: 35px;
  margin-right: 15px;
  color: #555;
}

.referrals-link__heading {
  margin: 2px 0 0;
}

.referrals-link__form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $desktop-width) {
    flex-direction: row;
    gap: 18px;
  }
}

.referrals-link__form-main-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 18px;

  @media (min-width: $desktop-width) {
    flex-direction: row;
  }
}

.referrals-link__form-wrapper {
  display: flex;
  flex-grow: 1;

  @media (min-width: $desktop-width) {
    flex-direction: row;
    flex-grow: 0;
    width: 440px;
  }
}

.referrals-link__field-wrapper {
  min-width: 150px;
  max-width: 421px;
  flex-grow: 1;
  margin-right: 6px;

  @media (min-width: $tablet-width) {
    margin-right: 8px;
  }
}

.referrals-link__field {
  width: 100%;
}

.referrals-link__btns-wrapper {
  display: flex;
  margin-right: -7px;
}

.referrals-link__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;

  &--share {
    margin-top: -1px;
  }
}
