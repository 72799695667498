.referrals-withdraw {
  padding-bottom: 1px;
}

.referrals-withdraw__form {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.referrals-withdraw__wrapper {
  display: flex;

  &--address {
    flex-grow: 1;
    max-width: 421px;
    margin-bottom: 10px;
  }

  &--network-amount {
    gap: 10px;
  }

  &--amount-main {
    flex-direction: column;
    margin-bottom: 2px;
  }

  &--amount-max {
    margin-bottom: 1px;
  }

  &--submit {
    flex-direction: column;
    margin-bottom: 2px;
  }
}

.referrals-withdraw__field {
  &--address {
    flex-grow: 1;
    display: flex;
  }

  &--amount {
    width: 120px;

    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &--network {
    flex-grow: 1;
    min-width: auto;
    max-width: 100px;
    box-sizing: border-box;
  }
}

.referrals-withdraw__submit-btn {
  margin-bottom: 1px;
}

.referrals-withdraw__message-amount {
  margin-right: -90px;
}
