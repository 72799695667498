.coins {
  @include main-padding;
  padding-bottom: 60px;

  @media (min-width: $desktop-width) {
  }
}

.coins__heading {
  @include outer-heading;
}

.coins__list {
  @include list-reset;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.coins__link {
  display: block;
  padding: 0 16px;

  &:hover {
    background-color: #eff6ff;
  }
}

.coins__link:hover .shadow--coins {
  &::after {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 75%,
      rgba(239, 246, 255, 1) 100%
    );
  }
}

.coins__wrapper {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  grid-column-gap: 20px;
  padding-bottom: 10px;

  @media (min-width: $tablet-width) {
    // % чтобы поле title инструмента не выпадало из контейнера
    grid-template-columns: 33% 1fr 37%;
    padding-bottom: 5px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 165px 80px 114px 115px 116px 170px 1fr;
    padding-bottom: 0;
  }
}

.coins__row:not(:last-child) .coins__wrapper {
  border-bottom: solid 1px #cbd5e1;
}

.coins__row--heading {
  display: none;

  @media (min-width: $desktop-width) {
    display: block;
    padding: 0 16px;
  }
}

.coins__wrapper-heading {
  display: grid;
  grid-template-columns: 165px 80px 114px 115px 116px 170px 1fr;
  grid-column-gap: 20px;
  border-bottom: solid 1px #cbd5e1;
}

.coins__row-heading {
  display: flex;
  margin: 0;
  padding: 10px 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1;
  color: #828282;

  @media (min-width: $desktop-width) {
    justify-self: flex-end;

    &--coin {
      padding-left: 30px;
      justify-self: flex-start;
    }

    &--chart {
      justify-self: flex-start;
      grid-column: 7;
    }

    &--change,
    &--market-cap,
    &--volume {
      cursor: pointer;
    }
  }
}

.coins__arrow-wrapper {
  margin: 0 0 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coins__arrow-icon {
  display: flex;
  width: 8px;
  height: 4px;
  color: #828282;

  &:hover {
    color: #555;
  }

  &--active {
    color: #2563eb;

    &:hover {
      color: #2563eb;
    }
  }
}

.coins__arrow-icon:first-child {
  margin-bottom: 4px;
}

.coins__instrument {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;

  position: relative;
  display: flex;
  width: 150px;
  box-sizing: border-box;
  padding: 6px 10px 6px 32px;

  @media (min-width: $tablet-width) {
    justify-self: start;
    width: 100%;
  }

  @media (min-width: $desktop-width) {
    padding: 9px 10px 9px 30px;
  }
}

.coins__instrument-logo {
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 20px;

  @media (min-width: $desktop-width) {
    top: 15px;
  }
}

.coins__insrument-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: hidden;
}

.coins__insrument-title {
  width: 100%;
  margin: 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    width: auto;

    font-size: 12px;
    line-height: 16px;
  }
}

.coins__insrument-ticker {
  margin: 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #cbd5e1;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.coins__price {
  grid-column: 2/3;
  grid-row: 1/2;

  display: flex;
  align-items: center;
  padding: 10px 0;

  @media (min-width: $desktop-width) {
    padding: 15px 0;
  }
}

.coins__change {
  display: none;

  @media (min-width: $desktop-width) {
    display: block;
    grid-column: 3/4;
    grid-row: 1/2;
  }
}

.coins__change-amount {
  display: flex;
  align-items: center;

  margin: 0 0 0 7px;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  @media (min-width: $desktop-width) {
    padding: 15px 0;
  }

  &--plus {
    color: #30e733;
  }

  &--minus {
    color: #f93232;
  }
}

.coins__market-cap {
  justify-self: center;
  grid-column: 1/2;
  grid-row: 2/3;

  width: 150px;
  box-sizing: border-box;
  padding: 6px 0;

  @media (min-width: $tablet-width) {
    justify-self: start;
    width: 100%;
    padding: 4px 0;
  }

  @media (min-width: $desktop-width) {
    grid-column: 4/5;
    grid-row: 1/2;
    padding: 15px 0;
  }
}

.coins__title {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: $desktop-width) {
    display: none;
  }
}

.coins__volume {
  grid-column: 2/3;
  grid-row: 2/3;
  padding: 6px 0;

  @media (min-width: $tablet-width) {
    padding: 4px 0;
  }

  @media (min-width: $desktop-width) {
    grid-column: 5/6;
    grid-row: 1/2;
    padding: 15px 0;
  }
}

.coins__chart {
  grid-column: 1/-1;
  grid-row: 3/4;
  width: 100%;

  @media (min-width: $tablet-width) {
    align-self: center;
    grid-column: 3/4;
    grid-row: 1/3;
  }

  @media (min-width: $desktop-width) {
    grid-column: 7;
    grid-row: 1/2;
  }
}

.coins__price,
.coins__change,
.coins__market-cap,
.coins__volume {
  @media (min-width: $desktop-width) {
    display: flex;
    justify-content: flex-end;
  }
}

.coins__price-amount,
.coins__market-cap-amount,
.coins__volume-amount {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: $desktop-width) {
    font-size: 12px;
    line-height: 20px;
  }
}

.coins__minus-icon {
  display: flex;
  width: 5px;
  height: 1.3px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    margin-top: 1px;
    width: 6px;
    height: 1.5px;
  }
}

.coins__percent-icon {
  display: flex;
  width: 8px;
  height: 4px;
  margin-right: 2px;
}

.coins__change-amount--minus .coins__percent-icon {
  margin-top: 2px;
}
