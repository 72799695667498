.promo {
  position: relative;

  @media (min-width: $tablet-width) {
    min-height: 340px;
  }
}

.promo__heading {
  @media (min-width: $tablet-width) {
    width: 372px;
  }

  @media (min-width: $desktop-width) {
    width: 541px;
  }
}

.promo__chart {
  @media (min-width: $tablet-width) {
    position: absolute;
    top: -10px;
    left: 393px;

    width: calc(100% - 415px);
    max-width: 500px;
    margin: 0;
  }

  @media (min-width: $desktop-width) {
    width: 500px;
    max-width: unset;
    top: -5px;
    left: auto;
    right: 0;
  }
}

.promo__description {
  margin-bottom: 25px;

  @media (min-width: $tablet-width) {
    max-width: 348px;
    margin-right: 380px;
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    max-width: unset;
    width: 541px;
    margin-right: 0;
  }
}

.promo__list {
  @include list-reset;
  display: flex;
  flex-direction: column;
}

.promo__item {
  position: relative;

  margin-bottom: 13px;
  padding-left: 26px;

  font-size: 14px;
  line-height: 19px;

  @media (min-width: $tablet-width) {
    margin-bottom: 18px;
  }
}

.promo__item:last-child {
  margin-bottom: 0;
}

.promo__icon {
  position: absolute;
  top: 0.5px;
  left: 0;

  width: 18px;
  height: 18px;
}

.promo__buttons-wrapper {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    padding-left: 13px;
    justify-content: flex-start;
  }

  @media (min-width: $desktop-width) {
    width: 340px;
  }
}
