.connect-step {
  position: relative;
}

// для того чтобы тень детей анимированного блока не была обрезена
// у аним. блока overflow: hidden + др. чтобы при скрытии блока нижняя часть обрезалась
.details__steps-to-connect--animated .connect-step--final {
  margin-bottom: 0;
}

.connect-step__intro {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.connect-step__list {
  margin-top: 0;
  margin-bottom: 13px;
  padding-left: 20px;

  &--ordered {
    @include list-reset;
    padding-left: 0;
    margin-bottom: 13px;
  }
}

.connect-step__item {
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 20px;
  }

  &--with-copy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.connect-step__item-text {
  margin-right: 5px;
}

.connect-step__inner-list {
  margin-top: 3px;
  padding-left: 27px;
  list-style-type: disc;

  @media (min-width: $tablet-width) {
    padding-left: 34px;
  }
}

.connect-step__fields-wrapper {
  padding-top: 4px;
  margin-bottom: 2px;
}

.connect-step__fields-inner-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;

  @media (min-width: $desktop-width) {
    flex-direction: row;
  }

  &--triple {
    flex-direction: column;
    gap: 10px;
    max-width: 421px;

    @media (min-width: $desktop-width) {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: unset;
    }
  }
}

.connect-step__fields-amount-inner-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 1px;

  max-width: 421px;
}

.connect-step__amount-wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 344px;
}

.connect-step__field {
  &--api-key {
    margin-bottom: 10px;

    @media (min-width: $desktop-width) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &--api-key,
  &--secret-key {
    flex-grow: 1;
    max-width: 421px;
  }

  &--amount {
    flex-grow: 1;
    border-right: none;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &--currency {
    position: relative;
    flex-grow: 0;
    width: 120px;
  }

  &--triple {
    @media (min-width: $desktop-width) {
      width: 350px;
    }
  }
}

.connect-step__btn-wrapper {
  display: flex;
  gap: 10px;
}

.connect-step__amount-btn {
  width: 41px;
  height: 40px;
  padding: 0 7px;

  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;

  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  background-color: #3b82f6;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #2563eb;
  }

  &:active {
    background-color: #346cc7;
  }
}

.connect-step__amount-wrapper:hover .connect-step__amount-btn {
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
}

.connect-step__amount-wrapper:focus .connect-step__amount-btn,
.connect-step__amount-wrapper:active .connect-step__amount-btn {
  box-shadow: 2px 2px 4px rgba(59, 130, 246, 0.2);
}

.connect-step__currency-icon {
  position: absolute;
  top: 18px;
  right: 6px;
  display: flex;
  width: 8px;
  height: 5px;
  color: #828282;
}

.connect-step__step1-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.connect-step__message {
  width: 100%;
}
