.sk-payments-order__card {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 57px;
}

.sk-payments-order__card-img {
  width: 20px;
  height: 16px;
  margin-right: 10px;

  border-radius: 5px;
  background-color: #dde4ec;
}

.sk-payments-order__text {
  flex-grow: 1;
  height: 25px;
  margin-right: 20px;

  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-payments-order__card-btn {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: #cbd5e1;
}

.sk-payments-order__form {
  position: relative;
  padding-top: 8px;
  padding-bottom: 2px;

  @media (min-width: $tablet-width) {
    padding-left: 30px;
  }
}

.sk-payments-order__form-img {
  display: none;

  @media (min-width: $tablet-width) {
    position: absolute;
    display: block;
    width: 20px;
    height: 16px;

    top: 18px;
    left: 0;

    border-radius: 5px;
    background-color: #dde4ec;
  }
}

.sk-payments-order__form-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 12px;
  align-items: center;
}

.sk-payments-order__fields-wrapper {
  display: flex;
  column-gap: 10px;
}

.sk-payments-order__field {
  height: 40px;

  border-radius: 5px;
  background-color: #e1ebf8;

  &--number {
    flex-grow: 1;
    min-width: 258px;
    max-width: 387px;

    @media (min-width: $tablet-width) {
      max-width: 411px;
    }

    @media (min-width: $desktop-width) {
      flex-grow: 0;
      min-width: auto;
      width: 250px;
    }
  }

  &--expiry {
    width: 67px;

    @media (min-width: $tablet-width) {
      width: 79px;
    }
  }

  &--cvc {
    width: 43px;

    @media (min-width: $tablet-width) {
      width: 55px;
    }
  }
}

.sk-payments-order__form-btn {
  width: 120px;
  height: 40px;
  margin-left: auto;
  border-radius: 5px;
  background-color: #cbd5e1;
}
