.coin {
  @include main-padding;

  @media (min-width: $tablet-width) {
    overflow: hidden;
  }

  @media (min-width: $desktop-width) {
    padding: 0px 20px 0px 20px;
    overflow: visible;
  }
}

.coin__heading {
  display: flex;
  align-items: center;
}

.coin__logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.coin__item {
  margin-bottom: 13px;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}
