.coin-container {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $desktop-width) {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 185px;
  }
}

.coin-board {
  @media (min-width: $desktop-width) {
    display: grid;
    grid-template-rows: auto 1fr;
    column-gap: 15px;
  }

  &--no-price {
    display: block;
  }
}

.coin-board__block-price {
  @media (min-width: $desktop-width) {
    padding: 8px 20px;
  }
}

.coin-board__block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 18px;
  padding: 8px 0 8px 15px;

  @media (min-width: $desktop-width) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;
    padding: 8px 20px 8px 20px;
  }
}

.coin-board--no-price .coin-board__block {
  @media (min-width: $desktop-width) {
    padding-left: 100px;
  }
}

.coin-board__item {

  width: 130px;

  @media (min-width: $desktop-width) {
    width: 145px;
  }

  &--cp {
    grid-row: 1/1;
    grid-column: 1/1;
  }

  &--mc {
    grid-row: 1/2;
    grid-column: 2/3;
    width: 145px;

    @media (min-width: $desktop-width) {
      grid-column: 1/1;
      grid-row: 1/2;
    }
  }

  &--v24 {
    grid-row: 2/3;
    grid-column: 1/2;

    @media (min-width: $desktop-width) {
      grid-row: 2/3;
      grid-column: 1/1;
    }
  }

  &--cs {
    grid-row: 2/3;
    grid-column: 2/3;
    width: 145px;

    @media (min-width: $desktop-width) {
      grid-row: 3/3;
      grid-column: 1/1;
    }
  }
}

.coin-board--no-price .coin-board__item {
  @media (min-width: $desktop-width) {
    grid-row: 1/2;

    &--mc {
      grid-column: 1/2;
    }

    &--v24 {
      grid-column: 2/3;
    }

    &--cs {
      grid-column: 3/4;
    }
  }
}

.coin-board__title {
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.1;
  color: #828282;
}

.coin-board__amount {
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #1a1a1a;
}

.coin-board__percent {
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 600;
  font-size: 10px;
  line-height: 20px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--plus {
    color: #30e733;
  }

  &--minus {
    color: #f93232;
  }
}

.coin-board__hint {
  position: relative;
  margin-top: -2px;
  margin-left: 10px;
}

.coin-board__percent-icon {
  display: flex;
  width: 8px;
  height: 4px;
  margin-right: 2px;
}

.coin-board__percent--minus .coin-board__percent-icon {
  margin-top: 2px;
}

.coin-board__minus-icon {
  display: flex;
  width: 8px;
  height: 1.6px;
  margin-top: 2px;
  color: #1a1a1a;
}
