.policy {
  @include doc-padding;
}

.policy__heading {
  @include doc-heading;
}

.policy__intro,
.policy__paragraph,
.policy__note {
  @include doc-paragraph-font;
  text-align: justify;
}

.policy__intro {
  margin-top: 0;
  margin-bottom: 10px;
}

.policy__list,
.policy__table {
  @include list-reset;
}

.policy__item {
  padding-bottom: 10px;
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    margin-bottom: 13px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 15px;
  }
}

.policy__item:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.policy__question {
  margin-top: 0;
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 32px;
  }
}

.policy__paragraph {
  margin-top: 0;
  margin-bottom: 10px;
}

.policy__sked {
  margin-bottom: 10px;
  list-style-type: square;
}

.policy__sked-item {
  @include doc-paragraph-font;

  margin-bottom: 5px;
}

.policy__table {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  border: solid 1px #555555;
}

.policy__row {
  display: grid;
  grid-template-columns: 20px 60px 60px 60px 1fr;

  @media (min-width: $tablet-width) {
    grid-template-columns: 30px 80px 80px 80px 1fr;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 30px 90px 90px 90px 1fr;
  }
}

.policy__row:not(:last-child) {
  border-bottom: solid 1px #555555;
}

.policy__cell {
  font-size: 10px;
  line-height: 12px;

  margin: 0;
  padding: 3px;

  @media (min-width: $tablet-width) {
    @include doc-paragraph-font;
  }
}

.policy__cell:not(:last-child) {
  border-right: solid 1px #555555;
}

.policy__cell:first-child {
  text-align: center;
}

.policy__row:first-child .policy__cell {
  @include doc-paragraph-font;
}

.policy__note {
  margin-top: 0;
  margin-bottom: 10px;
}
