@keyframes increase {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes decrease {
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

@keyframes disappear {
  to {
    opacity: 0;
  }
}

@keyframes subscribe-success {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.renew-keys-animation-enter {
  opacity: 0;
  transform: translateY(-50px);
}

.renew-keys-animation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.renew-keys-animation-exit {
  opacity: 1;
}

.renew-keys-animation-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 300ms, transform 300ms;
}
