.partnership-block {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    display: grid;
    grid-template-columns: 346px 1fr;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 515px 1fr;
  }
}



.partnership-block__intro {
  margin-top: 0;
  margin-bottom: 21px;
  font-size: 14px;
  line-height: 19px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    margin-bottom: 25px;
  }

  @media (min-width: $desktop-width) {
    width: 446px;
    margin-bottom: 30px;
  }
}

.partnership-block__img {
  width: 100%;
  height: 267px;
  margin-top: 20px;
  background-image: url("../../img/cell-bg/partnership-form/cell-partnership-mobile.png");
  background-size: 284px 267px;
  background-position: center bottom;
  background-repeat: no-repeat;

  @media (min-width: $tablet-width) {
    width: auto;
    height: auto;
    margin-top: 0;

    background-image: url("../../img/cell-bg/partnership-form/cell-partnership-tablet.png");
    background-size: 349px 328px;
    background-position: center top 80px;
  }

  @media (min-width: $desktop-width) {
    background-image: url("../../img/cell-bg/partnership-form/cell-partnership-desktop.png");
    background-size: 424px 399px;
    background-position: center top 55px;
  }
}
