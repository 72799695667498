.sk-referrals-activity {
    margin-top: -3px;
  }
  
  .sk-referrals-activity__row {
    width: 100%;
    height: 41px;
  
    display: grid;
    align-items: center;
    grid-template-columns: minmax(50px, 1fr) minmax(138px, 2fr) minmax(50px, 1fr);
    column-gap: 10px;
  
    @media (min-width: $tablet-width) {
      grid-template-columns:
        minmax(70px, 1fr) minmax(266px, 2fr) minmax(70px, 1fr)
    }
  
    @media (min-width: $desktop-width) {
      grid-template-columns: 50px 198px 50px;
      column-gap: 20px;
    }
  }
  
  .sk-referrals-activity__text {
    height: 20px;
    border-radius: 5px;
    background-color: #e1ebf8;
  }
  
  .sk-referrals-activity__row:first-child .sk-referrals-activity__text {
    background-color: #dde4ec;
  }
  