.fake-card-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 12px;

  padding-top: 8px;
  padding-bottom: 3px;

  @media (min-width: $tablet-width) {
    padding-left: 30px;
  }

  &--with-cards {
    @media (min-width: $tablet-width) {
      padding-left: 54px;
    }
  }
}

.fake-card-form__icon {
  display: none;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 21px;
    left: 0;
    display: flex;
    width: 19px;
    height: 14px;
    margin: 0 0.5px 0.5px 0;
    color: #828282;
  }
}

.fake-card-form--with-cards .fake-card-form__icon {
  left: 24px;
}

.fake-card-form__fields-wrapper {
  display: flex;
  column-gap: 10px;
}

.fake-card-form__input {
  height: 40px;
  padding: 12px 8px;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
  box-sizing: border-box;

  font-weight: 100;
  font-size: 12px;
  line-height: 14px;
  color: #555555;

  pointer-events: none;
  opacity: 0.6;

  @media (min-width: $tablet-width) {
    padding: 13px 10px;
  }

  &--number {
    flex-grow: 1;
    min-width: 258px;
    max-width: 387px;

    @media (min-width: $tablet-width) {
      max-width: 411px;
    }

    @media (min-width: $desktop-width) {
      flex-grow: 0;
      min-width: auto;
      width: 250px;
    }
  }

  &--expiry {
    width: 67px;

    @media (min-width: $tablet-width) {
      width: 79px;
    }
  }

  &--cvc {
    width: 43px;

    @media (min-width: $tablet-width) {
      width: 55px;
    }
  }
}

.fake-card-form--with-cards .fake-card-form__input {
  &--number {
    @media (min-width: $tablet-width) {
      max-width: 387px;
    }

    @media (min-width: $desktop-width) {
      width: 226px;
    }
  }
}

.fake-card-form__btn-add-card {
  margin-left: auto;

  pointer-events: none;
  opacity: 0.5;
}
