.payments-method {
  position: relative;
  display: flex;
}

.payments-method__handle {
  // Чтобы зона перетаскивания была видна
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  width: 24px;
  height: 100%;
  padding: 0;

  border: none;
  background-color: transparent;
  cursor: grab;

  &:focus {
    outline: none;
  }
}

.payments-method__drag-icon {
  position: absolute;
  left: 0;
  top: 50%;

  display: block;
  width: 10px;
  height: 24px;

  color: #2563eb;
  transform: translate(0, -50%);
}

.payments-method__row {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-height: 52px;
  padding: 8px 0 8px 30px;
  box-sizing: border-box;
  flex-wrap: wrap;
  column-gap: 10px;

  border-bottom: 1px solid #cbd5e1;
  background-color: white;

  &--with-cards {
    padding-left: 54px;
  }
}

.payments-method__icon {
  position: absolute;
  top: 21px;
  left: 0;
  margin: 0 0.5px 0.5px 0;

  display: flex;
  width: 19px;
  height: 14px;
  color: #828282;
}

.payments-method__row--with-cards .payments-method__icon {
  left: 24px;
}

.payments-method__note {
  min-width: 45px;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

.payments-method__btn-deposit {
  flex-shrink: 0;
  margin-left: auto;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.payments-method__card {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1a1a1a;
  flex-grow: 1;

  @media (min-width: $tablet-width) {
    font-size: 22px;
    line-height: 29px;
  }

  &--with-alert {
    color: #f93232;
  }
}

.payments-method__message {
  // Чтобы зона перетаскивания была видна
  z-index: 1;
  position: absolute;
  top: 38px;
  left: 8px;

  @media (min-width: $tablet-width) {
    position: static;
    padding-right: 5px;
  }
}

.payments-method__message-br {
  display: none;

  @media (min-width: $desktop-width) {
    display: block;
  }
}

.payments-method__btn-delete {
  flex-shrink: 0;
  margin-left: auto;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  @media (max-width: 403px) {
    border: none;
    height: auto;
  }
}

.payments-method__btn-charge {
  flex-shrink: 0;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:focus {
    color: #2bbc8b;
    background-color: transparent;
  }
  @media (min-width: $tablet-width) {
    width: 144px;
  }
  @media (min-width: $desktop-width) {
    margin-right: 19px;
  }
}
