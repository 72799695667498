.shadow {
  // у родителя обязательно проставить overflow: hidden; если сетка родителя растягивается
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
  }
}

.shadow--coin-heading {
  width: 100%;
  height: 26px;

  white-space: normal;
  word-break: break-all;

  @media (min-width: $tablet-width) {
    width: calc(100% - 320px);
    height: 32px;
  }

  @media (min-width: $desktop-width) {
    width: calc(100% - 335px);
  }

  &::after {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 80%,
      rgb(250, 251, 252, 1) 100%
    );

    @media (min-width: $tablet-width) {
      background: linear-gradient(
        to right,
        transparent,
        rgba(255, 255, 255, 0) 85%,
        rgba(250, 251, 252, 1) 100%
      );
    }

    @media (min-width: $desktop-width) {
      background: linear-gradient(
        to right,
        transparent,
        rgba(255, 255, 255, 0) 90%,
        rgba(250, 251, 252, 1) 100%
      );
    }
  }
}

.coin__heading--auth .shadow--coin-heading {
  @media (min-width: $tablet-width) {
    width: 100%;
  }
}

.shadow--instrument {
  &::after {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 1) 100%
    );

    @media (min-width: $desktop-width) {
      background: linear-gradient(
        to right,
        transparent,
        rgba(255, 255, 255, 0) 60px,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.shadow--name {
  // для того чтобы была возможность в 2 строки
  white-space: normal;
  &::after {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 1) 96%
    );
  }
}

.shadow--field {
  &::after {
    right: 10px;
    top: 3px;

    width: 99%;
    height: 30px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 1) 96%
    );

    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 1) 100%
    );

    @media (min-width: $desktop-width) {
      background: none;
    }
  }
}

.shadow--personal,
.shadow--notifications {
  white-space: normal;

  &::after {
    content: none;
  }

  &::before,
  &::after {
    @media (min-width: $tablet-width) {
      content: "";
      position: absolute;
      left: 10px;

      width: 272px;
      height: 25px;
    }
  }

  &::before {
    @media (min-width: $tablet-width) {
      z-index: 1;
      top: 0;

      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255) 10px,
        transparent
      );
    }
  }

  &::after {
    @media (min-width: $tablet-width) {
      top: auto;
      bottom: 0;
      width: 272px;
      height: 25px;

      background: linear-gradient(
        to top,
        rgba(255, 255, 255) 10px,
        transparent
      );
    }
  }
}

.shadow--coins {
  &::after {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
