.crypto-form {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.crypto-form__qr-wrapper {
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $tablet-width) {
    position: relative;
  }
}

.crypto-form__countdown-wrapper {
  position: relative;

  margin-left: -45px;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 9px;
    right: 66px;
    margin-left: 0;
  }
}

.crypto-form__countdown-time {
  position: absolute;
  left: 50px;

  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.crypto-form__address-label {
  align-self: flex-start;
  margin-bottom: 4px;

  font-size: 12px;
  line-height: 16px;
  color: #828282;
}

.crypto-form__address-wrapper {
  display: flex;
  align-self: stretch;
  margin-bottom: 12px;
}

.crypto-form__field-wrapper {
  width: 100%;
  margin-right: 9px;
}

.crypto-form__field {
  width: 100%;
  height: 40px;

  padding: 12px 10px 12px 8px;
  box-sizing: border-box;

  font-size: 12px;
  color: #828282;

  border: 1px solid #cbd5e1;
  border-radius: 5px;
  background-color: transparent;

  &:hover {
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  }

  &:focus,
  &:active {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 2px 2px 4px rgba(59, 130, 246, 0.2);
  }

  &::placeholder {
    color: #828282;
  }
}

.crypto-form__copy {
  position: relative;

  width: 30px;
  height: 40px;

  background-image: url("../../img/action/copy-blue.svg");
  background-size: 20px 22px;
  background-position: bottom 9px left 3px;
  background-repeat: no-repeat;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-image: url("../../img/action/copy-blue-dark.svg");
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.crypto-form__copy--copied::before {
  content: "Copied!";
  position: absolute;
  z-index: 5;
  top: -18px;
  right: 0;
  padding: 4px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #828282;

  background-color: #dbeafe;
  border-radius: 5px;

  animation-name: disappear;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.crypto-form__note {
  align-self: flex-start;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: #555;
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    font-size: 13px;
    line-height: 16px;
  }
}

.crypto-form__list {
  align-self: flex-start;
  margin-top: -5px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.crypto-form__item {
  font-size: 11px;
  line-height: 15px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.crypto-form__item:first-child {
  margin-bottom: 5px;
}

.crypto-form__icon-wrapper {
  display: inline-block;
  margin: 0 1px 0 4px;
}

.crypto-form__icon {
  display: flex;
  position: relative;

  &--usdt {
    top: 3px;
    width: 14px;
    height: 15px;
  }

  &--usdc {
    top: 2px;
    width: 13px;
    height: 13px;
  }
}

.crypto-form__btn {
  align-self: flex-end;
}

.crypto-form__bold {
  margin: 0 3px;
}
