.sk-trading-results {
  @include block-shadow;
  
  display: flex;
  flex-direction: column;
  padding: 14px 16px 5px;
}

.sk-trading-results__heading {
  width: 200px;
  height: 25px;
  margin-bottom: 8px;

  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $desktop-width) {
    margin-bottom: 10px;
  }
}

.sk-trading-results__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40.2px;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    height: 40.6px;
  }
}

.sk-trading-results__text:first-child {
  height: 20px;
  width: 180px;
  background-color: #e1ebf8;
  border-radius: 5px;
}

.sk-trading-results__text:nth-child(2) {
  height: 20px;
  width: 50px;
  background-color: #e1ebf8;
  border-radius: 5px;
}
