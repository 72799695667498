.sk-notifications {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.sk-notifications__item {
  display: flex;
  padding: 5px 0 10px;
}

.sk-notifications__icon {
  width: 16px;
  height: 12px;
  margin: 8px 15px 0 0;
  background-color: #cbd5e1;
  border-radius: 5px;
}

.sk-notifications__text-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sk-notifications__message {
  width: 100%;
  height: 32px;
  margin-bottom: 5px;
  background-color: #e1ebf8;
  border-radius: 5px;
}

.sk-notifications__date {
  width: 115px;
  height: 15px;
  background-color: #dde4ec;
  border-radius: 5px;
}
