.calc-block__wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  // Для вычисляемой ширины!
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding-top: 8px;
  }
}

.calc-block__heading {
  @media (min-width: $tablet-width) {
    margin-top: 0;
    margin-bottom: 9px;
    padding: 0 0 0 10px;

    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #1a1a1a;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 12px;
    font-size: 28px;
    line-height: 35px;
  }
}

.calc-block__top {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 15px;
}

.calc-block__total {
  display: flex;
  margin: 0;
  justify-content: center;
  column-gap: 30px;

  @media (min-width: $desktop-width) {
    justify-content: space-between;
  }
}

.calc-block__total-amount,
.calc-block__total-profit {
  width: 150px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  @media (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (min-width: $desktop-width) {
    width: 210px;
    font-size: 25px;
    line-height: 30px;
  }
}

.calc-block__total-amount {
  color: #4489f6;
}

.calc-block__total-profit {
  color: #1a1a1a;

  &--up {
    color: #2bbc8b;
  }

  &--down {
    color: #f93232;
  }
}

.calc-block__chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
