.payments-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop-width) {
    display: grid;
    align-content: flex-start;
    grid-template-columns: 614px 156px;
    grid-template-rows: 74px min-content;

    grid-column-gap: 15px;
  }
}

.payments-wrapper__item {
  margin-bottom: 13px;

  &--order {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  &--ad {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
