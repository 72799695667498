.overview-not-started {
  display: contents;
}

.overview-not-started__historical {
  grid-column: 1/2;
  grid-row: 1/3;
  justify-self: center;

  display: flex;
  flex-direction: column;
  width: 130px;
  padding-left: 26px;
  box-sizing: border-box;
  margin: 0;
  color: #828282;

  @media (min-width: $tablet-width) {
    justify-self: start;
    grid-row: 1/2;
    row-gap: 3px;
    width: 90px;
  }

  @media (min-width: $desktop-width) {
    width: auto;
    padding-left: 0;
  }
}

.overview-not-started__historical-big {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
}

.overview-not-started__historical-small {
  font-size: 12px;
  line-height: 18px;
}

.overview-not-started__total-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.overview-not-started .overview-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 5/6;
    }

    @media (min-width: $desktop-width) {
      max-width: 45px;
      grid-column: 5;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 8;
    }
  }
}
