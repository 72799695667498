.referrals-activity__table {
  @include list-reset;
  margin-top: -4px;
}

.referrals-activity__row {
  display: grid;
  grid-template-columns: minmax(67px, 1fr) minmax(141px, 2fr) 60px;
  align-items: center;
  height: 40px;

  @media (min-width: $desktop-width) {
    grid-template-columns: 90px 1fr 72px;
  }
}

.referrals-activity__row:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.referrals-activity__title {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  &--amount {
    text-align: right;
  }
}

.referrals-activity__event {
  display: flex;
  align-items: center;
  gap: 5px;
}

.referrals-activity__event-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 21px;
  height: 16px;
}

.referrals-activity__event-icon {
  display: flex;
  flex-shrink: 0;

  &--user {
    width: 15px;
    height: 15px;
    color: #828282;

    margin-top: -1px;
    padding: 1px;
  }

  &--wallet {
    width: 18px;
    height: 13px;
    color: #828282;

    margin-top: -1px;
    padding: 1px;
  }
}

.referrals-activity__event-text {
  margin: 0;
  align-self: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.referrals-activity__amount {
  padding-left: 5px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.referrals-activity__amount-icon {
  display: flex;
  flex-shrink: 0;

  &--minus {
    width: 5px;
    height: 1.5px;
    margin-top: 1.5px;
    color: #1a1a1a;
  }
}

.referrals-activity__absolute {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}
