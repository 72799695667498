.relative-block__wrapper {
  margin-top: -15px;

  @media (min-width: $tablet-width) {
    margin-top: -10px;
  }
}

.relative-block .swiper {
  margin: 0 -15px !important;
  width: calc(100% + 30px);

  @media (min-width: $desktop-width) {
    margin: 0 -10px !important;
    width: calc(100% + 20px);
  }
}

.relative-block .swiper-wrapper {
  width: 100%;
}

.relative-block .swiper-slide {
  width: 100% !important;
  height: 530px !important;

  @media (min-width: $tablet-width) {
    width: 385px !important;
    height: 555px !important;
  }

  @media (min-width: $desktop-width) {
    width: 356px !important;
    height: 530px !important;
  }
}

// элемент в слайде, отступы для отображения тени
// засчет этого, растояние между слайдами
.relative-block__slide {
  padding: 15px;

  @media (min-width: $desktop-width) {
    padding: 10px;
  }
}
