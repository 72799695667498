.grid {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(auto-fit, 135px);
  column-gap: 20px;
  row-gap: 16px;

  max-width: 610px;

  @media (min-width: $tablet-width) {
    column-gap: 25px;
    max-width: 615px;
  }

  @media (min-width: $desktop-width) {
    max-width: 775px;
  }
}

.grid__item {
  width: 135px;
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  &--with-link {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.grid__link {
  display: block;

  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  @include animation-lift-shadow-onhover-add;

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
  }

  &:active {
    box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
  }
}

.grid__img {
  display: block;
}
