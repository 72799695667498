.sk-payments-last {
  margin-top: -3px;
}

.sk-payments-last__row {
  width: 100%;
  height: 40.5px;

  display: grid;
  align-items: center;
  grid-template-columns:
    52px minmax(80px, 2fr)
    minmax(38px, 1fr) minmax(56px, 1fr);

  column-gap: 10px;

  @media (min-width: $tablet-width) {
    grid-template-columns: 64px minmax(159px, 2.5fr) minmax(114px, 1fr) minmax(
        72px,
        1fr
      );
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 73px 309px 155px 155px;
    column-gap: 20px;
  }
}

.sk-payments-last__text {
  height: 20px;
  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-payments-last__row:first-child .sk-payments-last__text {
  background-color: #dde4ec;
}
