.strategy-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
}

.strategy-popup__wrapper {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 101;

  display: flex;
  flex-direction: column;
  width: 290px;
  padding: 32px 40px 40px 20px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    top: 130px;
    width: 542px;
    min-height: 320px;
    padding: 30px 57px 30px 255px;

    background-image: url("../../img/popup/okx-popup-tablet.jpg");
    background-size: 215px 320px;
    background-position: left top;
    background-repeat: no-repeat;
  }

  @media (min-width: $desktop-width) {
    top: 150px;
    width: 620px;
    min-height: 301px;
    padding: 30px 43px 30px 299px;
    background-image: url("../../img/popup/okx-popup-desktop.jpg");
    background-size: 255px 301px;
  }

@mixin popup-style($tablet-image, $desktop-image) {
  @media (min-width: $tablet-width) {
    padding: 30px 52px 30px 255px;
    background-image: url($tablet-image);
    background-size: 215px 416px;
  }

  @media (min-width: $desktop-width) {
    min-height: 397px;
    padding: 30px 50px 30px 299px;
    background-image: url($desktop-image);
    background-size: 255px 397px;
  }
}

&--bitget {
  @include popup-style("../../img/popup/bitget-popup-tablet.jpg", "../../img/popup/bitget-popup-desktop.jpg");
}

&--bybit {
  @include popup-style("../../img/popup/bybit-popup-tablet.jpg", "../../img/popup/bybit-popup-desktop.jpg");
}

}

.strategy-popup__heading {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 20px;
    line-height: 28px;
  }

  &--bitget {
    @media (min-width: $tablet-width) {
      margin-right: -25px;
    }
  }
}

.strategy-popup__attn {
  font-weight: 600;
  color: #3b82f6;
}

.strategy-popup__message {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 19px;
  color: #555555;

  &--short:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.strategy-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  width: 15px;
  height: 15px;
  padding: 0;

  color: #555555;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    top: 20px;
    right: 20px;
  }

  &:hover {
    color: #1a1a1a;
  }

  &:focus {
    color: #1a1a1a;
    outline: none;
  }

  &:active {
    color: #555555;
  }
}
