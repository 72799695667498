.renew-keys-info {
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 18px;
  }
}

.renew-keys-info__list {
  @include list-reset;
}

.renew-keys-info__inner-list {
  margin-top: 3px;
  padding-left: 27px;
  list-style-type: disc;

  @media (min-width: $tablet-width) {
    padding-left: 34px;
  }
}

.renew-keys-info__item--with-copy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.renew-keys-info__item:not(:last-child),
.renew-keys-info__inner-item:not(:last-child) {
  margin-bottom: 5px;
}

.renew-keys-info__paragraph {
  margin-top: 0;
  margin-bottom: 10px;
}

.renew-keys-info__btn {
  &--reconnect {
    margin-top: 13px;
    margin-bottom: 20px;
  }
}
