.table-date {
  display: flex;
  flex-direction: column;

  padding: 6px 10px 6px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: $tablet-width) {
    padding: 4px 10px 4px 0;

    font-size: 12px;
    line-height: 16px;
  }

  &--notifications {
    flex-direction: row;
    padding: 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    gap: 3px;
  }
}

.table-date-full {
  margin: 0;
  color: #555555;

  &--notifications {
    color: #828282;
  }
}

.table-date-time {
  margin: 0;
  color: #cbd5e1;

  &--notifications {
    color: #828282;
  }
}
