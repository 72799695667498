.tooltip {
  position: relative;
  display: flex;
}

.tooltip__button {
  width: 12px;
  height: 12px;
  padding: 0;

  background-color: transparent;
  color: #828282;
  border: none;
  cursor: pointer;

  &:hover {
    color: #555555;
  }
}

.tooltip__message {
  display: none;
  position: absolute;
  top: -35px;
  left: 50%;

  height: 32px;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #828282;
  background-color: #dbeafe;
  border-radius: 5px;
  transform: translate(-50%, 0);
}

.tooltip__message::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;

  width: 17px;
  height: 7px;

  background-image: url("../../img/arrow/hint-arrow-up.svg");
  background-position: center;
  background-size: 17px 7px;

  transform: translate(-50%, 0) rotate(-180deg);
}

.tooltip__button:hover + .tooltip__message {
  display: block;
}

// Чтобы тоолтип не выходил за правый край
.position-discrepancy__missing .tooltip__message {
  left: auto;
  right: -15px;
  transform: none;
}
.position-discrepancy__missing .tooltip__message::before {
  left: auto;
  right: 12.5px;
  transform: translate(0, 0) rotate(-180deg);
}
