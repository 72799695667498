@mixin list-reset {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

@mixin doc-paragraph-font {
  font-size: 11px;
  line-height: 15px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin inner-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 16px 0;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

@mixin animation-lift-shadow-onhover-add {
  transition-duration: 0.2s;
  transition-property: transform, box-shadow;
  transform: translateY(0);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
  }
}

@mixin block-shadow {
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}
