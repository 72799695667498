.details-stop-note {
  margin: 0;

  &--big {
    @media (min-width: $desktop-width) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
    }
  }
}

.details-stop-note__text {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.details-stop-note__big {
  @media (min-width: $desktop-width) {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #1a1a1a;
    white-space: nowrap;

    margin-bottom: 3px;
    font-size: 17px;
  }
}
