.close {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  width: 15px;
  height: 15px;
  padding: 0;

  color: #828282;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    top: 20px;
    right: 20px;
  }

  &:hover {
    color: #555555;
  }

  &:focus {
    color: #1a1a1a;
    outline: none;
  }

  &:active {
    color: #555555;
  }
}
