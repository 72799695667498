.exchange-promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    @media (min-width: $tablet-width) {
      gap: 12px;
      flex-direction: row;
    }
    @media (min-width: $desktop-width) {
      flex-direction: row;
    }
  }
  
  .exchange-promo__heading {
    font-size: 28px;
  
    @media (min-width: $tablet-width) {
      font-size: 26px;
    }
  
    @media (min-width: $desktop-width) {
      font-size: 20px;
    }
  }
  
  .exchange-promo_container {
    display: flex;
    flex-direction: column;
    max-width: 372px;
    position: relative;
  
    @media (min-width: $desktop-width) {
      max-width: 523px;
    }
  }
  
  .exchange-promo__description {
    margin-top: 0;
    margin-bottom: 20px;
  
    font-size: 14px;
    line-height: 19px;
    color: #555555;
  
    @media (min-width: $tablet-width) {
      width: 372px;
      margin-bottom: 0;
    }
  
    @media (min-width: $desktop-width) {
      width: 488px;
    }
  }
  
  .exchange-promo__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
  
    @media (min-width: $tablet-width) {
      align-items: flex-start;
      flex-direction: column;
    }
    @media (min-width: $desktop-width) {
      flex-direction: row;
    }
  }
  