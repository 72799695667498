.okx-steps {
  display: flex;
  flex-direction: column;
}

.okx-steps__list {
  @include list-reset;
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 27px;

  @media (min-width: $tablet-width) {
    align-self: stretch;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 143px);
    column-gap: 58px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: repeat(auto-fit, 182px);
    column-gap: 107px;
  }
}

.okx-steps__item {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;

  @media (min-width: $tablet-width) {
    align-items: flex-start;
  }

  @media (min-width: $desktop-width) {
    min-height: 162px;
  }
}

.okx-steps__icon {
  position: relative;
  width: 135px;
  height: 135px;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 0;
    left: 0;

    width: 140px;
    height: 140px;
  }

  @media (min-width: $desktop-width) {
    left: 10px;

    width: 162px;
    height: 162px;
  }

  &::before {
    content: "";
    position: absolute;

    top: 50%;
    right: -5px;
    width: 4px;
    height: 2px;
    box-shadow: 6px 0 0 0 #e3ebfe, 7px 0 0 0 #e3ebfe, 13px 0 0 0 #e3ebfe;
    background-color: #e3ebfe;
    border-radius: 1px;
    transform: translate(0, -50%);

    @media (min-width: $tablet-width) {
      width: 8px;
      right: -14px;

      box-shadow: 18px 0 0 0 #e3ebfe, 25px 0 0 0 #e3ebfe, 42px 0 0 0 #e3ebfe;
    }

    @media (min-width: $desktop-width) {
      width: 12px;
      right: -32px;

      box-shadow: 30px 0 0 0 #e3ebfe, 42px 0 0 0 #e3ebfe, 73px 0 0 0 #e3ebfe;
    }
  }
}

.okx-steps__item:last-child .okx-steps__icon {
  &::before {
    @media (min-width: $tablet-width) {
      content: none;
    }
  }
}

.okx-steps__number {
  position: absolute;
  top: -7px;
  left: 0;

  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    top: -13px;
    font-size: 70px;
    line-height: 95px;
  }

  @media (min-width: $desktop-width) {
    top: -21px;
    left: 10px;
    font-size: 80px;
    line-height: 109px;
  }
}

.okx-steps__block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  min-height: 56px;
  margin-bottom: 0;
  padding: 11px 5px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    // чтобы перекрыть диск
    position: relative;
    width: 143px;
    min-height: 54px;
    margin-top: 81px;
    padding: 8px;
  }

  @media (min-width: $desktop-width) {
    width: 280px;
    min-height: 40px;
    margin-top: 91px;
    padding: 8px 15px;
  }

  &--register {
    text-align: start;
    font-family: "Open Sans", Arial, sans-serif;
    color: #555555;

    border: none;
    cursor: pointer;

    @include animation-lift-shadow-onhover-add;

    &:focus {
      box-shadow: none;
      outline: 2px solid #dbeafe;
    }

    &:active {
      box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
    }
  }
}

.okx-steps__info {
  margin: 0;

  font-size: 13px;
  line-height: 19px;
  color: #555555;
  text-align: center;

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.okx-steps__btn-wrapper {
  align-self: center;
  margin-top: 30px;
}

// изменить позже
.okx-steps__btn-wrapper1 {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}