.block-empty-data {
  display: flex;
  justify-content: center;

  @media (min-width: $desktop-width) {
    justify-content: flex-end;
  }
}

.block-empty-data__btns-wrapper {
  position: relative;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (min-width: $desktop-width) {
    margin: 4px 16px 1px 0;
  }

  &--with-register {
    @media (min-width: $desktop-width) {
      grid-column: 4/6;
    }
  }
}

.block-empty-data__link {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}
