.auth {
  flex-direction: column;
  margin-bottom: 9px;
  width: 271px;

  @media (min-width: $desktop-width) {
    width: 295px;
  }
}

.short-header-main .auth {
  margin: 0 0 20px 15px;
  padding: 0;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 13.5px;
    right: 15px;
    display: flex;
  }

  // &--partnership {
  //   padding-top: 66px;

  //   @media (min-width: $tablet-width) {
  //     padding-top: 0;
  //   }
  // }
}

.auth__wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  @media (min-width: $tablet-width) {
    margin-bottom: 18.5px;
  }
}

.auth__heading {
  margin: 7px 13px 0 0;

  font-weight: 600;
  font-size: 18px;
  line-height: 30px;

  @media (min-width: $desktop-width) {
    margin-right: 18px;
    font-size: 22px;
  }
}

.auth__list {
  @include list-reset;

  width: 165px;
  height: 45px;

  display: flex;
  justify-content: space-between;
}
