.sk-referrals-balance {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 10px;

  @media (min-width: $tablet-width) {
    row-gap: 16px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 260px 230px 263px;
    row-gap: 13px;
  }
}

.sk-referrals-balance__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  height: 42px;

  @media (min-width: $tablet-width) {
    height: 45px;
  }

  &:first-of-type {
    grid-column: 1/-1;
  }

  &--big {
    height: 60px;
  }

  @media (min-width: $tablet-width) {
    height: 45px;
  }
}

.sk-referrals-balance__title {
  width: 80px;
  height: 14px;

  border-radius: 5px;
  background-color: #dde4ec;
  margin-left: 26px;

  @media (min-width: $tablet-width) {
    width: 100px;
  }
}

.sk-referrals-balance__item--big .sk-referrals-balance__title {
  height: 28px;

  @media (min-width: $tablet-width) {
    height: 14px;
  }
}

.sk-referrals-balance__value-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sk-referrals-balance__icon {
  width: 20px;
  height: 20px;
  background-color: #e1ebf8;
}

.sk-referrals-balance__value {
  width: 60px;
  height: 20px;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    width: 70px;
  }
}
