.toggles-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 15px;
  padding: 0 10px;
}

.toggles-base__item-wrapper {
  position: relative;
}

.toggles-base__item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.toggles-base__text {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;
}

.toggles-base__label {
  position: relative;
  display: block;
  width: 38px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  &--period {
    border: 1px solid #26cf28;
  }

  &--coin {
    border: 1px solid #2563eb;
  }
}

.toggles-base__toggle {
  position: absolute;
  top: 50%;
  left: 3px;

  width: 13px;
  height: 13px;

  border-radius: 50%;
  transform: translate(0, -50%);

  &--period {
    background-color: #26cf28;
  }

  &--coin {
    background-color: #2563eb;
  }
}

input:checked + .toggles-base__label .toggles-base__toggle {
  left: auto;
  right: 3px;
}

.toggles-base__icon {
  display: flex;
  width: 20px;
  height: 20px;
}

.toggles-base__hint {
  position: absolute;
  right: -29px;
  bottom: -2px;
}
