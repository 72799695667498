.sk-nav-item {
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 63px;
}

.sk-nav-item__icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-color: #dde4ec;
  border-radius: 5px;
}

.sk-nav-item__account {
  width: 105px;
  height: 20px;
  background-color: #e1ebf8;
  border-radius: 5px;
}
