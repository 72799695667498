.table-instrument {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 6px 5px 6px 25px;
  
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
  
    @media (min-width: $tablet-width) {
      flex-direction: row;
      padding: 10px 5px 10px 30px;
      font-size: 12px;
      line-height: 20px;
    }
  }
  
  .table-instrument-logo {
    position: absolute;
    top: 9.5px;
    left: 0;
  
    width: 20px;
    height: 20px;
  }
  
  .table-instrument-title {
    margin: 0;
    color: #555555;
    @media (min-width: $tablet-width) {
      margin-right: 3px;
    }
  }
  
  .table-instrument-ticker {
    margin: 0;
    color: #cbd5e1;
  }