.payments {
  @include main-padding-no-heading;
  display: flex;
  flex-direction: column;
}

.payments__item {
  margin-bottom: 13px;
}

.payments__item:last-child {
  margin-bottom: 0;
}

.payments__ad {
  @media (min-width: $desktop-width) {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: start;
  }
}

.payments__order {
  @media (min-width: $desktop-width) {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: start;
  }
}

.payments__failed {
  @media (min-width: $desktop-width) {
    grid-column: 1/3;
  }
}

.payments__last {
  @media (min-width: $desktop-width) {
    grid-column: 1/3;
  }
}
