.trading-results {
  @media (min-width: $desktop-width) {
    min-width: 247px;
  }
}

.demo-insight .trading-results {
  width: 290px;
  margin-bottom: 0;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    width: 295px;
  }
}


.trading-results__table {
  @include list-reset;
  margin-top: -4px;

  display: grid;
  grid-template-columns: 1fr 80px;
}

.trading-results__row {
  display: contents;
}

.trading-results__name,
.trading-results__value {
  margin: 0;
  padding: 10px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.trading-results__row:not(:last-of-type) .trading-results__name,
.trading-results__row:not(:last-of-type) .trading-results__value {
  border-bottom: solid 1px #cbd5e1;
}

.trading-results__name {
  padding-right: 20px;
  color: #828282;

  @media (min-width: $desktop-width) {
    padding-right: 10px;
  }
}

.trading-results__value {
  text-align: end;
  color: #1a1a1a;
}
