.recent-activity {
  min-height: 90px;
}

.recent-activity__table {
  @include list-reset;
  margin-top: -4px;
}

.recent-activity__row {
  display: flex;
  flex-direction: column;
  // cursor: pointer;
}

.recent-activity__title-row,
.recent-activity__row:not(:last-child) {
  border-bottom: solid 1px #cbd5e1;
}

.recent-activity__row-wrapper {
  display: grid;
  grid-template-columns: minmax(54px, 27%) minmax(80px, 31%) minmax(109px, 42%) 15px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(105px, 27%) minmax(129px, 26%) minmax(197px, 47%)
      15px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 80px 144px 129px 15px;
  }
}

.recent-activity__title {
  margin: 0;
  padding: 10px 10px 10px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--result {
    padding-right: 0;
  }
}

.recent-activity__title-gap {
  margin-left: -10px;
}

// 3 column grid
.recent-activity__title--result,
.recent-activity__result {
  display: grid;
  grid-template-columns: minmax(54px, 1.5fr) minmax(50px, 70px);

  @media (min-width: $tablet-width) {
    grid-template-columns: minmax(137px, 1fr) minmax(55px, 75px);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 72px 58px;
  }
}

.recent-activity__title-title {
  padding-right: 14px;
  text-align: right;
}

.recent-activity__result-absolute,
.recent-activity__result-relative {
  display: flex;
  justify-content: flex-end;
}

.recent-activity__toggle {
  display: flex;
  justify-content: right;
  align-items: center;
}
