.nav-toggle {
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 7px;

    width: 26px;
    height: 3px;
    border-radius: 2px;
    background-color: #555555;
    transition: all ease 0.4s;
  }

  &::before {
    bottom: 11px;
  }

  &::after {
    bottom: 17px;
  }
}

.nav-toggle--opened {
  &::before,
  &::after {
    width: 30px;
    height: 4px;
    top: 19px;
    left: 5px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.nav-toggle__icon {
  position: absolute;
  top: 5.5px;
  left: 6.5px;

  width: 27px;
  height: 11px;

  color: #555555;
  transition: all ease 0.5s;
}

.nav-toggle--opened .nav-toggle__icon {
  opacity: 0;
}
