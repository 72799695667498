.page-header {
  position: relative;
  z-index: 100;
  background-color: #fafbfc;

  display: flex;
  flex-direction: column;
  padding: 66px 15px 0 15px;
  min-height: 66px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    position: static;
    grid-row: 1/2;
    grid-column: 1/2;

    min-height: auto;
    width: 245px;
    padding: 135px 10px 30px;
    box-sizing: content-box;

    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  }
}

.page-header__nav {
  @media (max-width: $before-tablet-width) {
    order: 1;
  }
}

.page-header__nav-btns {
  position: absolute;
  top: 12px;
  right: 50px;

  @media (min-width: $tablet-width) {
    top: 13.5px;
    right: 15px;
  }
}

.page-header__nav-toggle {
  position: absolute;
  top: 12px;
  right: 10px;

  @media (min-width: $tablet-width) {
    display: none;
  }
}

.page-header__auth {
  @media (min-width: $tablet-width) {
    position: absolute;
    top: 13.5px;
    right: 15px;

    display: flex;
  }
}

.page-header__demo {
  position: absolute;
  top: 15px;
  left: 67px;

  @media (min-width: $tablet-width) {
    left: 143px;
  }
}
