.pluses {
  @include list-reset;
  display: flex;
  flex-direction: column;

  row-gap: 20px;

  @media (min-width: $tablet-width) {
    row-gap: 20px;
    column-gap: 30px;
  }

  @media (min-width: $desktop-width) {
    gap: 20px;
  }

  // Модификаторы высоты
  &--smard-best {
    @media (min-width: $tablet-width) {
      flex-wrap: wrap;
      max-height: 298px;
    }

    @media (min-width: $desktop-width) {
      height: 241px;
    }
  }

  &--why-works {
    @media (min-width: $tablet-width) {
      flex-wrap: wrap;
      max-height: 284px;
    }

    @media (min-width: $desktop-width) {
      height: 235px;
    }
  }

  &--how-works {
    @media (min-width: $tablet-width) {
      flex-wrap: wrap;
      max-height: 522px;
    }

    @media (min-width: $desktop-width) {
      height: 278px;
    }
  }

  &--okx,
  &--bitget,
  &--binance {
    @media (min-width: $tablet-width) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &--what-is-smard {
    @media (min-width: $tablet-width) {
      flex-wrap: wrap;
      // для 2 колонок
      // max-height: 380px;

      // для 3 колонок
      max-height: 340px;
    }

    @media (min-width: $desktop-width) {
      height: 258px;
    }
  }
}

.pluses__item {
  padding: 14px 20px;
  // В другом блочном классе есть width!
  box-sizing: border-box;

  border-radius: 5px;
  background: linear-gradient(
    111.14deg,
    rgba(59, 130, 246, 0.15) 0%,
    rgba(59, 130, 246, 0.05) 103.29%
  );

  &--top-less {
    padding-top: 9px;
  }
}

// Модификаторы колонок
.pluses--tablet-2-columns .pluses__item {
  @media (min-width: $tablet-width) {
    width: calc((100% - 30px) / 2);
  }

  @media (min-width: $desktop-width) {
    width: 514px;
  }
}

.pluses--tablet-3-columns .pluses__item {
  @media (min-width: $tablet-width) {
    width: calc((100% - 60px) / 3);
  }

  @media (min-width: $desktop-width) {
    width: 337px;
  }
}

.pluses--desktop-4-columns .pluses__item {
  @media (min-width: $desktop-width) {
    width: 247px;
  }
}

.pluses--desktop-3-columns .pluses__item {
  @media (min-width: $desktop-width) {
    width: 337px;
  }
}

//

.pluses__heading {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0;
  margin-bottom: 5px;

  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #1a1a1a;

  &--right-less {
    margin-right: -10px;
  }
}

.pluses__icon {
  flex-shrink: 0;
  align-self: flex-start;
  display: block;
}

.pluses__info {
  margin-top: 0;
  margin-bottom: 0;

  font-size: 14px;
  line-height: 19px;
}

// smard-best
.pluses--smard-best {
  .pluses__icon {
    &--rate {
      width: 26px;
      height: 26px;
    }

    &--commissions {
      width: 26px;
      height: 26px;
    }

    &--payouts {
      width: 33px;
      height: 24px;
      margin-top: 7px;
    }

    &--tracking {
      width: 25px;
      height: 27px;
    }
  }
}

// why-works
.pluses--why-works {
  .pluses__item {
    &--risk {
      @media (min-width: $tablet-width) {
        order: 2;
      }
    }

    &--position {
      @media (min-width: $tablet-width) {
        order: 3;
      }
    }

    &--long {
      @media (min-width: $tablet-width) {
        order: 1;
      }
    }

    &--lock {
      @media (min-width: $tablet-width) {
        order: 3;
      }
    }

    &--transfer {
      @media (min-width: $tablet-width) {
        order: 2;
      }
    }
  }

  .pluses__icon {
    &--momentum {
      width: 22px;
      height: 22px;
    }

    &--automated {
      width: 35px;
      height: 35px;
    }

    &--transfer {
      width: 28px;
      height: 28px;
    }

    &--lock {
      width: 26px;
      height: 28px;
    }

    &--risk {
      width: 26px;
      height: 26px;
    }

    &--position {
      width: 22px;
      height: 22px;
    }

    &--long {
      width: 26px;
      height: 26px;
    }
  }
}

// how-works
.pluses--how-works {
  .pluses__item {
    &--transparency {
      @media (min-width: $tablet-width) {
        order: 1;
      }
    }

    &--flexibility {
      @media (min-width: $tablet-width) {
        order: 2;
      }

      @media (min-width: $desktop-width) {
        order: 3;
      }
    }

    &--billing {
      @media (min-width: $tablet-width) {
        order: 3;
      }

      @media (min-width: $desktop-width) {
        order: 5;
      }
    }

    &--risks {
      @media (min-width: $tablet-width) {
        order: 4;
      }

      @media (min-width: $desktop-width) {
        order: 2;
      }
    }

    &--access {
      @media (min-width: $tablet-width) {
        order: 5;
      }

      @media (min-width: $desktop-width) {
        order: 4;
      }
    }

    &--security {
      @media (min-width: $tablet-width) {
        order: 6;
      }
    }
  }

  .pluses__icon {
    &--automated {
      width: 35px;
      height: 35px;
    }

    &--risks {
      width: 28px;
      height: 28px;
    }

    &--access {
      width: 27px;
      height: 27px;
    }

    &--security {
      width: 19px;
      height: 28px;
    }

    &--transparency {
      width: 26px;
      height: 28px;
    }

    &--flexibility {
      width: 26px;
      height: 26px;
    }

    &--billing {
      width: 19px;
      height: 21px;
    }
  }
}

// okx
.pluses--okx {
  .pluses__icon {
    width: 28px;
    height: 28px;
  }
}

//// bybit
//.pluses--okx {
//  .pluses__icon {
//    width: 28px;
//    height: 28px;
//  }
//}

// bitget
.pluses--bitget {
  .pluses__icon {
    &--diversified {
      width: 34px;
      height: 28px;
    }

    &--flexible {
      width: 29px;
      height: 29px;
    }

    &--independence {
      width: 31px;
      height: 25px;
    }
  }
}

// binance
.pluses--binance {
  .pluses__icon {
    &--automated {
      width: 35px;
      height: 35px;
    }

    &--prosperity {
      width: 28px;
      height: 28px;
    }

    &--payments {
      width: 16px;
      height: 28px;
    }
  }
}

// what-is-smard
.pluses--what-is-smard {
  .pluses__icon {
    &--easy {
      width: 26px;
      height: 26px;
    }

    &--no-payments {
      width: 16px;
      height: 28px;
    }

    &--long {
      width: 26px;
      height: 26px;
    }

    &--support {
      width: 28px;
      height: 26px;
    }

    &--effect {
      width: 22px;
      height: 22px;
    }
  }
}
