.card-form__wrapper {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 12px;

  padding-top: 8px;
  padding-bottom: 1px;

  @media (min-width: $tablet-width) {
    padding-left: 30px;
  }

  &--with-cards {
    @media (min-width: $tablet-width) {
      padding-left: 54px;
    }
  }
}

.card-form__fields-wrapper {
  display: flex;
  column-gap: 10px;
}

.card-form__icon {
  display: none;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 21px;
    left: 0;
    display: flex;
    width: 19px;
    height: 14px;
    margin: 0 0.5px 0.5px 0;
    color: #828282;
  }
}

.card-form__wrapper--with-cards .card-form__icon {
  left: 24px;
}

.card-form__input-wrapper {
  position: relative;

  &--number {
    flex-grow: 1;
    min-width: 258px;
    max-width: 387px;

    @media (min-width: $tablet-width) {
      max-width: 411px;
    }

    @media (min-width: $desktop-width) {
      flex-grow: 0;
      min-width: auto;
      width: 250px;
    }
  }
}

.card-form__wrapper--with-cards .card-form__input-wrapper {
  &--number {
    @media (min-width: $tablet-width) {
      max-width: 387px;
    }

    @media (min-width: $desktop-width) {
      width: 226px;
    }
  }
}

.card-form__input {
  height: 40px;
  padding: 12px 8px;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    padding: 13px 10px;
  }

  &--expiry {
    width: 67px;

    @media (min-width: $tablet-width) {
      width: 79px;
    }
  }

  &--cvc {
    width: 43px;

    @media (min-width: $tablet-width) {
      width: 55px;
    }
  }

  &--invalid {
    border-color: #fa755a;
  }

  &:hover {
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  }
}

.StripeElement--focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 2px 2px 4px rgba(59, 130, 246, 0.2);
}

.card-form__btn-add-card {
  position: relative;
  margin-left: auto;
}

.card-form__input-error {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -7px;
  line-height: 0;

  transform: translate(0, -50%);
}

.card-form__message {
  @media (min-width: $tablet-width) {
    margin-left: 30px;
  }
}
