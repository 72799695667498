.binance-canvas {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 45px 10px 15px;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.binance-canvas__logo {
  position: absolute;
  top: 10px;
  left: 10px;

  display: flex;
  align-items: center;
  gap: 13px;
  height: 22px;

  @media (min-width: $desktop-width) {
    top: 13px;
    left: 16px;
    height: 27px;
  }
}

.binance-canvas__icon {
  display: flex;
  height: 22px;

  @media (min-width: $desktop-width) {
    height: 27px;
  }

  &--binance-spot {
    width: 110px;

    @media (min-width: $desktop-width) {
      width: 135px;
    }
  }

  &--binance-futures {
    width: 100px;

    @media (min-width: $desktop-width) {
      width: 123px;
    }
  }

  &--plus {
    width: 10px;
    height: 10px;
    margin-top: -2px;
    color: #555555;
  }
}
