.link {
  color: #3b82f6;

  &:hover {
    color: #2563eb;
  }

  &:focus {
    color: #2563eb;
    outline: none;
  }

  &:active {
    color: #1a1a1a;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &--blocked {
    pointer-events: none;
  }
}

.link--underline {
  text-decoration: underline;
  // position: relative;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 1px;
  //   left: 0;
  //   bottom: 0.5px;
  //   background-color: #3b82f6;
  // }

  // &:hover::after {
  //   background-color: #2563eb;
  // }

  // &:focus {
  //   background-color: #2563eb;
  // }

  // &:active {
  //   background-color: #1a1a1a;
  // }
}
