.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;

  color: #555555;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  &--apple {
    position: relative;
  }

  &--telegram {
    position: relative;
  }

  @include animation-lift-shadow-onhover-add;

  &--google:hover,
  &--google:active {
    color: #ffffff;
    background-color: #e25f50;
  }

  &--apple:hover,
  &--apple:active {
    color: #ffffff;
    background-color: #1a1a1a;
  }

  &--telegram:hover,
  &--telegram:active {
    color: #ffffff;
    background-color: #0088cc;
  }

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
    outline-offset: -1px;
  }

  &--disabled {
    pointer-events: none;
  }
}

.login__icon {
  display: flex;
  font-size: 0;
  line-height: 0;

  &--google {
    width: 20px;
    height: 20px;
  }

  &--apple {
    width: 20px;
    height: 24px;
    margin-top: -3px;
  }

  &--telegram {
    width: 23px;
    height: 20px;
  }
}

.login__telegram-frame {
  position: absolute;
  top: 0;
  left: 0;

  width: 45px;
  height: 40px;

  transform: scaleY(1.125);
  transform-origin: top left;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.01;
}

.login__apple-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;

  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
}
