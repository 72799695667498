.overview {
  @include main-padding;

  @media (min-width: $tablet-width) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.overview__welcome {
  @include outer-heading;
}

.overview__wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.overview__list {
  @include list-reset;
  display: flex;
  flex-direction: column;
  gap: 13px;
}
