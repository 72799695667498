.relative-canvas {
  position: relative;
  padding: 53px 16px 15px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    padding-top: 56px;
  }

  @media (min-width: $desktop-width) {
    width: 336.6px;
    box-sizing: border-box;
    padding-top: 62px;
  }
}

.relative-canvas__logo {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  height: 22px;

  @media (min-width: $desktop-width) {
    height: 27px;
  }

  &--binance-spot {
    width: 110px;

    @media (min-width: $desktop-width) {
      width: 135px;
    }
  }

  &--binance-futures {
    width: 100px;

    @media (min-width: $desktop-width) {
      width: 123px;
    }
  }

  &--okx {
    width: 71px;

    @media (min-width: $desktop-width) {
      width: 87px;
    }
  }

  &--bybit {
    width: 77px;
    height: 27px;
  }

  &--bitget-futures {
    top: 12px;
    width: 87px;
    height: 28px;

    @media (min-width: $desktop-width) {
      top: 11px;
      width: 103px;
      height: 32px;
    }
  }
}

.relative-canvas__popular {
  position: absolute;
  top: 6px;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 47px;

  font-size: 20px;
  line-height: 15px;
  color: #05d991;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;

  background-color: rgba(206, 255, 228, 0.708);
  border-radius: 10px;
}

.relative-canvas__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #555555;

  margin: 0 0 16px;
}

.relative-canvas__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.relative-canvas__chart {
  margin: 0 -6px;
}

.relative-canvas__bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.relative-canvas__toggle {
  align-self: center;
}
