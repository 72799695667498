.landing {
  padding: 76px 0 60px;

  @media (min-width: $tablet-width) {
    padding: 10px 0 50px;
  }

  @media (min-width: $desktop-width) {
    width: $desktop-width;
    margin: auto;
    padding: 10px 0 50px;
  }
}

.landing__wrapper {
  padding: 0 15px;
}

.landing__heading {
  margin-top: 0;
  margin-bottom: 20px;

  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 39px;
  }
}

.landing__heading-large {
  text-transform: uppercase;
}

.landing__heading-text-tool:before {
  content: "tool";
}

.landing__title {
  margin-top: 0;
  margin-bottom: 30px;

  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: $desktop-width) {
    font-size: 22px;
    line-height: 31px;
  }

  &--blue {
    color: #3b82f6;
  }

  &--faq {
    margin-bottom: 20px;
  }
}

.landing__item {
  margin-bottom: 60px;

  @media (min-width: $tablet-width) {
    margin-bottom: 80px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 100px;
  }

  &--with-bg {
    @media (min-width: $tablet-width) {
      margin-bottom: 10px;
    }
  }

  &--pricing {
    margin-bottom: 20px;

    @media (min-width: $tablet-width) {
      margin-bottom: 40px;
    }
    @media (min-width: $desktop-width) {
      margin-bottom: 60px;
    }
  }
  &--pricing-fees {
    margin-bottom: 20px;

    @media (min-width: $tablet-width) {
      margin-bottom: 30px;
    }
    @media (min-width: $desktop-width) {
      margin-bottom: 35px;
    }
  }
}

.landing__item:last-child {
  margin-bottom: 0;
}

.landing__relative-gap {
  min-height: 570px;
  margin-bottom: 60px;

  @media (min-width: $tablet-width) {
    min-height: 603px;
    margin-bottom: 80px;
  }

  @media (min-width: $desktop-width) {
    min-height: 581px;
    margin-bottom: 100px;
  }
}

.landing__btn-gap {
  width: 120px;
  height: 40px;
}
