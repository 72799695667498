.sk-payments-fee {
  display: block;
  width: 38px;
  height: 28px;

  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $desktop-width) {
    width: 45px;
  }
}
