.payments-order {
  box-sizing: border-box;
  padding-bottom: 8px;

  @media (min-width: $desktop-width) {
    min-height: 267px;
  }
}

.payments-order__intro {
  margin-top: 0;
  margin-bottom: 10px;

  font-size: 11px;
  line-height: 15px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.payments-order__table {
  @include list-reset;
}
