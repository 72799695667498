.sk-details-canvas {
  @include block-shadow;

  display: flex;
  flex-direction: column;
  padding: 10px 16px;

  @media (min-width: $desktop-width) {
    padding: 20px 25px 3px;
  }
}

.sk-details-canvas__chart {
  margin: 0 20px 20px;
  height: 180px;
  background-color: #e1ebf8;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    height: 255px;
    margin: 21px 25px 40px;
  }

  @media (min-width: $desktop-width) {
    height: 240px;
    margin: 0 25px 30px;
  }
}

.sk-details-canvas__toggle {
  height: 25px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $desktop-width) {
    margin: 10px 0;
  }
}

.sk-details-canvas__toggle:first-child {
  @media (min-width: $tablet-width) {
    display: contents;
  }
}
