.contacts {
  @include list-reset;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: $tablet-width) {
    gap: 10px;
  }
}

.contacts__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;

  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  @include animation-lift-shadow-onhover-add;

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
    outline-offset: -1px;
  }

  &:active {
    background: #dbeafe;
  }
}

.contacts__icon {
  display: flex;
  font-size: 0;
  line-height: 0;
  color: #2563eb;

  &--telegram {
    width: 23px;
    height: 20px;
  }

  &--email {
    width: 20px;
    height: 16px;
  }

  &--twitter {
    width: 25px;
    height: 20px;
  }

  &--linkedin {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }
}
