.sk-referrals-intro-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 168px;
  padding: 3px 0;

  @media (min-width: $tablet-width) {
    width: 140px;
    padding: 4px 0;
    gap: 4px;
  }

  @media (min-width: $desktop-width) {
    width: 163px;
    padding: 5px 0;
    gap: 6px;
  }
}

.sk-referrals-intro-step__text {
  height: 14px;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    height: 16px;
  }

  &:first-child {
    width: 100%;
  }

  &:nth-child(2) {
    width: 100px;

    @media (min-width: $desktop-width) {
      width: 130px;
    }
  }
}
