.coin-canvas {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px;
}

.coin-canvas__info {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.coin-canvas__info-item {
  display: flex;
  align-items: center;
  margin: 0;
}

.coin-canvas__info-coin {
  display: flex;
  width: 18px;
  height: 18px;
  margin-right: 5px;

  @media (min-width: $tablet-width) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.coin-canvas__info-percent {
  display: flex;
  align-items: center;
  width: 57px;

  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--plus {
    color: #30e733;
  }

  &--minus {
    color: #f93232;
  }
}

.coin-canvas__info-percent-icon {
  display: flex;
  width: 8px;
  height: 4px;
  margin-right: 2px;
}

.coin-canvas__info-percent--minus .coin-canvas__info-percent-icon {
  margin-top: 2px;
}

.coin-canvas__toggles-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  row-gap: 5px;
}

.coin-canvas__toggles-inner-wrapper {
  display: flex;
  align-items: center;
}

.coin-canvas__vs {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #555555;
  margin-right: 5px;
}

.coin-canvas__toggle-coin {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.coin-canvas__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
