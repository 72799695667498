.setup {
  @include main-padding-no-heading;
}

.setup__wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 16px 0;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.setup__heading {
  align-self: center;
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;

  @media (min-width: $tablet-width) {
    margin-bottom: 25px;
    line-height: 25px;
  }

  @media (min-width: $desktop-width) {
    font-size: 22px;
    line-height: 30px;
  }
}

.setup__heading-inner {
  margin: 5px 0 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  @media (min-width: $tablet-width) {
    line-height: 21px;
  }

  @media (min-width: $desktop-width) {
    font-size: 20px;
    line-height: 26px;
  }
}

.setup__intro,
.setup__paragraph,
.setup__intro-list,
.setup__paragraph-list,
.setup-intro__item {
  @include doc-paragraph-font;
  text-align: justify;

  margin-top: 0;
  margin-bottom: 10px;
}

.setup__list {
  @include list-reset;
}

.setup__paragraph-list {
  list-style-type: disc;
}

.setup__intro-list {
  padding-left: 16px;
}

.setup__intro-item:not(:last-child),
.setup__paragraph-item:not(:last-child) {
  margin-bottom: 5px;
}

.setup__intro-scroll {
  @include doc-paragraph-font;

  padding: 0;

  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  color: #555555;

  background-color: transparent;
  border: solid 1.5px transparent;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  background-color: transparent;
}

.setup__item {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    margin-bottom: 13px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 15px;
  }
}

.setup__item:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.setup__img {
  align-self: center;

  margin-top: 0;
  margin-bottom: 10px;

  width: 100%;
  height: auto;

  &--short {
    width: 258px;
  }

  &--medium {
    max-width: 400px;
  }

  &--big {
    max-width: 570px;
  }

  &--app3 {
    max-width: 430px;

    @media (min-width: $tablet-width) {
      max-width: 420px;
      margin-left: 21px;
    }

    @media (min-width: $desktop-width) {
      max-width: 475px;
      margin-left: 80px;
    }
  }

  &--app6 {
    max-width: 390px;
  }

  &--app7 {
    max-width: 350px;
  }

  &--app8 {
    max-width: 80%;
  }

  &--app9 {
    max-width: 60%;
  }
}

.setup__post {
  margin-top: -15px;
  margin-bottom: 30px;

  @include doc-paragraph-font;
  text-align: justify;

  @media (min-width: $tablet-width) {
    margin-bottom: 33px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 35px;
  }
}
