.board-sign {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;

  &--plus {
    width: 8px;
    height: 9px;
  }

  &--minus {
    width: 8px;
    height: 1.6px;
    margin-top: 2px;
  }

  &--colored-plus {
    width: 8px;
    height: 9px;
    color: #2bbc8b;
  }

  &--colored-minus {
    width: 8px;
    height: 1.6px;
    margin-top: 2px;
    color: #f93232;
  }

  &--equal {
    width: 9px;
    height: 5px;
    color: #1a1a1a;
  }
}
