.current-position__table {
  @include list-reset;
  margin-top: -4px;
}

.current-position__row {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &--without-chart {
    cursor: auto;
  }
}

.current-position__title-row,
.current-position__row:not(:last-child) {
  border-bottom: solid 1px #cbd5e1;
}

.current-position__row-wrapper {
  display: grid;
  grid-template-columns: minmax(81px, 38%) minmax(45px, 20%) minmax(109px, 42%) 15px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(134px, 30%) minmax(100px, 23%) minmax(197px, 47%)
      15px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 144px 55px 124px 15px;
  }
}

.current-position__title {
  margin: 0;
  padding: 10px 10px 10px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--amount {
    padding-right: 0;
  }
}

.current-position__title-gap {
  margin-left: -10px;
}

// 2 column grid
.current-position__title--quantity {
  padding-right: 14px;
  text-align: end;
}

.current-position__quantity {
  text-align: end;
}

// 3 column grid
.current-position__title--amount,
.current-position__amount {
  display: grid;
  grid-template-columns: minmax(54px, 1.5fr) minmax(50px, 70px);

  @media (min-width: $tablet-width) {
    grid-template-columns: minmax(137px, 1fr) minmax(55px, 75px);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 72px 58px;
  }
}

.current-position__title-title {
  padding-right: 14px;
  text-align: right;
}

.current-position__toggle {
  display: flex;
  justify-content: right;
  align-items: center;
}

.current-position__quantity,
.current-position__amount-absolute,
.current-position__amount-relative {
  display: flex;
  justify-content: flex-end;
}
