@mixin tablet-second-column {
  @media (min-width: $tablet-width) {
    position: relative;
    justify-self: center;
    width: 120px;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
    width: auto;
  }
}

@mixin tablet-third-column {
  @media (min-width: $tablet-width) {
    justify-self: center;
    width: 116px;
    margin-left: -26px;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
    width: auto;
    margin-left: 0;
  }
}

.overview-summary {
  padding: 0 15px;
}

.overview-summary__list {
  @include list-reset;
  display: grid;
  grid-template-columns: repeat(2, minmax(130px, 1fr));
  row-gap: 10px;
  padding: 15px 0;
  justify-items: center;

  @media (min-width: $tablet-width) {
    grid-template-columns: minmax(125px, 1fr) minmax(205px, 2fr) minmax(
        113px,
        1fr
      );
    row-gap: 15px;
    justify-items: flex-start;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 162px 219px 230px 144px;
  }
}

.overview-summary__list:not(:last-of-type) {
  border-bottom: solid 1px #cbd5e1;
}

.overview-summary__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 130px;
  padding-left: 26px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    width: 100px;
    gap: 3px;
  }

  &--all-time-return {
    @include tablet-second-column;
  }

  &--smard-wallet {
    @include tablet-third-column;
  }

  &--to-pay {
    grid-row: 2/3;
    grid-column: 1/-1;
    margin-left: 45px;

    @media (min-width: $tablet-width) {
      @include tablet-third-column;
      grid-row: auto;
      grid-column: auto;
      margin-left: 0;
    }
  }

  &--btn {
    justify-self: center;
    grid-column: 1/-1;
    width: 120px;
    padding-left: 0;

    @media (min-width: $desktop-width) {
      justify-self: flex-end;
      align-self: center;
      margin-right: -1px;
      grid-column: auto;
    }
  }

  // 2 и 3 lists

  &--month-return {
    @include tablet-second-column;
  }

  &--service-paid {
    @include tablet-third-column;
  }

  &--all-time-return-percent {
    @include tablet-third-column;
  }

  &--referral-commission {
    @media (min-width: $tablet-width) {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    @media (min-width: $desktop-width) {
      grid-row: auto;
      grid-column: auto;
    }
  }

  &--month-earned {
    @include tablet-second-column;

    @media (min-width: $tablet-width) {
      grid-row: 2/3;
      grid-column: 2/3;
    }

    @media (min-width: $desktop-width) {
      grid-row: auto;
      grid-column: auto;
    }
  }

  &--total-referrals {
    @media (min-width: $tablet-width) {
      grid-row: 3/4;
      grid-column: 1/2;
    }

    @media (min-width: $desktop-width) {
      grid-row: auto;
      grid-column: auto;
    }
  }

  &--referrals-earned {
    @include tablet-second-column;
  }
}

.overview-summary__icon-wrapper {
  position: absolute;
  top: 4px;
  left: 0px;

  width: 21px;
  height: 16px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-summary__icon {
  display: flex;
  color: #828282;

  &--current-balace {
    margin-top: 1px;

    width: 18px;
    height: 13px;
  }

  &--all-time-return {
    width: 18px;
    height: 16px;
  }

  &--smard-wallet {
    margin-top: 1px;

    width: 19px;
    height: 14px;
    scale: 0.95;
  }

  &--to-pay {
    margin-top: -1px;
    width: 16px;
    height: 15px;
  }

  &--next-payment {
    margin-top: -1px;
    width: 16px;
    height: 15px;
  }

  &--service-fee {
    width: 14px;
    height: 16px;
  }

  &--month-return {
    margin-top: -1px;
    width: 18px;
    height: 16px;
  }

  &--referral-commission {
    width: 16px;
    height: 16px;
  }

  &--referrals-earned {
    width: 17px;
    height: 16px;
  }

  &--service-paid {
    margin-top: 1px;

    width: 19px;
    height: 14px;
    scale: 0.95;
  }

  &--all-time-return-percent {
    width: 18px;
    height: 16px;
  }

  &--total-referrals {
    width: 15px;
    height: 15px;
  }

  &--month-earned {
    width: 16px;
    height: 16px;
  }
}

.overview-summary__amount {
  display: flex;
  gap: 3px;
  align-items: center;

  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  white-space: nowrap;

  @media (min-width: $tablet-width) {
    font-size: 17px;
  }

  &--up {
    color: #2bbc8b;
  }

  &--down {
    color: #f93232;
  }
}

.overview-summary__title {
  position: relative;
  display: block;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  text-wrap: nowrap;
  white-space: nowrap;
}
