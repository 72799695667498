@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.smard.club/assets/fonts/opensans.woff2") format("woff2"),
    url("https://cdn.smard.club/assets/fonts/opensans.woff") format("woff"),
    url("../../fonts/opensans.woff2") format("woff2"),
    url("../../fonts/opensans.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.smard.club/assets/fonts/opensansSemibold.woff2")
      format("woff2"),
    url("https://cdn.smard.club/assets/fonts/opensansSemibold.woff")
      format("woff"),
    url("../../fonts/opensansSemibold.woff2") format("woff2"),
    url("../../fonts/opensansSemibold.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.smard.club/assets/fonts/opensansBold.woff2")
      format("woff2"),
    url("https://cdn.smard.club/assets/fonts/opensansBold.woff") format("woff"),
    url("../../fonts/opensansBold.woff2") format("woff2"),
    url("../../fonts/opensansBold.woff") format("woff");
}

@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.smard.club/assets/fonts/gothamPro.woff2")
      format("woff2"),
    url("https://cdn.smard.club/assets/fonts/gothamPro.woff") format("woff"),
    url("../../fonts/gothamPro.woff2") format("woff2"),
    url("../../fonts/gothamPro.woff") format("woff");
}
