.personal__list {
  max-width: 310px;
}

.personal__link-list,
.personal__login-list {
  @include list-reset;
}

.personal__item {
  margin-left: 25px;
  margin-right: 15px;

  @media (min-width: $tablet-width) {
    margin-left: 0;
    margin-right: 4px;
  }

  &--pricing,
  &--faq {
    margin-bottom: 15px;

    @media (min-width: $tablet-width) {
      display: none;
    }
  }

  &--overview {
    margin-bottom: 15px;
    @media (min-width: $tablet-width) {
      display: none;
    }
  }
}

.personal__link {
  // Для телеграма
  position: relative;

  display: flex;
  align-items: center;
  color: #555555;
  cursor: pointer;

  &--apple {
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  &:hover {
    color: #3b82f6;
  }

  &:focus {
    color: #2563eb;
  }

  &:active {
    color: #1a1a1a;
  }
}

.personal__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 24px;
  margin: 0 8px 0 0;

  @media (min-width: $tablet-width) {
    width: 25px;
    margin-right: 9px;
  }
}

.personal__icon {
  display: flex;
  line-height: 0;
  color: #555555;

  &--apple {
    width: 15px;
    height: 18px;
    margin-top: -3px;
    margin-left: 2px;

    @media (min-width: $tablet-width) {
      width: 17px;
      height: 20px;
      margin-top: -4px;
      margin-left: 1px;
    }
  }

  &--google {
    width: 15px;
    height: 15px;

    @media (min-width: $tablet-width) {
      width: 16px;
      height: 16px;
    }
  }

  &--telegram {
    width: 17px;
    height: 14px;

    @media (min-width: $tablet-width) {
      width: 18px;
      height: 15px;
    }
  }

  &--exit {
    width: 15.5px;
    height: 15.5px;

    @media (min-width: $tablet-width) {
      width: 17.5px;
      height: 17.5px;
    }
  }

  &--pricing {
    width: 16px;
    height: 16px;
  }

  &--faq {
    width: 16px;
    height: 16px;
  }

  &--overview {
    width: 15px;
    height: 11px;
  }
}

.personal__heading {
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 2px;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
}

.personal__link--apple .personal__heading {
  flex-grow: 0;
}

.personal__description {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 20px;

  font-size: 12px;
  line-height: 16px;
  color: #9e9daf;

  @media (min-width: $tablet-width) {
    padding-left: 35px;
  }
}

.personal__close {
  position: absolute;
  top: 12px;
  right: 10px;

  width: 40px;
  height: 40px;
  padding: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    display: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    top: 19px;
    left: 5px;

    border-radius: 2px;
    background-color: #555555;
    transition: all ease 0.4s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.personal__link-telegram-frame {
  position: absolute;
  top: 0;
  left: 0;

  width: 180px;
  height: 40px;

  transform: scaleY(0.625);
  transform-origin: top left;
  overflow: hidden;
  opacity: 0.01;
}
