.partnership-income {
  padding: 20px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 354px;
    max-width: unset;
  }

  @media (min-width: $desktop-width) {
    width: 515px;
    padding: 20px 30px;
  }
}

.partnership-income__heading {
  margin-top: 0;
  margin-bottom: 9px;

  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: $desktop-width) {
    font-size: 28px;
    line-height: 39px;
  }
}

.partnership-income__amount {
  margin-top: 0;
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  color: #4489f6;

  @media (min-width: $tablet-width) {
    font-size: 40px;
    line-height: 54px;
  }

  @media (min-width: $desktop-width) {
    font-size: 55px;
    line-height: 75px;
  }
}

.partnership-income__toggle {
  margin-bottom: 15px;

  @media (min-width: $tablet-width) {
    margin-bottom: 20px;
  }
}

.partnership-income__label {
  display: block;
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 11px;
  }
}

// range

.partnership-income__slider {
  width: 250px;
  height: 30px;

  @media (min-width: $tablet-width) {
    width: 296px;
  }

  @media (min-width: $desktop-width) {
    width: 414px;
  }
}

.partnership-income__thumb {
  position: absolute;
  top: -4px;

  width: 20px;
  height: 20px;

  background: #ffffff;
  border-radius: 100%;

  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  outline: none;

  cursor: pointer;

  @media (min-width: $tablet-width) {
    top: -7px;

    width: 30px;
    height: 30px;
  }
}

.partnership-income__mark {
  position: absolute;
  top: 50%;
  left: 50%;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  transform: translate(-50%, -50%);

  @media (min-width: $tablet-width) {
    font-size: 16px;
  }
}

.partnership-income__value {
  position: absolute;
  top: 20px;
  left: 50%;

  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;
  text-align: center;
  white-space: nowrap;

  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    top: 33px;
    font-size: 13px;
  }
}

// .calc-block__thumb.active {
//   background-color: #3b82f6;
// }

.partnership-income__track {
  background: #ffffff;

  height: 12px;
  border-radius: 6px;
  box-shadow: inset 0 0 3px 0.2px rgba(179, 178, 178, 0.74);

  @media (min-width: $tablet-width) {
    height: 16px;
    border-radius: 8px;
  }
}

.partnership-income__track-0 {
  background: #3b83f6f3;
  box-shadow: none;
}

//

.partnership-income__note {
  margin: 0;

  font-size: 10px;
  line-height: 14px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 11px;
    line-height: 15px;
  }

  @media (min-width: $desktop-width) {
    font-size: 12px;
    line-height: 18px;
  }
}
