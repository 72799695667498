.last-orders__table {
  @include list-reset;
  margin-top: -4px;
}

.last-orders__row {
  display: flex;
  flex-direction: column;
  // cursor: pointer;
}

.last-orders__title-row,
.last-orders__row:not(:last-child) {
  border-bottom: solid 1px #cbd5e1;
}

.last-orders__row-wrapper {
  display: grid;
  grid-template-columns: 70px 20px minmax(98px, 80%) minmax(70px, 20%);

  @media (min-width: $tablet-width) {
    grid-template-columns: 70px 20px minmax(298px, 80%) minmax(80px, 20%);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 70px 20px 178px 100px;
  }
}

.last-orders__title {
  margin: 0;
  padding: 10px 10px 10px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--quantity {
    text-align: end;
    padding-right: 14px;
  }
}

.last-orders__quantity {
  display: flex;
  justify-content: flex-end;
}

.last-orders__side {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: $tablet-width) {
    margin-left: 3px;
  }
}

.last-orders__side-icon {
  display: flex;
  width: 7px;
  height: 4px;

  &--sell {
    color: #f93232;
  }

  &--buy {
    color: #59ec5c;
  }
}
