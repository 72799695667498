.payments-balance {
  display: flex;
  justify-content: space-between;
  padding: 13px 16px;
}

.payments-balance__wrapper {
  display: flex;
  align-items: center;
  min-height: 35px;
}

.payments-balance__icon {
  display: flex;
  width: 35px;
  height: 31px;
  margin-right: 10px;
  color: #555;
}

.payments-balance__heading {
  margin: 2px 0 0;
}

.payments-balance__amount {
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 22px;
    line-height: 29px;
  }

  &--plus {
    color: #2bbc8b;
  }

  &--minus {
    color: #f93232;
  }
}
