.coin-description {
  position: relative;

  @media (min-width: $desktop-width) {
    display: flex;
    justify-content: space-between;
  }
}

.coin-description__banner {
  width: 100%;
  height: 333px;
  margin-bottom: 0;

  background: url("../../img/banner/cell-banner.png") center bottom -82px/240px 260px
      no-repeat,
    url("../../img/banner/chart-vector-banner.svg") right bottom -70px / auto 75%
      no-repeat;

  @media (min-width: $tablet-width) {
    height: 200px;

    background: url("../../img/banner/cell-banner.png") right bottom -82px/240px
        260px no-repeat,
      url("../../img/banner/chart-vector-banner.svg") center bottom -10px/105% 80%
        no-repeat;
  }

  @media (min-width: $desktop-width) {
    order: 1;
    position: sticky;
    margin-top: 27px;
    margin-bottom: 40px;

    top: 0;
    width: 340px;
    height: 439px;

    background: url("../../img/banner/cell-banner.png") center bottom -140px/380px
        410px no-repeat,
      url("../../img/banner/chart-vector-banner.svg") center bottom/700px 70%
        no-repeat;
  }
}

.coin-description__banner-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $tablet-width) {
    align-items: start;
    width: 247px;
    margin-left: 30px;
  }

  @media (min-width: $desktop-width) {
    align-items: center;
    width: auto;
    margin-left: 0;
  }
}

.coin-description__banner-logo {
  display: flex;
  width: 112px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.coin-description__banner-title {
  margin-top: 0;
  margin-bottom: 14px;
  padding: 0 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: center;

  @media (min-width: $tablet-width) {
    padding: 0;
    font-size: 22px;
    line-height: 30px;
    text-align: start;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
  }
}

.coin-description__banner-title em {
  font-style: normal;
  color: #3b82f6;
}

.coin-description__info {
  display: flex;
  flex-direction: column;
  padding: 27px 0 40px 0;

  @media (min-width: $desktop-width) {
    width: 426px;
    min-height: 439px;
  }

  &--no-banner {
    @media (min-width: $desktop-width) {
      width: auto;
      min-height: auto;
    }
  }
}

.coin-description__heading {
  margin-top: 0;
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;

  @media (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 32px;
  }
}

.coin-description__paragraph {
  margin: 0;

  font-size: 11px;
  line-height: 15px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.coin-description__info-btn {
  align-self: center;
  margin-top: 15px;

  @media (min-width: $desktop-width) {
    margin-top: 20px;
  }
}
