.table-note {
    margin-top: 0;
    margin-bottom: 10px;

    font-size: 11px;
    line-height: 15px;
    color: #828282;

    @media (min-width: $tablet-width) {
      font-size: 12px;
      line-height: 16px;
    }

    &--alert {
      color: #f93232;
    }
  }