.field {
  min-width: 100px;

  height: 40px;
  padding: 7px 14px 9px;
  box-sizing: border-box;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  color: #828282;

  border: 1px solid #cbd5e1;
  border-radius: 5px;

  @media (min-width: $desktop-width) {
    padding: 7px 12px 9px;
  }

  &--disabled {
    pointer-events: none;
  }

  &--invalid {
    border-color: #f93232;
  }

  &--select {
    position: relative;
  }
}

.field:hover {
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
}

.field:focus,
.field:active {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 2px 2px 4px rgba(59, 130, 246, 0.2);
}

.field__icon {
  position: absolute;
  top: 18px;
  right: 6px;
  display: flex;
  width: 8px;
  height: 5px;
  color: #828282;
}
