.affiliates {
  display: flex;
  flex-direction: column;
}

.affiliates__list {
  @include list-reset;
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  justify-content: center;
  gap: 20px;

  @media (min-width: $tablet-width) {
    align-self: stretch;
    grid-template-columns: repeat(auto-fit, 226px);
    column-gap: 30px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: repeat(auto-fit, 336.5px);
    column-gap: 20px;
  }
}

.affiliate__link {
  display: flex;
  flex-direction: column;
  width: 290px;
  padding: 14px 20px 20px;
  box-sizing: border-box;

  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);

  @include animation-lift-shadow-onhover-add;

  @media (min-width: $tablet-width) {
    width: 226px;
  }

  @media (min-width: $desktop-width) {
    width: 337px;
  }

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
  }

  &:active {
    box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
  }
}

.affiliate__head {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 3.5px 0 3.5px 70px;

  @media (min-width: $tablet-width) {
    padding: 4.5px 0 4.5px 70px;
  }

  @media (min-width: $desktop-width) {
    padding: 9px 0 9px 85px;
  }
}

.affiliate__avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 55px;

  @media (min-width: $desktop-width) {
    width: 70px;
    height: 70px;
  }
}

.affiliate__name {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    margin-bottom: 6px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 10px;
  }
}

.affiliate__subscribers {
  font-size: 16px;
  line-height: 22px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 13px;
    line-height: 18px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.affiliate__title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 17px;
  }

  @media (min-width: $desktop-width) {
    font-size: 18px;
  }
}

.affiliate__video-img {
  width: 250px;
  height: 138px;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 186px;
    height: 102px;
  }

  @media (min-width: $desktop-width) {
    width: 296px;
    height: 162px;
  }
}

.affiliates .swiper {
  margin: 0 -15px !important;
  width: calc(100% + 30px);
}

.affiliates .swiper-wrapper {
  width: 320px !important;
}

.affiliates .swiper-slide {
  width: 320px !important;
  height: 393px !important;
  background-position: center;
  background-size: cover;
}

.affiliates__slide {
  padding: 15px;
}

.affiliates__logos {
  margin-top: 10px;

  @media (min-width: $tablet-width) {
    align-self: center;
    width: 738px;
    margin-top: 30px;
  }

  @media (min-width: $desktop-width) {
    align-self: flex-start;
    width: auto;
  }
}
