.renew-keys-form {
  display: flex;
  flex-direction: column;
}

.renew-keys-form__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.renew-keys-form__inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1px;

  @media (min-width: $desktop-width) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.renew-keys-form__field {
  max-width: 451px;
  flex-grow: 1;

  @media (min-width: $tablet-width) {
    max-width: 461px;
  }

  &--triple {
    @media (min-width: $desktop-width) {
      flex-grow: 0;
      max-width: unset;
      width: 372.5px;
    }
  }
}
