.referrals-intro {
  margin-bottom: 35px;

  @media (min-width: $tablet-width) {
    margin-bottom: 25px;
  }
}

.referrals-intro__list {
  @include list-reset;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.referrals-intro__item {
  display: flex;
  align-items: center;

  @media (min-width: $tablet-width) {
    flex-direction: column;
  }
}

.referrals-intro__item:not(:last-child) {
  margin-bottom: 25px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }
}

.referrals-intro__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  margin-right: 28px;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 50%;

  @media (min-width: $tablet-width) {
    width: 68px;
    height: 68px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.referrals-intro__icon {
  display: flex;
  color: #3b82f6;

  &--user {
    width: 26px;
    height: 26px;

    @media (min-width: $tablet-width) {
      width: 29px;
      height: 29px;
    }
  }

  &--chain {
    width: 32px;
    height: 16px;

    @media (min-width: $tablet-width) {
      width: 36px;
      height: 18px;
    }
  }

  &--credit-card {
    width: 27px;
    height: 21px;

    @media (min-width: $tablet-width) {
      width: 30px;
      height: 24px;
    }
  }
}

.referrals-intro__item-text {
  flex-grow: 1;
  width: 168px;
  max-width: 215px;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media (min-width: $tablet-width) {
    width: 140px;
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: $desktop-width) {
    width: 163px;
    line-height: 24px;
  }
}
