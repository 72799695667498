.copy {
  display: inline-block;
}

.copy__btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 3px;

  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: inherit;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #dbeafe;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 15px;
  }
}

.copy__btn--copied::before {
  content: "Copied!";
  position: absolute;
  top: -23px;
  right: -17px;
  padding: 4px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #828282;

  background-color: #dbeafe;
  border-radius: 5px;

  animation-name: disappear;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.copy__text {
  margin-right: 3px;
}

.copy__icon {
  display: flex;
  justify-self: flex-end;
  width: 10px;
  height: 12px;

  color: #3b82f6;
}

.copy:hover .copy__icon,
.copy:active .copy__icon,
.copy:focus .copy__icon {
  color: #2563eb;
}
