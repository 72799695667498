.block-not-started {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: $tablet-width) {
    grid-template-columns: repeat(4, 25%);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 89px 108px 109px 110px 254px;
  }
}

.block-not-started__historical {
  grid-column: 1/2;
  grid-row: 1/3;
  justify-self: center;

  display: flex;
  flex-direction: column;
  width: 115px;
  margin: 0;
  margin-left: 20px;
  padding: 5px 0;
  color: #828282;

  @media (min-width: $tablet-width) {
    grid-row: 1/2;
    width: auto;
    margin-left: 0;
    padding: 0;
  }

  @media (min-width: $desktop-width) {
    justify-self: start;
  }
}

.block-not-started__historical-big {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;

  @media (min-width: $tablet-width) {
    margin-bottom: 3px;
  }
}

.block-not-started__historical-small {
  font-size: 12px;
  line-height: 18px;
}

.block-not-started__total-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
  min-width: 115px;
  margin-left: 20px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.block-not-started__btns-wrapper {
  grid-column: 1/-1;
  justify-self: center;

  margin-top: 30px;
  margin-bottom: 10px;

  @media (min-width: $desktop-width) {
    grid-column: 5/6;
    justify-self: end;
    align-self: center;
    margin: 0 16px 0 0;
  }

  &--with-register {
    @media (min-width: $desktop-width) {
      grid-column: 4/6;
    }
  }
}

.block-not-started__connect-btn-wrapper {
  position: relative;
}

.block-not-started__link {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

//

.block-not-started .details-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 3/4;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 4/5;
    }
  }
}

.block-not-started--with-register .details-board__total {
  &--from-start {
    @media (min-width: $tablet-width) {
      margin-left: 10px;
      grid-column: 2/3;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 3/4;
    }
  }
}
