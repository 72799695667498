.telegram-login {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  transform: scale(2);
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.01;
}
