.overview-board__link {
  @include block-shadow;
  position: relative;

  display: grid;
  grid-template-rows: 62px min-content;

  @media (min-width: $tablet-width) {
    // Для тогда чтобы задать индекс, и пред. перекрывала след. для хинта
    position: relative;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 115px 670px;
    grid-template-rows: min-content;
    padding: 30px 0;
  }

  @include animation-lift-shadow-onhover-add;

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
  }

  &:active {
    box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
  }

  &--disabled {
    cursor: auto;
    &:hover {
      transform: none;
      @include block-shadow;
    }
  }
}

.overview-board__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 115px;
  height: 62px;
  padding-left: 15px;
  box-sizing: border-box;

  @media (min-width: $desktop-width) {
    height: 45px;
  }
}

.overview-board__logo {
  display: block;

  @media (min-width: $desktop-width) {
    height: auto;
  }

  &--binance-spot {
    width: 80px;
  }

  &--binance-futures {
    width: 80px;
  }

  &--okx {
    width: 58px;
  }

  &--kucoin {
    width: 85px;
  }

  &--bing-x {
    width: 75px;
  }

  &--kraken {
    width: 85px;
  }

  &--dydx {
    width: 50px;
  }

  &--phemex {
    width: 80px;
  }

  &--bitget-futures {
    width: 75px;
  }

  &--bybit {
    width: 55px;
  }

  &--deribit {
    width: 85px;
  }
}

.overview-board__block {
  // чтоб текст статуса не перекрывал сообщения хинта кнопки
  z-index: 2;
  position: relative;

  display: grid;
  grid-template-columns: minmax(130px, 1fr) minmax(130px, 1fr);
  grid-template-rows: repeat(3, min-content);
  padding: 0 15px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(120px, 1fr) 5px minmax(90px, 1fr) 5px minmax(110px, 1fr) 5px
      minmax(113px, 1fr);
    grid-template-rows: repeat(2, min-content);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 90px 15px 90px 15px 70px 15px 120px 110px 10px 120px;
    grid-template-rows: min-content;
    padding: 0;
  }
}

.overview-board__total-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 130px;
  padding-left: 26px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.overview-board__total {
  line-height: 0;
  font-size: 0;

  @media (min-width: $tablet-width) {
    justify-self: center;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 90px;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-end;
    width: auto;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      justify-self: flex-start;
      width: 120px;
      padding-left: 26px;
      box-sizing: border-box;
    }

    @media (min-width: $desktop-width) {
      width: auto;
      padding-left: 0;
    }
  }
}

.overview-board__amount {
  display: flex;
  align-items: center;

  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  white-space: nowrap;

  @media (min-width: $tablet-width) {
    font-size: 17px;
  }
}

.overview-board__total--initial .overview-board__amount {
  color: #828282;
}

.overview-board__total--last-month {
  min-width: 95px;
}

.overview-board__total--your-changes .overview-board__amount {
  color: #828282;
}

.overview-board__total--up .overview-board__amount {
  color: #2bbc8b;
}

.overview-board__total--down .overview-board__amount {
  color: #f93232;
}

.board-sign + .overview-board__number {
  margin-left: 2px;
}

.overview-board__text {
  display: block;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  text-wrap: nowrap;
  white-space: nowrap;
}

.overview-board__sign {
  display: none;

  @media (min-width: $tablet-width) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9px;
    height: 24px;
  }

  &--calc {
    @media (min-width: $tablet-width) {
      margin-left: -15px;
      grid-column: 4;
      grid-row: 1/2;
    }

    @media (min-width: $desktop-width) {
      margin: 0;
      grid-column: 4;
      grid-row: 1/2;
    }
  }

  &--your-changes {
    @media (min-width: $tablet-width) {
      margin-left: -15px;
      grid-column: 2;
      grid-row: 1/2;
    }

    @media (min-width: $desktop-width) {
      margin: 0;
      grid-column: 2;
      grid-row: 1/2;
    }
  }

  &--equal {
    @media (min-width: $tablet-width) {
      margin-left: -15px;
      grid-column: 6;
      grid-row: 1/2;
    }

    @media (min-width: $desktop-width) {
      margin: 0;
      grid-column: 7;
      justify-self: flex-start;
      grid-row: 1/2;
    }
  }
}

.overview-board__alert {
  position: absolute;
  top: 0;
  right: 16px;
}

.overview-board__alert-hint {
  position: absolute;
  right: -22px;
  top: -3px;
}

.overview-board__dynamics {
  grid-column: 1/-1;
  grid-row: 2/3;
  margin: 0 -15px;

  @media (min-width: $desktop-width) {
    grid-column: 6/7;
    grid-row: 1/2;

    display: flex;
    flex-direction: column;
    padding-right: 14px;
    margin: 0;
  }
}

.overview-board__btn-wrapper {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);

  @media (min-width: $desktop-width) {
    position: relative;
    left: 0;
    bottom: 0;
    transform: none;

    grid-column: 10;
    height: 44px;
    display: flex;
    align-items: center;
    margin-top: 1px;
  }
}

.overview-board__btn-hint {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translate(0, -50%);
}

.overview-board__trending {
  position: absolute;
  left: 50%;
  bottom: 66px;

  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #555555;

  transform: translate(-50%, 0);

  @media (min-width: $desktop-width) {
    position: relative;
    left: 0;
    bottom: 0;
    transform: none;

    gap: 1px;
    margin-right: -30px;
    line-height: 18px;
    color: #828282;
  }
}

.overview-board__trending-icon {
  display: flex;
  width: 16px;
  height: 10px;
  margin-top: 2px;

  @media (min-width: $desktop-width) {
    width: 14px;
    height: 8px;
  }

  &--up {
    color: #30e733;
  }

  &--down {
    color: #f93332;
  }
}
