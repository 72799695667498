.partnership-form {
  display: flex;
  flex-direction: column;
  max-width: 365px;

  @media (min-width: $tablet-width) {
    max-width: unset;
  }
}

.partnership-form__title {
  margin-top: 0;
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1a1a1a;

  @media (min-width: $desktop-width) {
    margin-bottom: 15px;
  }
}

.partnership-form__field {
  width: 100%;
  margin-bottom: 10px;

  &--comment {
    height: 130px;
    resize: none;
    margin-bottom: 18px;
    padding-top: 10px;
    line-height: 16px;
  }
}

.partnership-form__btn-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.partnership-form__btn {
  flex-shrink: 0;
  margin-right: 10px;
}

.partnership-form__note {
  margin: 0;
  font-size: 12px;
  line-height: 16px;

  @media (min-width: $tablet-width) {
    margin-right: -100px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $desktop-width) {
    margin-right: 0px;
  }
}
