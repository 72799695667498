.page {
  min-height: 100vh;
  background-color: #fafbfc;

  &--landing {
    position: relative;
    background: url("../../img/circles/circle-plain.svg") no-repeat top -70px right -70px,
      url("../../img/circles/circle-plain.svg") no-repeat top 49% left -130px,
      url("../../img/circles/dotted-border.svg") no-repeat top -70px right -70px,
      url("../../img/circles/dotted-border.svg") no-repeat top 29% right -100px,
      url("../../img/circles/dotted-border.svg") no-repeat top 48% left -100px,
      url("../../img/circles/dotted-border.svg") no-repeat top 79% left -100px,
      url("../../img/circles/dotted-border.svg") no-repeat top 93% right -80px;

    @media (min-width: $desktop-width) {
      background: url("../../img/circles/circle-plain-desktop-dark.svg")
          no-repeat top -250px right -270px,
        url("../../img/circles/circle-plain-tablet.svg") no-repeat top 23% left -190px,
        url("../../img/circles/dotted-border-desktop.svg") no-repeat top -250px right -120px,
        url("../../img/circles/dotted-border-desktop.svg") no-repeat top 29%
          right -270px,
        url("../../img/circles/dotted-border-desktop.svg") no-repeat top 20%
          left -210px,
        url("../../img/circles/dotted-border-desktop.svg") no-repeat top 79%
          left -200px,
        url("../../img/circles/dotted-border-desktop.svg") no-repeat top 99%
          right -210px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: transparent;
      border-radius: 50%;
    }

    &::before {
      width: 100px;
      height: 100px;
      top: -200px;
      left: -200px;

      box-shadow: -50px 200px 200px 400px #2564eb28,
        100px 2200px 200px 300px #2564eb28, 100px 4200px 200px 300px #2564eb28,
        100px 6200px 200px 300px #2564eb28;

      @media (min-width: $tablet-width) {
        width: 200px;
        height: 200px;
        top: -400px;
        left: -200px;
      }

      @media (min-width: $desktop-width) {
        width: 400px;
        height: 400px;
        top: -800px;
        left: -200px;

        box-shadow: -50px 500px 400px 400px #2564eb28,
          -200px 2300px 400px 400px #2564eb28,
          -200px 4300px 400px 400px #2564eb28;
      }
    }

    &::after {
      width: 100px;
      height: 100px;
      top: -200px;
      right: 0;

      box-shadow: 200px 1200px 300px 300px #2564eb28,
        200px 3300px 300px 300px #2564eb28, 200px 5300px 300px 300px #2564eb28,
        200px 7300px 300px 300px #2564eb28;

      @media (min-width: $tablet-width) {
        width: 200px;
        height: 200px;
        top: -400px;
        right: 0px;
      }

      @media (min-width: $desktop-width) {
        width: 400px;
        height: 400px;
        top: -800px;
        right: 0px;

        box-shadow: 500px 1500px 400px 400px #2564eb28,
          200px 3300px 400px 400px #2564eb28, 200px 5300px 400px 400px #2564eb28;
      }
    }
  }
}

.page__cointainer {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    position: relative;
    display: grid;
    grid-template-rows: minmax(400px, max-content) 1fr;
    grid-template-columns: 265px 1fr;
  }

  @media (min-width: $desktop-width) {
    grid-template-rows: minmax(700px, max-content) 1fr;
    width: $desktop-width;
    margin: auto;
  }

  &--landing {
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-width) {
      width: auto;
    }
  }
}
