.table-trending {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a1a1a;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--up {
    color: #59ec5c;
  }

  &--down {
    color: #f93232;
  }
}

.table-trending__icon {
  display: flex;
  width: 7px;
  height: 4px;
  margin-right: 2px;
  color: inherit;
}
