.sk-position-discrepancy__row {
  width: 100%;
  height: 40.5px;
  margin-top: -2px;

  display: grid;
  grid-template-columns:
    minmax(78px, 30%) minmax(48px, 1.2fr) minmax(48px, 1.2fr)
    minmax(48px, 1fr);
  align-items: center;
  column-gap: 10px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(131px, 30%) 1fr 1fr
      1fr;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 141px 1fr 1fr 1fr;
    column-gap: 20px;
  }
}

.sk-position-discrepancy__text {
  height: 20px;
  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-position-discrepancy__row:first-of-type .sk-position-discrepancy__text {
  background-color: #dde4ec;
}
