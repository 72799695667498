.faq-item {
  border-bottom: solid 1px #cbd5e1;
}

.faq-item__question {
  position: relative;
  margin: 0;
  padding: 20px 0;
  padding-left: 25px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    padding-left: 35px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $desktop-width) {
    padding: 20px 35px;
  }
}

.faq-item__icon {
  position: absolute;
  top: 19px;
  left: 0;

  display: flex;
  width: 16px;
  height: 16px;
  color: #2563eb;

  @media (min-width: $tablet-width) {
    top: 22px;
  }

  @media (min-width: $desktop-width) {
    top: 22px;
  }
}

.faq-item__answer {
  &--with-chart {
    margin: 0 -15px;
    padding: 0 15px;
  }
}

.faq-item__paragraph {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 16px;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 15px;
  }
}

.faq-item__chart {
  margin-bottom: 10px;

  @media (min-width: $desktop-width) {
    width: 480px;
    margin: 0 auto 15px;
  }
}

.faq-item__container {
  width: 240px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  gap: 20px;

  @media (min-width: $tablet-width) {
    width: 688px;
  }

  @media (min-width: $desktop-width) {
    width: 465px;
  }
}

.faq-item__container-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.faq-item__container-questions-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faq__list-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: $desktop-width) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.faq__list-column {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
