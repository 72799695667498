.action-btn {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.action-btn--copied::before {
  content: "Copied!";
  position: absolute;
  top: -25px;
  left: -5px;
  padding: 4px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #828282;

  background-color: #dbeafe;
  border-radius: 5px;

  animation-name: disappear;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.action-btn__icon {
  display: flex;
  color: #3b82f6;

  &--copy {
    width: 20px;
    height: 22px;

    @media (min-width: $desktop-width) {
      height: 21px;
    }
  }

  &--share {
    width: 17px;
    height: 24px;
  }
}

.action-btn:hover .copy__icon,
.action-btn:active .copy__icon,
.action-btn:focus .copy__icon {
  color: #2563eb;
}
