.sign {
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 9px;
    height: 9px;
    margin-top: 2px;
    margin-right: 1px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--colored-plus:before {
    background-image: url("../../img/sign/plus-green.svg");
    background-size: 9px 9px;
  }

  &--colored-minus:before {
    background-image: url("../../img/sign/minus-red.svg");
    background-size: 9px 1.5px;
  }

  &--plus:before {
    background-image: url("../../img/sign/plus-black.svg");
    background-size: 9px 9px;
  }

  &--minus:before {
    background-image: url("../../img/sign/minus-black.svg");
    background-size: 9px 1.5px;
  }
}
