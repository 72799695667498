.sk-referrals-link {
  display: flex;
  align-items: center;
  height: 40px;
}

.sk-referrals-link__field {
  flex-grow: 1;
  width: 150px;
  max-width: 421px;
  height: 40px;
  margin-right: 6px;

  @media (min-width: $tablet-width) {
    margin-right: 8px;
  }
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $desktop-width) {
    max-width: 409px;
  }
}

.sk-referrals-link__btn {
  width: 25px;
  height: 30px;
  margin-right: -1px;
  border-radius: 5px;
  background-color: #dde4ec;
}
