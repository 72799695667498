.connect-help {
    @include list-reset;
    display: flex;
    flex-direction: column;
  
    @media (min-width: $tablet-width) {
      flex-direction: row;
    }
  }
  
  .connect-help__item {
    display: flex;
    margin-bottom: 6px;
  
    @media (min-width: $tablet-width) {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }
  
  .connect-help__item:last-child {
    margin-bottom: 0;
  }
  
  .connect-help__link {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #3b82f6;
    background-color: transparent;
    border: none;
    cursor: pointer;
  
    @media (min-width: $tablet-width) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  
  .connect-help__icon {
    display: flex;
    margin-right: 5px;
  
    &--calendar {
      width: 12px;
      height: 12px;
    }
  
    &--text {
      width: 10px;
      height: 11px;
      margin: 1px 6px 0 1px;
    }
  
    &--video {
      width: 12px;
      height: 12px;
    }
  }