.short-header-extra {
  display: none;

  @media (min-width: $tablet-width) {
    padding-top: 72px;
    display: block;
  }
}

.short-header-extra__wrapper {
  position: relative;

  @media (min-width: $tablet-width) {
    display: flex;
    min-height: 40px;
  }

  @media (min-width: $desktop-width) {
    min-height: 40px;
  }
}
