.max-btn {
  width: 41px;
  height: 40px;
  padding: 0 7px;

  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;

  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  background-color: #3b82f6;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #2563eb;
  }

  &:active {
    background-color: #346cc7;
  }
}

.field:hover + .max-btn {
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
}

.field:focus + .max-btn,
.field:active + .max-btn {
  box-shadow: 2px 2px 4px rgba(59, 130, 246, 0.2);
}
