.nav-extra-landing {
  @include list-reset;
  display: flex;

  margin-bottom: 30px;
  padding: 91px 15px 0;

  @media (min-width: $tablet-width) {
    position: absolute;
    z-index: 11;
    top: 22px;
    left: 240px;

    margin: 0;
    padding: 0;
  }

  @media (min-width: $desktop-width) {
    left: 282px;
  }
}

// .short-header-main .nav-extra-landing {
//   display: flex;

//   @media (min-width: $tablet-width) {
//     display: none;
//   }

//   @media (min-width: $desktop-width) {
//     display: flex;
//   }
// }

// .short-header-extra .nav-extra-landing {
//   display: none;

//   @media (min-width: $tablet-width) {
//     display: flex;
//   }

//   @media (min-width: $desktop-width) {
//     display: none;
//   }
// }

.nav-extra-landing__item {
  @media (min-width: $desktop-width) {
    margin-right: 30px;
  }
}

.nav-extra-landing__item:not(:last-child) {
  margin-right: 23px;

  @media (min-width: $tablet-width) {
    margin-right: 30px;
  }
}

.nav-extra-landing__link {
  padding: 0;

  font-size: 16px;
  line-height: 29px;
  color: #555555;

  @media (min-width: $tablet-width) {
    display: block;
    font-size: 14px;
  }
}

.nav-extra-landing__link:hover {
  background-color: transparent;
}

.nav-extra-landing__link:focus {
  background-color: transparent;
  outline: none;
  outline-offset: none;
}

.nav-extra-landing__link:active {
  background-color: transparent;
  border-color: none;
}

.nav-extra-landing__link.active {
  background-color: transparent;
}

.nav-extra-landing__link:hover {
  color: #3b82f6;
}

.nav-extra-landing__link:focus {
  color: #2563eb;
}

.nav-extra-landing__link:active {
  color: #1a1a1a;
}

.nav-extra-landing__link.active {
  color: #2563eb;
}
