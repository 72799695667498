.sk-referrals-clients {
    margin-top: -3px;

}

.sk-referrals-clients__row {
  width: 100%;
  height: 40.5px;

  display: grid;
  align-items: center;
  grid-template-columns: minmax(116px, 2fr) minmax(72px, 1fr) minmax(54px, 1fr);
  column-gap: 10px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(159px, 2fr) minmax(67px, 1fr) minmax(78px, 1fr)
      minmax(107px, 1fr);
  }

  @media (min-width: $desktop-width) {
    height: 41px;
    grid-template-columns: 120px 60px 59px 66px;
    column-gap: 20px;
  }
}

.sk-referrals-clients__text {
  height: 20px;
  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-referrals-clients__row:first-child .sk-referrals-clients__text {
  background-color: #dde4ec;
}

.sk-referrals-clients__text:last-child {
  display: none;

  @media (min-width: $tablet-width) {
    display: block;
  }
}
