.nav-extra {
  @include list-reset;
}

.nav-extra__item {
  @media (min-width: $desktop-width) {
    display: block;
    height: 29px;
    margin-right: 30px;
  }
}

.nav-extra__link {
  position: relative;

  display: flex;
  padding: 16px 20px 15px 0;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    align-items: center;
    padding: 11.5px 10px 11.5px 0;
  }

  @media (min-width: $desktop-width) {
    display: block;
    padding: 0;

    font-size: 14px;
    line-height: 29px;
  }
}

.nav-extra__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 29px;
  margin-right: 10px;

  @media (min-width: $tablet-width) {
    width: 60px;
    margin-right: 0;
  }

  @media (min-width: $desktop-width) {
    display: none;
  }
}

.nav-extra__icon {
  display: flex;
  color: #555555;

  @media (min-width: $tablet-width) {
    color: #828282;
  }

  &--pricing {
    width: 17px;
    height: 17px;

    @media (min-width: $tablet-width) {
      width: 15px;
      height: 15px;
    }
  }

  &--help {
    width: 17px;
    height: 17px;

    @media (min-width: $tablet-width) {
      width: 15px;
      height: 15px;
    }
  }

  &--faq {
    width: 16px;
    height: 16px;

    @media (min-width: $tablet-width) {
      width: 14px;
      height: 15px;
    }
  }
}

.nav-extra__link-text {
  font-size: 16px;
  line-height: 29px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 14px;
  }
}

.nav-extra__link:hover {
  background-color: #eff6ff;

  @media (min-width: $desktop-width) {
    background-color: transparent;
  }
}

.nav-extra__link:hover .nav-extra__link-text {
  @media (min-width: $desktop-width) {
    color: #3b82f6;
  }
}

.nav-extra__link:focus {
  background-color: #eff6ff;
  outline: 2px solid #dbeafe;
  outline-offset: -2px;

  @media (min-width: $desktop-width) {
    background-color: transparent;
    outline: none;
    outline-offset: none;
  }
}

.nav-extra__link:focus .nav-extra__link-text {
  @media (min-width: $desktop-width) {
    color: #2563eb;
  }
}

.nav-extra__link:active {
  border-color: #dbeafe;
  background-color: #dbeafe;

  @media (min-width: $desktop-width) {
    background-color: transparent;
    border-color: none;
  }
}

.nav-extra__link:active .nav-extra__link-text {
  @media (min-width: $desktop-width) {
    color: #1a1a1a;
  }
}

// For current nav item
.nav-extra__link.active {
  background-color: #dbeafe;

  @media (min-width: $desktop-width) {
    background-color: transparent;
  }
}

.nav-extra__link.active .nav-extra__link-text {
  @media (min-width: $desktop-width) {
    color: #2563eb;
  }
}
