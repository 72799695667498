.trading-results-demo {
  @media (min-width: $desktop-width) {
  }
}

.demo-insight .trading-results-demo {
  display: flex;
  padding: 5px 16px 5px 16px;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.trading-results-demo__table {
  display: flex;
  flex-direction: row;
}

.trading-results-demo__row {
  display: flex;
  margin: 5px;
  flex-direction: column;
}

.trading-results-demo__name {
  font-weight: 400;
  font-size: 8px;
  line-height: 18px;
  display: flex;
  align-items: flex-start;
  max-height: 20px;
  color: #828282;
  margin: 0;
  gap: 8px;
  @media (min-width: $tablet-width) {
    line-height: 20px;
    font-size: 10px;
  }
  @media (min-width: $desktop-width) {
    line-height: 20px;
    font-size: 12px;
  }
}

.trading-results__value-container {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-direction: row;
}

.trading-results-demo__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  display: flex;
  color: #1a1a1a;
  align-items: flex-start;
  gap: 8px;
  @media (min-width: $tablet-width) {
    font-size: 17px;
  }
}
