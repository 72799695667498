.details {
  @include main-padding-no-heading;

  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    overflow: hidden;
  }

  @media (min-width: $desktop-width) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 370px 95px 290px;
    grid-template-rows: repeat(5, min-content);
    column-gap: 15px;
    overflow: visible;
  }
}

.details__item {
  margin-bottom: 13px;
}

.details__board {
  @media (min-width: $desktop-width) {
    grid-row: 1/2;
    grid-column: 1/-1;
  }
}

.details__renew-keys {
  padding: 13px;
  margin: -13px;

  @media (min-width: $desktop-width) {
    grid-column: 1/-1;
  }
}

.details__steps-to-connect {
  @media (min-width: $desktop-width) {
    grid-column: 1/-1;
  }

  &--animated {
    padding: 13px;
    margin: -13px;
  }
}

.details__conf-integration {
  @media (min-width: $desktop-width) {
    grid-column: 1/-1;
  }
}

.details__discrepancy {
  padding: 13px;
  margin: -13px;

  @media (min-width: $desktop-width) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.details__canvas {
  @media (min-width: $desktop-width) {
    grid-column: 1/3;
  }
}

.details__results {
  @media (min-width: $desktop-width) {
    grid-column: 3/4;
  }
}

.details__schedule {
  @media (min-width: $desktop-width) {
    grid-column: 1/-1;
  }
}

.details__note {
  @media (min-width: $desktop-width) {
    grid-column: 1/-1;
  }
}

.details__wrapper {
  display: contents;

  @media (min-width: $desktop-width) {
    grid-column: 1/-1;

    display: grid;
    grid-template-columns: 370px 95px 290px;
    column-gap: 15px;
  }
}

.details__position {
  @media (min-width: $desktop-width) {
    grid-row: 1/3;
  }
}

.details__discrepancy + .details__position {
  @media (min-width: $desktop-width) {
    grid-row: 2/3;
  }
}

.details__activity {
  @media (min-width: $desktop-width) {
    grid-column: 2/4;
    grid-row: 1/3;
  }
}

.details__change-balance-animation {
  max-height: 0; 
  margin-bottom: -13px;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, margin-bottom 0.4s ease, opacity 0.4s ease;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  &.open {
    margin-bottom: 13px;
    opacity: 1;
  }

  &.closed {
    max-height: 0;
    margin-bottom: -13px;
    opacity: 0;
  }
}
