.overview-coming-soon {
  display: contents;
}

.overview-coming-soon__wrapper {
  min-height: 62px;

  @media (min-width: $tablet-width) {
    min-height: 50px;
  }

  @media (min-width: $desktop-width) {
    display: contents;
  }
}
