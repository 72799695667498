.payments-last__table {
  @include list-reset;
  margin-top: -4px;
}

.payments-last__row {
  display: grid;
  grid-template-columns:
    minmax(60px, 72px) minmax(88px, 2fr)
    minmax(46px, 1fr) minmax(64px, 1fr);
  align-items: center;
  height: 40px;

  @media (min-width: $tablet-width) {
    grid-template-columns: 72px minmax(167px, 2.5fr) minmax(122px, 1fr) minmax(
        80px,
        1fr
      );
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 112px 320px 150px 170px;
  }
}

.payments-last__row:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.payments-last__title {
  margin: 0;
  padding-left: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  &--amount {
    text-align: right;
  }

  &--date {
    padding-left: 0;
  }
}

.payments-last__amount {
  padding-left: 5px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.payments-last__amount-icon {
  display: flex;
  flex-shrink: 0;

  &--plus {
    width: 6px;
    height: 6px;
    margin-top: 1px;
    color: #2bbc8b;
  }

  &--minus {
    width: 5px;
    height: 1.5px;
    margin-top: 1.5px;
    color: #f93232;
  }
}

.payments-last__absolute {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--pending {
    color: #828282;
  }

  &--plus {
    color: #2bbc8b;
  }

  &--minus {
    color: #f93232;
  }
}

.payments-last__method {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 5px;
}

.payments-last__icon {
  display: none;

  @media (min-width: $tablet-width) {
    display: flex;
    width: 19px;
    height: 14px;
    margin: 0 0.5px 0.5px 0;
  }

  &--deposit {
    color: #828282;
  }
}

.payments-last__reason {
  padding-left: 5px;
}

.payments-last__text {
  margin: 0;
  align-self: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--reason {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
