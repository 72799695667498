.faq-screen {
    @include main-padding-no-heading;

    @media (min-width: $tablet-width) {
      overflow: hidden;
    }

    @media (min-width: $desktop-width) {
      overflow: visible;
    }
  }