.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
}

.auth-modal__wrapper {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 101;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 40px 15px 40px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    width: 382px;
    top: 130px;
    padding: 40px 21px 40px;
  }

  @media (min-width: $desktop-width) {
    width: 420px;
    top: 150px;
    padding: 60px 15px 60px;
  }
}

.auth-modal .auth__wrapper {
  justify-content: space-between;
}

.auth-modal .auth__heading {
  margin-right: 0;
}
