.sk-nav-referrals {
  display: flex;
  align-items: center;
  height: 29px;
  padding-left: 22px;
}

.sk-nav-referrals__icon {
  width: 16px;
  height: 16px;
  margin-right: 22px;
  background-color: #dde4ec;
  border-radius: 5px;
}

.sk-nav-referrals__text {
  width: 110px;
  height: 25px;
  background-color: #e1ebf8;
  border-radius: 5px;
}
