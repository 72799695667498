.auth-description {
  margin: 0;

  font-size: 11px;
  line-height: 14px;
  color: #828282;

  @media (min-width: $desktop-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.short-header-main .auth-description {
  @media (min-width: $tablet-width) {
    display: none;
  }
}

.short-header-extra .auth-description {
  @media (min-width: $tablet-width) {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 271px;
  }

  @media (min-width: $desktop-width) {
    width: 295px;
  }
}