.faq-canvas {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 10px;

    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
    border-radius: 5px;
  }

  .faq-canvas__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .faq-canvas__controls-top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 15px;
    margin: 0 -10px;
  }