.sk-overview-summary {
  padding: 0 15px;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.sk-overview-summary__list {
  @include list-reset;
  padding: 15px 0;

  display: grid;
  grid-template-columns: repeat(2, minmax(130px, 1fr));
  row-gap: 10px;

  @media (min-width: $tablet-width) {
    grid-template-columns: minmax(125px, 1fr) minmax(205px, 2fr) minmax(
        113px,
        1fr
      );
    row-gap: 15px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 162px 219px 230px 144px;
  }
}

.sk-overview-summary__list:not(:last-of-type) {
  border-bottom: solid 1px #cbd5e1;
}

.sk-overview-summary__item {
  justify-self: center;
  display: flex;
  flex-direction: column;

  width: 130px;
  padding-left: 26px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    justify-self: flex-start;
    width: 100px;
    row-gap: 3px;
  }
}

.sk-overview-summary__item:nth-of-type(2),
.sk-overview-summary__item:nth-of-type(5),
.sk-overview-summary__item:nth-of-type(8) {
  @media (min-width: $tablet-width) {
    justify-self: center;
    width: 120px;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
    width: auto;
  }
}

.sk-overview-summary__item:nth-of-type(3),
.sk-overview-summary__item:nth-of-type(6),
.sk-overview-summary__item:nth-of-type(9) {
  @media (min-width: $tablet-width) {
    justify-self: center;
    width: 116px;
    margin-left: -26px;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
    width: auto;
    margin-left: 0;
  }
}

.sk-overview-summary__amount {
  display: flex;
  place-items: center;
  width: 104px;
  height: 24px;

  @media (min-width: $tablet-width) {
    width: 90px;
  }
}
.sk-overview-summary__amount-inner {
  width: 80px;
  height: 20px;
  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $tablet-width) {
    width: 60px;
  }
}

.sk-overview-summary__note {
  display: flex;
  place-items: center;
  height: 18px;
  width: 104px;

  @media (min-width: $tablet-width) {
    width: 90px;
  }
}

.sk-overview-summary__note-inner {
  width: 100px;
  height: 14px;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    width: 80px;
  }
}

