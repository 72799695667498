.footer {
  @media (min-width: $tablet-width) {
    position: relative;
    z-index: 101;
    grid-row: 2/3;
    grid-column: 1/3;
  }

  @media (min-width: $desktop-width) {
    width: $desktop-width;
    margin: auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 5px;
      height: 100%;
      background-color: #f8f8f8;
      box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.25);
    }
  }
}

.page--landing footer {
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.2);

  @media (min-width: $desktop-width) {
    width: auto;
    margin: 0;
    background-color: #f8f8f8;

    &::before {
      content: none;
    }

    // Убрать лишний отступ при полосе прокрутки
    margin-left: calc(-100vw + 100%);
    padding-left: calc(100vw - 100%);
  }
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  background-color: #f8f8f8;

  @media (min-width: $tablet-width) {
    // Контакты выравнивались от высоты футора
    position: relative;
    min-height: 230px;
    box-sizing: border-box;
    //
    flex-direction: row;
    padding: 35px 15px 15px;
  }

  @media (min-width: $desktop-width) {
    // Закрыть слева псевдоэлемент от footer с тенью
    position: relative;

    min-height: 240px;
  }
}

.page--landing .footer__wrapper {
  @media (min-width: $desktop-width) {
    width: $desktop-width;
    box-sizing: border-box;
    margin: auto;
  }
}

.footer__nav-contacts {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  row-gap: 20px;
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    justify-content: space-around;
    flex-grow: 1;
    order: 1;
    display: flex;
    margin-bottom: 0;
    padding-top: 23px;
    padding-right: 60px;
  }

  @media (min-width: $desktop-width) {
    width: 514px;
    padding-top: 19px;
  }
}

.footer__list {
  &--1 {
    min-width: 105px;
  }

  &--2 {
    min-width: 140px;
  }

  &--3 {
    min-width: 140px;

    @media (min-width: $desktop-width) {
      margin-left: -30px;
    }
  }
}

.footer__contacts {
  align-self: center;
  width: 110px;

  @media (min-width: $tablet-width) {
    position: absolute;
    // Позиционирование от footer-wrapper
    width: 45px;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &--outer {
    display: none;
  }
}

.footer__info {
  color: #828282;

  @media (min-width: $tablet-width) {
    order: 0;
    flex-grow: 1;

    width: 293px;
    margin-right: 20px;
  }

  @media (min-width: $desktop-width) {
    width: 470px;
    margin-right: 50px;
  }
}

.footer__copyright {
  display: block;
  margin-bottom: 5px;

  font-family: "Gotham Pro", Arial, sans-serif;
  font-size: 15px;
  line-height: 18px;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 5px;
  }
}

.footer__note {
  margin: 0;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: $tablet-width) {
    font-size: 11px;
    line-height: 15px;
  }

  @media (min-width: $desktop-width) {
    font-size: 12px;
    line-height: 18px;
  }
}

.footer__note:not(:last-child) {
  margin-bottom: 10px;

  @media (min-width: $desktop-width) {
    margin-bottom: 5px;
  }
}

// footer__nav-contacts--one-row

.footer__nav-contacts--one-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer__nav-contacts--one-row .footer__list {
  min-width: auto;
}

.footer__nav-contacts--one-row .footer__contacts {
  &--inner {
    display: none;
  }
}

.footer__nav-contacts--one-row + .footer__contacts--outer {
  display: block;
  width: auto;
  margin-bottom: 25px;
}
