.private-window {
  display: block;
  padding: 83px 15px 30px;
  background-color: #fafbfc;

  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  height: 100vh;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 62px;
    right: 0;
    bottom: auto;
    left: auto;
    overflow-y: visible;

    width: 300px;
    height: 220px;
    padding: 16px 8px 16px 16px;

    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
    border-radius: 5px;

    &--notifications {
      height: 400px;
    }
  }
}

.private-window__list {
  @include list-reset;
  display: flex;
  flex-direction: column;
  margin: auto;
  //   max-width у каждого своя

  @media (min-width: $tablet-width) {
    overflow-y: auto;
    // max-height: 163px;
    max-height: 188px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9ea4ab;
      border-radius: 3px;
    }

    &--notifications {
      max-height: 368px;
    }
  }
}
