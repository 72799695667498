.deversify-results {
  width: 100%;
  padding: 12px 16px 0;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 542px;
  }

  @media (min-width: $desktop-width) {
    width: 515px;
  }
}

.deversify-results__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #555555;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $desktop-width) {
    margin-bottom: 10px;
  }
}

.deversify-results__table {
  @include list-reset;
}

.deversify-results__row {
  display: grid;
  grid-template-columns: minmax(72px, 1fr) minmax(49px, 1fr) minmax(55px, 1fr) minmax(
      81px,
      1fr
    );
  align-items: center;
  height: 40px;

  @media (min-width: $tablet-width) {
    grid-template-columns: 140px 100px 118px 152px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 140px 90px 107px 146px;
  }
}

.deversify-results__row:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.deversify-results__title {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.deversify-results__title:not(:first-child) {
  justify-content: flex-end;
  padding-right: 10px;
  text-align: end;
}

.deversify-results__title:last-child {
  padding-right: 21px;
  color: #2563eb;
  background-color: rgba(37, 99, 235, 0.1);
}

.deversify-results__cell {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
}

.deversify-results__cell:first-child {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.deversify-results__cell:not(:first-child) {
  padding-right: 10px;
  justify-content: end;
}

.deversify-results__cell:last-child {
  position: relative;
  padding-right: 25px;
  background-color: rgba(37, 99, 235, 0.1);
}

.deversify-results__icon {
  position: absolute;
  display: flex;
  width: 16px;
  height: 10px;
  top: 50%;
  right: 4px;
  color: #2bbc8b;
  transform: translate(0, -50%);
}
