.sk-heading {
  width: 220px;
  height: 21px;
  margin-top: 7px;
  margin-bottom: 13px;
  background-color: #cbd5e1;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 150px;
    height: 22px;
  }

  @media (min-width: $desktop-width) {
    width: 300px;
  }
}
