.server-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 58px 0 23px;

  background-image: url("../../img/charts-bg/blue-chart.svg");
  background-repeat: no-repeat;
  background-position: right 60px;
  background-size: 767px 285px;

  @media (min-width: $tablet-width) {
    align-items: start;
    padding: 124px 0 24px;
    background-image: url("../../img/charts-bg/blue-chart-medium.svg");
    background-position: right 230px;
    background-size: 814px 396px;
  }
}

.server-error__wrapper {
  padding: 0 10px;

  @media (min-width: $tablet-width) {
    padding: 0 15px;
  }
}

.server-error__heading {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 32px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 13px;
    font-size: 36px;
  }
}

.server-error__message {
  margin-top: 0;
  margin-bottom: 110px;
  font-size: 14px;
  line-height: 18px;
  color: #555555;

  @media (min-width: $tablet-width) {
    margin-bottom: 164px;
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 131px;
  }
}

.server-error__img {
  @media (min-width: $tablet-width) {
    align-self: center;
    width: 472px;
    height: 214px;
  }

  @media (min-width: $desktop-width) {
    width: 550px;
    height: 264px;
  }
}
