.sk-details-strategy {
  @include block-shadow;

  display: flex;
  flex-direction: column;
  min-height: 264px;
  margin-bottom: 13px;

  @media (min-width: $tablet-width) {
    min-height: 179px;
  }

  @media (min-width: $desktop-width) {
    flex-direction: row;
    align-items: center;
    min-height: 105px;
    padding-bottom: 2px;
    box-sizing: border-box;
  }
}

.sk-details-strategy__img {
  width: 110px;
  height: 25px;
  margin: 17px 0 28px 15px;
  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $tablet-width) {
    margin: 17px 0 21px 15px;
  }

  @media (min-width: $desktop-width) {
    width: 80px;
    margin: 0 7.5px;
  }
}

.sk-details-strategy__big-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 9px;

  @media (min-width: $tablet-width) {
    justify-content: space-evenly;
    margin-bottom: 15px;
  }

  @media (min-width: $desktop-width) {
    display: contents;
  }
}

.sk-details-strategy__small-wrapper {
  margin-bottom: 11px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.sk-details-strategy__amount-wrapper {
  margin-bottom: 15px;

  @media (min-width: $desktop-width) {
    margin-right: 12px;
    margin-bottom: 0;
  }

  &--third {
    @media (min-width: $desktop-width) {
      margin-right: 21px;
    }
  }
}

.sk-details-strategy__amount {
  width: 80px;
  height: 20px;
  margin-bottom: 4px;
  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $desktop-width) {
    width: 65px;
    margin-bottom: 6px;
  }
}

.sk-details-strategy__note {
  width: 100px;
  height: 14px;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $desktop-width) {
    width: 85px;
  }
}

.sk-details-strategy__btn {
  width: 120px;
  height: 40px;
  align-self: center;
  margin-bottom: 25px;
  border-radius: 5px;
  background-color: #cbd5e1;

  @media (min-width: $tablet-width) {
    margin-bottom: 21px;
  }

  @media (min-width: $desktop-width) {
    margin: 0 15px 0 auto;
  }
}
