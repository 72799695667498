.sk-relative-block {
  padding-top: 15px;
  margin-top: -15px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    display: flex;
    gap: 30px;

    height: 555px;
    margin: -10px -10px 0;
    padding: 10px 10px 0;

    overflow: hidden;
  }

  @media (min-width: $desktop-width) {
    height: 530px;
    gap: 20px;
  }
}

.sk-relative-block__slider {
  width: 100%;
  height: 472px;
  position: relative;
  padding: 53px 16px 15px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    flex-shrink: 0;
    width: 355px;
    height: 496px;
    margin-top: 5px;
  }

  @media (min-width: $desktop-width) {
    margin-top: 0;
    padding-top: 62px;
    width: 336.56px;
    height: 482px;
  }
}

.sk-relative-block__logo {
  position: absolute;
  top: 12px;
  left: 16px;

  width: 100px;
  height: 30px;

  border-radius: 5px;
  background-color: #dde4ec;
  border-radius: 5px;
}

.sk-relative-block__heading {
  width: 200px;
  height: 20px;
  margin-bottom: 16px;

  background-color: #cbd5e1;
  border-radius: 5px;
}

.sk-relative-block__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sk-relative-block__chart {
  width: calc(100% - 10px);
  height: 160px;
  margin: 11px 5px 29px;
  border-radius: 5px;
  background-color: rgba(252, 252, 252, 0.868);
  border: dashed 1.5px lightgray;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  @media (min-width: $tablet-width) {
    height: 183px;
  }

  @media (min-width: $desktop-width) {
    height: 160px;
  }
}

.sk-relative-block__bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sk-relative-block__toggle {
  align-self: center;
  width: 180px;
  height: 20px;

  background-color: #dde4ec;
  border-radius: 5px;
}

.sk-relative-block__extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sk-relative-block__list {
  display: flex;
  flex-direction: column;
}

.sk-relative-block__item {
  height: 40.3px;
  display: flex;
  align-items: center;
}

.sk-relative-block__row {
  width: 100%;
  height: 20px;

  background-color: #dde4ec;
  border-radius: 5px;
}

.sk-relative-block__btns {
  display: flex;
  gap: 10px;
}

.sk-relative-block__btn {
  width: 120px;
  height: 40px;

  background-color: #cbd5e1;
  border-radius: 5px;
}
