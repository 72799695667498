// только в tablet и desktop
.sk-calc-canvas {
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
}

.sk-calc-canvas__wrapper {
  padding: 8px 10px 15px;
}

.sk-calc-canvas__heading {
  width: 270px;
  height: 28px;
  margin-bottom: 9px;
  background-color: #cbd5e1;
  border-radius: 5px;

  @media (min-width: $desktop-width) {
    width: 350px;
    height: 35px;
    margin-bottom: 12px;
  }
}

.sk-calc-canvas__top {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 15px;
}

.sk-calc-canvas__total {
  display: flex;
  margin: 0;
  justify-content: center;
  column-gap: 30px;

  @media (min-width: $desktop-width) {
    justify-content: space-between;
  }
}

.sk-calc-canvas__amount {
  width: 150px;
  height: 28px;
  background-color: #dde4ec;
  border-radius: 5px;

  @media (min-width: $desktop-width) {
    width: 210px;
    font-size: 25px;
    line-height: 30px;
  }
}

.sk-calc-canvas__toggles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 10px;

  @media (min-width: $desktop-width) {
    justify-content: space-between;
  }
}

.sk-calc-canvas__toggle {
  display: flex;
  flex-direction: column;
}

.sk-calc-canvas__toggle-name {
  width: 100px;
  height: 16px;
  background-color: #dde4ec;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sk-calc-canvas__toggle-bar {
  width: 150px;
  height: 15px;
  background-color: #dde4ec;
  border-radius: 5px;
  margin-bottom: 15px;

  @media (min-width: $desktop-width) {
    width: 210px;
    height: 19px;
  }
}

.sk-calc-canvas__chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sk-calc-canvas__chart {
  width: calc(100% - 10px);
  height: 145px;
  margin: 10px 5px 45px;
  border-radius: 5px;
  background-color: rgba(252, 252, 252, 0.868);
  border: dashed 1.5px lightgray;
}

.sk-calc-canvas__bottom {
  width: 300px;
  height: 25px;
  background-color: #dde4ec;
  border-radius: 5px;
  margin: 0 auto;
}
