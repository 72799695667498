.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal__wrapper {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 101;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  padding: 15px 10px 18px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;
  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    width: 382px;
    top: 130px;
    padding: 15px 21px 18px;
  }

  @media (min-width: $desktop-width) {
    width: 420px;
    top: 150px;
    padding: 15px 15px 18px;
  }
}

.modal__heading {
  display: flex;
  margin-top: 0;
  margin-bottom: 25px;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 0.4px;
  color: #f93232;
}

.modal__heading-icon {
  display: block;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  margin-right: 8px;

  @media (min-width: $tablet-width) {
    width: 17px;
    height: 17px;
    margin-top: 0;
  }
}

.modal__text {
  margin-top: 0;
  margin-bottom: 40px;

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #828282;

  @media (min-width: $tablet-width) {
    margin-bottom: 49px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 65px;
  }
}

.modal__btn {
  width: 80px;
  height: 32px;
  padding: 0 10px;
  box-sizing: border-box;

  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  background-color: #ffffff;
  border: 1.5px solid transparent;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.modal__btn--ok {
  text-transform: uppercase;
  color: #f93232;
  border: #f93232;

  &:hover {
    color: #ffffff;
    background-color: #fa5b5b;
    border-color: #fa5b5b;
  }

  &:focus {
    color: #ffffff;
    background-color: #fa5b5b;
    border-color: #fdadad;
  }

  &:active {
    color: #ffffff;
    background-color: #f52d2d;
    border-color: #f52d2d;
  }
}

.modal__stop-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.modal__btn--cancel {
  margin-bottom: 10px;

  color: #2bbc8b;
  border-color: #2bbc8b;
  background-color: #ffffff;

  &:hover {
    color: #ffffff;
    background-color: #2bbc8b;
  }

  &:focus {
    color: #ffffff;
    background-color: #2bbc8b;
    border-color: #97ffdc;
  }

  &:active {
    color: #ffffff;
    background-color: #22956e;
    border-color: #22956e;
  }
}

.modal__btn--stop,
.modal__btn--delete {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #f93232;
  border: #f93232;

  &::before {
    content: "";
    width: 13px;
    height: 13px;
    margin-right: 5px;

    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../img/buttons/red-stop.svg");
    background-size: 13px 13px;
  }

  &:hover,
  &:focus {
    color: #fa5b5b;
  }

  &:active {
    color: #f52d2d;
  }
}
