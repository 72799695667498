.table-quantity {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 14px;
}

.table-quantity__amount {
  margin: 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.table-quantity__tooltip {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
