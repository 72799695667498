.footer-list {
  @include list-reset;
}

.footer-list__inner-list {
  @include list-reset;
  margin-top: 10px;
  margin-left: 15px;
}

.footer-list__item {
  margin-bottom: 10px;

  font-size: 14px;
  line-height: 18px;
}

.footer-list__item:last-child {
  margin-bottom: 0;
}

.footer-list__link {
  text-wrap: nowrap;
  font-size: 14px;
  line-height: 18px;

  color: #555555;

  &:hover {
    color: #3b82f6;
  }

  &:focus {
    color: #2563eb;
    outline: none;
  }

  &:active {
    color: #1a1a1a;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.footer-list__text {
  display: block;
  font-size: 14px;
  line-height: 18px;

  color: #555555;
}
