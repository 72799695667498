.referrals-clients__table {
  @include list-reset;
  margin-top: -4px;
}

.referrals-clients__row {
  display: grid;
  grid-template-columns: minmax(126px, 2fr) minmax(72px, 1fr) minmax(64px, 1fr);
  align-items: center;
  height: 40px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(169px, 2fr) minmax(77px, 1fr) minmax(78px, 1fr)
      minmax(117px, 1fr);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 135px 75px 64px 91px;
  }
}

.referrals-clients__row:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.referrals-clients__title {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  &--amount {
    text-align: right;
  }

  &--last-month {
    display: none;

    @media (min-width: $tablet-width) {
      display: block;
      text-align: right;
      padding-left: 20px;
    }

    @media (min-width: $desktop-width) {
      padding-left: 10px;
    }
  }
}

.referrals-clients__name {
  display: flex;
  padding-right: 10px;
  gap: 5px;
  overflow: hidden;
  align-items: center;
}

.referrals-clients__icon {
  flex-shrink: 0;

  display: flex;
  width: 15px;
  height: 15px;
  color: #828282;

  margin-top: -1px;
  padding: 1px;

  &--active {
    width: 16px;
    height: 15px;
  }
}

.referrals-clients__text {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.referrals-clients__amount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.referrals-clients__absolute {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.referrals-clients__last-month {
  display: none;

  @media (min-width: $tablet-width) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3px;
    padding-left: 20px;
  }
}

.referrals-clients__amount-icon {
  display: flex;
  flex-shrink: 0;

  &--minus {
    width: 5px;
    height: 1.5px;
    margin-top: 1.5px;
    color: #1a1a1a;
  }
}
