.notifications__list {
  max-width: 400px;
}

.notifications__item {
  display: flex;
  padding: 5px 0 9px;

  @media (min-width: $tablet-width) {
    margin-right: 8px;
  }
}

.notifications__item:not(:last-child) {
  border-bottom: 1px solid #cbd5e1;
}

.notifications__icon-wrapper {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 12px;
  margin-top: 8px;
  margin-right: 15px;
}

.notifications__icon {
  width: 16px;
  height: 12px;

  display: flex;
  color: #555555;

  &--accounts {
    width: 15px;
    height: 11px;
  }

  &--card {
    width: 14px;
    height: 10px;
  }

  &--wallet {
    width: 14px;
    height: 10px;
  }

  &--chain {
    width: 16px;
    height: 12px;
  }
}

.notifications__unread {
  position: absolute;
  top: 0;
  left: 10px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #2563eb;
  border-radius: 50%;
}

.notifications__text-wrapper {
  display: flex;
  flex-direction: column;
}

.notifications__message {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #555555;

  &--unread {
    color: #2563eb;
  }
}

.notifications__note {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 250px;
  margin-top: 0;
  margin-bottom: 0;

  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #9e9daf;
  text-align: center;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.notifications__close {
  position: absolute;
  top: 12px;
  right: 10px;

  width: 40px;
  height: 40px;
  padding: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    display: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    top: 19px;
    left: 5px;

    border-radius: 2px;
    background-color: #555555;
    transition: all ease 0.4s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
