.how-to-earn {
  display: flex;
  flex-direction: column;
}

.how-to-earn__list {
  @include list-reset;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    align-self: stretch;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 209px);
    column-gap: 55px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: repeat(auto-fit, 279px);
    column-gap: 105px;
  }
}

.how-to-earn__item {
  position: relative;
  margin-bottom: 27px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }

  &::before {
    @media (min-width: $tablet-width) {
      content: "";
      position: absolute;

      top: 146px;
      left: 214px;
      width: 8px;
      height: 2px;
      box-shadow: 15px 0 0 0 #e3ebfe, 21px 0 0 0 #e3ebfe, 36px 0 0 0 #e3ebfe;
      background-color: #e3ebfe;
      border-radius: 1px;
    }

    @media (min-width: $desktop-width) {
      top: 91px;
      left: 292px;

      width: 12px;
      box-shadow: 30px 0 0 0 #e3ebfe, 41px 0 0 0 #e3ebfe, 71px 0 0 0 #e3ebfe;
    }
  }
}

.how-to-earn__item:last-child {
  margin-bottom: 0;

  @media (min-width: $tablet-width) {
    &::before {
      content: none;
    }
  }
}

.how-to-earn__icon {
  position: absolute;

  top: 0;
  left: 10px;

  width: 140px;
  height: 140px;

  @media (min-width: $desktop-width) {
    width: 162px;
    height: 162px;
  }
}

.how-to-earn__number {
  position: absolute;
  top: -7px;
  left: 0;

  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    top: -13px;
    font-size: 70px;
    line-height: 95px;
  }

  @media (min-width: $desktop-width) {
    top: -23px;
    left: 10px;
    font-size: 80px;
    line-height: 109px;
  }
}

.how-to-earn__block {
  // чтобы перекрыть диск
  position: relative;

  width: 290px;
  margin-top: 75px;
  margin-bottom: 0;
  padding: 14px 14px 20px 20px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 209px;
    margin-top: 82px;
  }

  @media (min-width: $desktop-width) {
    width: 280px;
    margin-top: 91px;
  }

  &--btn-to-form {
    text-align: start;
    font-family: "Open Sans", Arial, sans-serif;
    color: #555555;

    border: none;
    cursor: pointer;

    @include animation-lift-shadow-onhover-add;

    &:focus {
      box-shadow: none;
      outline: 2px solid #dbeafe;
    }

    &:active {
      box-shadow: 0px 0px 15px rgba(37, 99, 235, 0.3);
    }
  }
}

.how-to-earn__title {
  margin-top: 0;
  margin-bottom: 5px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;

  @media (min-width: $desktop-width) {
    line-height: 20px;
  }
}

.how-to-earn__info {
  margin: 0;

  font-size: 16px;
  line-height: 22px;
  color: #555555;

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.how-to-earn__btn {
  align-self: center;
  margin-top: 30px;
}
