.scrammers-note {
  margin-top: 0;
  margin-bottom: 10px;

  font-size: 11px;
  line-height: 14px;
  color: red;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 5px;
    left: 15px;

    width: 410px;
    font-size: 11px;
    line-height: 14px;
  }

  @media (min-width: $desktop-width) {
    width: 450px;

    font-size: 12px;
    line-height: 16px;
  }
}
