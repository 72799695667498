.position-discrepancy {
  display: flex;
  flex-direction: column;
}

.position-discrepancy__table {
  @include list-reset;
  margin-top: -4px;
}

.position-discrepancy__row {
  display: grid;
  grid-template-columns:
    minmax(81px, 30%) minmax(50px, 1.2fr) minmax(50px, 1.2fr)
    minmax(50px, 1fr);
  align-items: center;
  height: 40px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(134px, 30%) 1fr 1fr
      1fr;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 144px 1fr 1fr 1fr;
  }
}

.position-discrepancy__row:not(:last-child) {
  border-bottom: solid 1px #cbd5e1;
}

.position-discrepancy__row-heading {
  margin: 0;
  padding-right: 14px;

  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #828282;
  text-align: right;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  @media (min-width: $desktop-width) {
    padding-right: 10px;
  }

  &--instrument {
    text-align: left;
  }

  &--missing {
    @media (min-width: $desktop-width) {
      padding-right: 14px;
    }
  }
}

.position-discrepancy__break {
  &--none {
    display: none;
  }
}

// 1 column
.position-discrepancy__instrument {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 6px 5px 6px 25px;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    padding: 10px 5px 10px 30px;

    font-size: 12px;
    line-height: 20px;
  }
}

.position-discrepancy__instrument-logo {
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 20px;
}

.position-discrepancy__instrument-title {
  margin: 0;
  color: #555555;

  @media (min-width: $tablet-width) {
    margin-right: 3px;
  }
}

.position-discrepancy__instrument-ticker {
  margin: 0;
  color: #cbd5e1;
}

// 2,3,4 columns

.position-discrepancy__expected,
.position-discrepancy__detected,
.position-discrepancy__missing {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 14px;

  @media (min-width: $desktop-width) {
    padding-right: 10px;
  }
}

.position-discrepancy__missing {
  @media (min-width: $desktop-width) {
    padding-right: 14px;
  }
}

.position-discrepancy__expected-amount,
.position-discrepancy__detected-amount,
.position-discrepancy__missing-amount {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  text-align: right;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.position-discrepancy__missing-amount {
  font-weight: 800;
  color: #f93232;
}

.position-discrepancy .tooltip {
  position: absolute;
  top: 4px;
  right: 0;

  @media (min-width: $desktop-width) {
    right: -4px;
  }
}

.position-discrepancy__missing .tooltip {
  @media (min-width: $desktop-width) {
    right: 0;
  }
}

.position-discrepancy__btn {
  align-self: center;
  margin-top: 5px;
}
