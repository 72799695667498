.demo-canvas {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: calc(100vw - 30px);
  max-width: 480px;
  padding: 15px 10px;
  box-sizing: border-box;

  background-color: #fff;
  box-shadow: 0 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    align-self: stretch;
    width: -webkit-fill-available;

    justify-content: space-between;
  }

  @media (min-width: $desktop-width) {
    width: 480px;
  }
}
