.hint__question {
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  animation-duration: 0.5s;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &--animated {
    animation-name: increase;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  &--closed {
    animation-name: decrease;
  }
}

.hint__message {
  display: none;

  position: absolute;
  z-index: 100;

  flex-direction: column;
  width: 175px;
  box-sizing: border-box;
  padding: 8px;

  background-color: #dbeafe;
  border-radius: 5px;
}

.hint--opened .hint__message {
  display: flex;
}

.hint__message::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;

  background-image: url("../../img/arrow/hint-arrow-up.svg");
  background-position: center;
  background-size: 17px 7px;
}

// demo
.demo__hint .hint__message {
  width: 175px;
  top: 35px;
  left: -50px;

  @media (min-width: $tablet-width) {
    width: 180px;
    top: -18px;
    right: -190px;
    left: auto;
  }

  @media (min-width: $desktop-width) {
    width: 240px;
    right: -250px;
  }
}

.demo .hint__message::before {
  top: -3px;
  left: 54px;

  @media (min-width: $tablet-width) {
    top: 28px;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// strategy
.overview-board__btn-hint--start .hint__message {
  width: 250px;
  top: -113px;
  left: -145px;

  @media (min-width: $tablet-width) {
    width: 166px;
    top: -55px;
    left: 31px;
  }

  @media (min-width: $desktop-width) {
    width: 340px;
    top: auto;
    left: -300px;
    bottom: -90px;
  }
}

.overview-board__btn-hint--start .hint__message::before {
  bottom: -3px;
  right: 82px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 65px;
    right: auto;
    left: -9px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -4px;
    right: 17px;
    left: auto;
    transform: rotate(0);
  }
}

.overview-board__btn-hint--pay .hint__message {
  width: 200px;
  top: -97px;
  left: -131px;

  @media (min-width: $tablet-width) {
    width: 166px;
    top: -40px;
    left: 31px;
  }

  @media (min-width: $desktop-width) {
    width: 200px;
    top: auto;
    left: -160px;
    bottom: -96px;
  }
}

.overview-board__btn-hint--pay .hint__message::before {
  bottom: -3px;
  right: 46px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 48px;
    right: auto;
    left: -9px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    right: 17px;
    left: auto;
    transform: rotate(0);
  }
}

.overview-board__btn-hint--details .hint__message {
  width: 200px;
  top: -113px;
  left: -132px;

  @media (min-width: $tablet-width) {
    width: 166px;
    top: -45px;
    left: 31px;
  }

  @media (min-width: $desktop-width) {
    width: 270px;
    top: auto;
    left: -230px;
    bottom: -96px;
  }
}

.overview-board__btn-hint--details .hint__message::before {
  bottom: -3px;
  right: 46px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 55px;
    right: auto;
    left: -9px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    right: 17px;
    left: auto;
    transform: rotate(0);
  }
}

.overview-board__btn-hint--subscribe .hint__message {
  width: 200px;
  top: -113px;
  left: -130px;

  @media (min-width: $tablet-width) {
    width: 166px;
    top: -45px;
    left: 31px;
  }

  @media (min-width: $desktop-width) {
    width: 270px;
    top: auto;
    left: -230px;
    bottom: -96px;
  }
}

.overview-board__btn-hint--subscribe .hint__message::before {
  bottom: -3px;
  right: 48px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 55px;
    right: auto;
    left: -9px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    right: 17px;
    left: auto;
    transform: rotate(0);
  }
}

// strategy__hint-alert
// details-board__hint-alert

.details-board__hint-alert .hint__message,
.overview-board__alert-hint .hint__message {
  width: 180px;
  bottom: -95px;
  left: -155px;
}

.details-board__hint-alert .hint__message::before,
.overview-board__alert-hint .hint__message::before {
  top: -3px;
  right: 3px;
}

// details-board
.block-demo__stop-hint .hint__message {
  width: 200px;

  bottom: -95px;
  left: -90px;

  @media (min-width: $tablet-width) {
    bottom: auto;
    top: -95px;
    left: -90px;
  }

  @media (min-width: $desktop-width) {
    top: 33px;
    left: -150px;
    right: auto;
  }
}

.block-demo__stop-hint .hint__message::before {
  top: -3px;
  left: 94px;

  @media (min-width: $tablet-width) {
    top: 84px;
    left: 95px;
    transform: rotate(-180deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    left: 155px;
    transform: none;
  }
}

.block-demo__renew-hint .hint__message {
  width: 160px;
  top: -95px;
  left: -70px;

  @media (min-width: $desktop-width) {
    top: 33px;
    left: auto;
    right: -10px;
  }
}

.block-demo__renew-hint .hint__message::before {
  bottom: -3px;
  left: 74px;
  transform: rotate(-180deg);

  @media (min-width: $desktop-width) {
    top: -3px;
    left: auto;
    right: 13px;
    transform: none;
  }
}

// connect-step

.connect-step .hint__message {
  width: 180px;
  top: -98px;
  left: -90px;

  @media (min-width: $tablet-width) {
    top: -15px;
    left: 33px;
  }
}

.connect-step .hint__message::before {
  top: 85px;
  left: 94px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 24px;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// startegy-results

.hint__message--trading-results {
  width: 200px;
  left: -100px;
  top: 32px;

  @media (min-width: $tablet-width) {
    top: -28px;
    left: 32px;
  }

  @media (min-width: $desktop-width) {
    left: -100px;
    top: 32px;
  }
}

.hint__message--trading-results::before {
  top: -3px;
  right: 79px;

  @media (min-width: $tablet-width) {
    top: 37px;
    left: -8px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    right: 79px;
    left: auto;
    transform: rotate(0);
  }
}

// current-position

.hint__message--current-position {
  width: 200px;
  left: -100px;
  top: 32px;

  @media (min-width: $tablet-width) {
    top: -28px;
    left: 32px;
  }
}

.hint__message--current-position::before {
  top: -3px;
  right: 79px;

  @media (min-width: $tablet-width) {
    top: 37px;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// recent-activity

.hint__message--recent-activity {
  width: 240px;
  left: -100px;
  top: 32px;

  @media (min-width: $tablet-width) {
    top: -28px;
    left: 32px;
  }

  @media (min-width: $desktop-width) {
    left: -100px;
    top: 32px;
  }
}

.hint__message--recent-activity::before {
  top: -3px;
  right: 119px;

  @media (min-width: $tablet-width) {
    top: 37px;
    left: -8px;
    transform: rotate(-90deg);
  }

  @media (min-width: $desktop-width) {
    top: -3px;
    right: 119px;
    left: auto;
    transform: rotate(0);
  }
}

// chart

.hint__message--details-canvas {
  width: 225px;
  left: -230px;
  top: -30px;

  @media (min-width: $tablet-width) {
    top: -94px;
    left: -175px;
  }
}

.hint__message--details-canvas::before {
  top: 39px;
  right: -8px;
  transform: rotate(90deg);

  @media (min-width: $tablet-width) {
    top: auto;
    right: 29px;
    bottom: -3px;
    transform: rotate(180deg);
  }
}

// nav

.nav__referrals-hint .hint__message {
  width: 175px;
  left: 151px;
  top: -95px;

  @media (min-width: $tablet-width) {
    top: -20px;
    right: -182px;
    left: auto;
  }
}

.nav__referrals-hint .hint__message::before {
  bottom: -3px;
  right: 80px;
  transform: rotate(180deg);

  @media (min-width: $tablet-width) {
    right: auto;
    bottom: 36px;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// referrals balance

.hint__message--referrals-balance {
  width: 190px;
  right: -43px;
  top: 32px;
}

.hint__message--referrals-balance::before {
  top: -3px;
  right: 47px;
}

// referrals clients

.hint__message--referrals-clients {
  width: 200px;
  right: -90px;
  top: 32px;

  @media (min-width: $desktop-width) {
    right: -206px;
    top: -25px;
  }
}

.hint__message--referrals-clients::before {
  top: -3px;
  right: 94px;

  @media (min-width: $desktop-width) {
    top: 35px;
    right: auto;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// referrals activity

.hint__message--referrals-activity {
  width: 200px;
  right: -100px;
  top: 32px;

  @media (min-width: $desktop-width) {
    right: -206px;
    top: -40px;
  }
}

.hint__message--referrals-activity::before {
  top: -3px;
  right: 103px;

  @media (min-width: $desktop-width) {
    top: 49px;
    right: auto;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// payments order

.hint__message--payments-order {
  width: 190px;
  right: -35px;
  top: -95px;

  @media (min-width: $tablet-width) {
    right: -197px;
    top: -30px;
  }
}

.hint__message--payments-order::before {
  bottom: -3px;
  right: 38px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 41px;
    right: auto;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// payments last

.hint__message--payments-last {
  width: 160px;
  right: -35px;
  top: -95px;

  @media (min-width: $tablet-width) {
    right: -167px;
    top: -30px;
  }
}

.hint__message--payments-last::before {
  bottom: -3px;
  right: 38px;
  transform: rotate(-180deg);

  @media (min-width: $tablet-width) {
    top: 41px;
    right: auto;
    left: -8px;
    transform: rotate(-90deg);
  }
}

// coin

.coin-board__hint--volume24 .hint__message {
  width: 190px;
  right: -80px;
  bottom: -112px;
}

.coin-board__hint--volume24 .hint__message::before {
  top: -3px;
  right: 84px;
}

.coin-board__hint--circulating-supply .hint__message {
  width: 190px;
  right: -15px;
  bottom: -160px;

  @media (min-width: $tablet-width) {
    right: -60px;
  }
}

.coin-board__hint--circulating-supply .hint__message::before {
  top: -3px;
  right: 18px;

  @media (min-width: $tablet-width) {
    right: 64px;
  }
}

//

.hint__text {
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #828282;
}

.hint__got-it {
  align-self: flex-end;
  width: 80px;
  height: 32px;
  padding: 0;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #828282;

  background-color: transparent;
  border: solid 1px #828282;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #828282;
    border-color: #828282;
  }

  &:focus {
    color: #fff;
    background-color: #828282;
    border-color: #cbd5e1;
    outline: none;
  }

  &:active {
    color: #fff;
    background-color: #555555;
    border-color: #555555;
  }
}
