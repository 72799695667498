.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 40px;
  padding: 10px 5px;
  box-sizing: border-box;

  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-wrap: nowrap;

  background-color: transparent;
  border: solid 1.5px transparent;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &--blocked {
    pointer-events: none;
  }
}

.btn__icon {
  display: flex;
  margin-right: 8px;
  color: inherit;
}

.btn__text {
  color: inherit;
  text-wrap: nowrap;
  white-space: nowrap;
}

// SIZE

.btn--small {
  width: 80px;
  height: 28px;
  padding: 6px 10px;
}

.btn--bigger {
  width: 135px;
}

.btn--big {
  width: 150px;
}

.btn--170 {
  width: 170px;
}

.btn--175 {
  width: 170px;
}

.btn--super {
  width: 180px;
}

.btn--180 {
  width: 180px;
}

.btn--190 {
  width: 190px;
}

.btn--200 {
  width: 200px;
}

.btn--super-big {
  width: 200px;
}

.btn--before-huge {
  width: 220px;
}

.btn--huge {
  width: 240px;
}

// STYLE
.btn--blue-white {
  color: #3b82f6;
  border-color: #3b82f6;
  background-color: transparent;

  &:hover {
    color: #ffffff;
    background-color: #3b82f6;
  }

  &:focus {
    color: #ffffff;
    background-color: #3b82f6;
    border-color: #dbeafe;
  }

  &:active {
    color: #ffffff;
    background-color: #2563eb;
    border-color: #2563eb;
  }
}

.btn--white-blue {
  color: #ffffff;
  border-color: #3b82f6;
  background-color: #3b82f6;

  &:hover {
    color: #ffffff;
    background-color: #2bbc8b;
    border-color: #2bbc8b;
  }

  &:focus {
    color: #ffffff;
    background-color: #2bbc8b;
    border-color: #97ffdc;
  }

  &:active {
    color: #ffffff;
    background-color: #22956e;
    border-color: #22956e;
  }
}

.btn--white-green {
  color: #ffffff;
  border-color: #2bbc8b;
  background-color: #2bbc8b;

  &:hover {
    color: #ffffff;
    background-color: #05d991;
    border-color: #05d991;
  }

  &:focus {
    color: #ffffff;
    background-color: #05d991;
    border-color: #d6fad6;
  }

  &:active {
    color: #ffffff;
    background-color: #03bf80;
    border-color: #03bf80;
  }
}

.btn--blue-transparent {
  color: #3b82f6;
  background-color: #ffffff;

  &:hover {
    color: #60a5fa;
  }

  &:focus {
    color: #2563eb;
  }

  &:active {
    color: #3b82f6;
  }
}

.btn--green-white {
  color: #2bbc8b;
  border-color: #2bbc8b;
  background-color: transparent;

  &:hover {
    color: #ffffff;
    background-color: #2bbc8b;
  }

  &:focus {
    color: #ffffff;
    background-color: #2bbc8b;
    border-color: #97ffdc;
  }

  &:active {
    color: #ffffff;
    background-color: #22956e;
    border-color: #22956e;
  }
}

.btn--green-transparent {
  color: #2bbc8b;
  background-color: transparent;

  &:hover {
    color: #08da97;
  }

  &:focus {
    color: #22956e;
  }

  &:active {
    color: #2bbc8b;
  }
}

.btn--red-white {
  color: #fa5b5b;
  border-color: #fa5b5b;
  background-color: transparent;

  &:hover {
    color: #ffffff;
    background-color: #fa5b5b;
  }

  &:focus {
    color: #ffffff;
    background-color: #fa5b5b;
    border-color: #fdadad;
  }

  &:active {
    color: #ffffff;
    background-color: #f52d2d;
    border-color: #f52d2d;
  }
}

// IMAGES

.btn__icon--arrow-left,
.btn__icon--arrow-right,
.btn__icon--arrow-up,
.btn__icon--key-in-circle,
.btn__icon--plus-in-circle,
.btn__icon--check {
  width: 13px;
  height: 13px;
  margin-top: 0.5px;
}

.btn__icon--arrow-loader {
  width: 13px;
  height: 16px;

  animation-name: rotation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.btn__icon--check-loader {
  width: 13px;
  height: 13px;
  margin-top: 0.5px;

  animation-name: subscribe-success;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

.btn__icon--triangle-down {
  width: 8px;
  height: 4px;
  margin-top: 3px;
}

.btn__icon--triangle-up {
  width: 8px;
  height: 4px;
  margin-top: 1px;
}

.btn__icon--eye {
  width: 13px;
  height: 9px;
  margin-top: 1px;
}

.btn__icon--error {
  width: 13px;
  height: 13px;
  margin-top: 1.5px;
}

.btn__icon--credit-card {
  width: 14px;
  height: 11px;
  margin-top: 1.5px;
}

.btn__icon--chain {
  width: 15px;
  height: 14px;
}
