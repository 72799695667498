.details-note__list {
  @include list-reset;
}

.details-note__item {
  margin-bottom: 10px;

  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 18px;
  }
}

.details-note__item:last-child {
  margin-bottom: 0;
}
