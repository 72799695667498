@mixin outer-heading {
  margin-top: 0;
  margin-bottom: 15px;

  font-size: 18px;
  font-weight: 600;
  line-height: 26px;

  @media (min-width: $tablet-width) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

@mixin doc-heading {
  margin-top: 0;
  margin-bottom: 15px;

  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  @media (min-width: $desktop-width) {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 48px;
  }
}

@mixin inner-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;

  @media (min-width: $tablet-width) {
    line-height: 25px;
  }

  @media (min-width: $desktop-width) {
    font-size: 22px;
    line-height: 30px;
  }
}
