.sk-referrals-heading {
  align-self: center;
  width: 300px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 28px;

  background-color: #cbd5e1;
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 450px;
  }

  @media (min-width: $desktop-width) {
    height: 22px;
    width: 550px;
    margin-top: 13px;
    margin-bottom: 33px;
  }
}
