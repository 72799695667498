.logo {
  position: absolute;
  top: 15px;
  left: 15px;

  font-size: 0;
  line-height: 0;

  @media (min-width: $tablet-width) {
    top: 22px;
    left: 23px;
  }
}

// 2 отдельных класса,
// чтобы при изменении ширины экрана, размеры лого не скакали
.logo__icon {
  display: flex;
  width: 112px;
  height: 30px;
}

.logo__icon-mobile {
  display: flex;
  width: 40px;
  height: 36px;
}
