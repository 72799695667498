.block-started {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: $tablet-width) {
    padding-right: 18px;
    grid-template-columns:
      minmax(77px, 1fr) 9px minmax(77px, 1fr) 9px minmax(77px, 1fr)
      9px minmax(77px, 1fr);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 80px 9px 89px 9px 100px 9px 121px 119px 135px;
  }
}

.block-started__total-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
  min-width: 115px;
  margin-left: 20px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.block-started__total-wrapper:nth-of-type(1) {
  grid-column: 1/2;
  grid-row: 1/2;
}

.block-started__total-wrapper:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}

.block-started__btns-wrapper {
  grid-column: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    justify-content: center;
    column-gap: 60px;
  }

  @media (min-width: $desktop-width) {
    grid-column: 9/9;
    column-gap: 30px;
    justify-content: flex-end;
    align-self: center;
    margin: 0 16px 0 0;
  }

  &--with-renew {
    @media (min-width: $tablet-width) {
      margin-bottom: 10px;
    }

    @media (min-width: $desktop-width) {
      margin-bottom: 0;
    }
  }
}

.block-started__renew-btn-wrapper {
  position: relative;
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    order: 1;
  }
}

.block-started__link {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;

  // @media (min-width: $desktop-width) {
  //   margin-left: -10px;
  // }
}

.block-started__stop-btn {
  @media (min-width: $desktop-width) {
    margin-right: 20px;
  }
}

.block-started__renew-btn-wrapper + .block-started__stop-btn {
  @media (min-width: $desktop-width) {
    margin-right: 0;
  }
}

.block-started .details-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      grid-column: 1/2;
    }
  }

  &--current {
    @media (min-width: $tablet-width) {
      grid-column: 7/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 6/6;
      margin-left: 5px;
    }
  }

  &--your-changes {
    @media (min-width: $tablet-width) {
      grid-column: 3/4;
    }

    @media (min-width: $desktop-width) {
      margin-left: 5px;
    }
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 5/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/6;
      margin-left: 5px;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 7/8;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/7;
      margin-left: 10px;
    }
  }
}

.block-started .details-board__sign {
  &--calc {
    @media (min-width: $tablet-width) {
      grid-column: 4/5;
    }
  }

  &--equal {
    @media (min-width: $tablet-width) {
      grid-column: 6;
      grid-row: 1;
    }
  }
  &--your-changes-calc {
    @media (min-width: $tablet-width) {
      grid-column: 2/3;
      grid-row: 1;
    }
  }
}
