.sk-payments-balance {
  display: flex;
  align-items: center;
  width: 60px;
  height: 35px;

  @media (min-width: $tablet-width) {
    width: 70px;
  }
}

.sk-payments-balance__amount {
  width: 100%;
  height: 25px;

  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    height: 28px;
}
}
