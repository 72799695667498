.short-header-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-height: 66px;

  backdrop-filter: blur(20px);
  background-color: rgba(37, 99, 235, 0.11);
  box-shadow: 0px 1px 1px rgba(37, 99, 235, 0.25);

  @media (min-width: $tablet-width) {
    min-height: auto;
    height: 72px;
  }

  @media (min-width: $desktop-width) {
    padding-left: calc(100vw - 100%);
  }
}

.short-header-main__wrapper {
  position: relative;

  @media (min-width: $desktop-width) {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.short-header-main__nav-btns {
  position: absolute;
  top: 12px;
  right: 10px;

  @media (min-width: $tablet-width) {
    top: 13.5px;
    right: 15px;
  }
}

.short-header-main__toggle {
  position: absolute;
  top: 12px;
  right: 10px;

  @media (min-width: $tablet-width) {
    display: none;
  }
}
