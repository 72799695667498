.relative-extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.relative-extra__list {
  @include list-reset;

  display: grid;
  grid-template-columns: 1fr 80px;
}

.relative-extra__row {
  display: contents;
}

.relative-extra__name,
.relative-extra__value {
  margin: 0;
  padding: 10px 0;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.relative-extra__row:not(:last-of-type) .relative-extra__name,
.relative-extra__row:not(:last-of-type) .relative-extra__value {
  border-bottom: solid 1px #cbd5e1;
}

.relative-extra__name {
  padding-right: 20px;
  color: #828282;

  @media (min-width: $desktop-width) {
    padding-right: 10px;
  }
}

.relative-extra__value {
  text-align: end;
  color: #1a1a1a;
}

.relative-extra__btns {
  display: flex;
  gap: 10px;
}
