@import "../mixins/general-mixins.scss";

.nav-btns {
  display: flex;
  width: 80px;
  height: 40px;

  @media (min-width: $tablet-width) {
    gap: 20px;
    width: 110px;
    height: 45px;
  }
}

.nav-btns__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    width: 45px;
    height: 45px;

    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  }

  @include animation-lift-shadow-onhover-add;

  &:focus {
    box-shadow: none;
    outline: 2px solid #dbeafe;
    outline-offset: -1px;
  }

  &:active {
    background: #dbeafe;
  }

  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.nav-btns__icon {
  color: #555555;

  @media (min-width: $tablet-width) {
    color: #828282;
  }

  &--user {
    width: 22px;
    height: 22px;
    margin-bottom: 2px;

    @media (min-width: $tablet-width) {
      width: 18px;
      height: 18px;
    }
  }

  &--bell {
    width: 22px;
    height: 26px;

    @media (min-width: $tablet-width) {
      width: 16px;
      height: 20px;
    }
  }
}

.nav-btns__notifications {
  position: absolute;
  top: 7px;
  left: 24px;
  display: block;
  min-width: 16px;
  padding: 0 2px;
  height: 16px;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  text-align: center;

  background-color: #2563eb;
  border-radius: 8px;

  @media (min-width: $tablet-width) {
    top: 0;
    left: 35px;

    min-width: 18px;
    height: 18px;

    font-size: 11px;
    line-height: 18px;

    border-radius: 11px;
  }
}
