.partnership-promo {
  position: relative;
  display: flex;
  flex-direction: column;
}

.partnership-promo__heading {
  @media (min-width: $tablet-width) {
    width: 292px;
  }

  @media (min-width: $desktop-width) {
    width: 352px;
  }
}

.partnership-promo__description {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 14px;
  line-height: 19px;
  color: #555555;

  @media (min-width: $tablet-width) {
    width: 350px;
    margin-right: 380px;
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    width: 374px;
  }
}

.partnership-promo__income {
  align-self: center;
  margin: 0 auto;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: 0;
    left: 52%;
    margin: 0;
  }

  @media (min-width: $desktop-width) {
    left: auto;
    right: 0;
  }
}

.partnership-promo__btn {
  align-self: center;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    align-self: flex-start;
  }
}
