.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  margin: 0;
  padding: 2px 5px;

  font-size: 10px;
  line-height: 13px;
  color: #555555;
  background-color: #eff6ff;
  border-radius: 0px 0px 5px 5px;

  @media (min-width: $tablet-width) {
    min-width: 120px;
    right: 15px;
    padding: 3.5px 5px;
    box-sizing: border-box;

    font-size: 12px;
    line-height: 14px;
  }

  &--alert {
    color: #f93232;
    background-color: #feebea;
  }
}

.alert__icon {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  color: #555;

  @media (min-width: $tablet-width) {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }
}

.alert--alert .alert__icon {
  color: #f93232;
}
