.deversify-block {
  display: flex;
  flex-direction: column;
}

.deversify-block__info {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 14px;
  line-height: 19px;
  color: #555555;

  @media (min-width: $desktop-width) {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 515px;
    margin-bottom: 10px;
  }
}

.deversify-block__wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    align-items: center;
  }

  @media (min-width: $desktop-width) {
    flex-direction: row;
    gap: 20px;
  }
}

.deversify-block__btn {
  align-self: center;
}

.deversify-block__chart {
  align-self: center;
  width: 100%;
  max-width: 420px;

  @media (min-width: $tablet-width) {
    width: 650px;
    max-width: unset;
  }

  @media (min-width: $desktop-width) {
    align-self: auto;
    width: 516px;
  }
}

.deversify-block__results {
  align-self: center;
  max-width: 542px;

  @media (min-width: $tablet-width) {
    align-self: auto;
    max-width: unset;
  }

  @media (min-width: $desktop-width) {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
