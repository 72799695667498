.details-btns-with-register {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}

.details-btns-with-register__wrapper {
  position: relative;
}

.details-btns-with-register__link {
  position: absolute;
  top: 45px;
  left: 50%;

  display: flex;
  align-items: center;
  padding: 0;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: #3b82f6;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.details-btns-with-register__icon {
  display: flex;
  margin-left: 1px;
  margin-right: 6px;

  &--chain {
    width: 13px;
    height: 10px;
  }

  &--up {
    width: 13px;
    height: 10px;
    margin-bottom: 1px;
  }
}
