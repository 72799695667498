.compare-blocks__wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    gap: 13px;
  }

  @media (min-width: $desktop-width) {
    gap: 20px;
  }
}

.compare-blocks__item {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    width: calc((100% - 13px) / 2);
  }

  @media (min-width: $desktop-width) {
    width: auto;
  }
}

.compare-blocks__info {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 14px;
  line-height: 19px;
  color: #555555;

  @media (min-width: $tablet-width) {
    min-height: 95px;
    padding-right: 25px;
  }

  @media (min-width: $desktop-width) {
    min-height: 76px;
    margin-bottom: 30px;
    padding-right: 30px;
  }
}

.compare-blocks__chart {
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: $desktop-width) {
    width: 516px;
  }
}

.compare-blocks__btn {
  align-self: center;

  @media (min-width: $tablet-width) {
    justify-self: center;
  }
}
