.sk-coin-board {
  margin-bottom: 13px;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $desktop-width) {
    display: grid;
    grid-template-rows: auto 1fr;
    column-gap: 15px;
    row-gap: 10px;

    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}

.coin-skeleton-container {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $desktop-width) {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 185px;
  }
}

.sk-coin-board__block-price {
  display: none;

  @media (min-width: $desktop-width) {
    display: block;
    padding: 8px 20px;

    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
    border-radius: 5px;
  }
}

.sk-coin-board__block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 20px;
  padding: 16px 0 10px 15px;

  @media (min-width: $desktop-width) {
    grid-template-columns: 1fr;
    justify-items: start;
    padding: 14px 20px 8px 20px;

    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
    border-radius: 5px;
  }
}

.sk-coin-board__item {
  display: flex;
  flex-direction: column;

  width: 130px;

  @media (min-width: $desktop-width) {
    width: auto;
  }

  &--price {
    @media (min-width: $desktop-width) {
      display: none;
    }
  }
}

.sk-coin-board__item:nth-child(2n) {
  width: 145px;
}

.sk-coin-board__title {
  width: 90px;
  height: 12px;
  margin-bottom: 8px;

  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-coin-board__amount {
  width: 80px;
  height: 17px;
  margin-bottom: 8px;

  border-radius: 5px;
  background-color: #dde4ec;
}

.sk-coin-board__percent {
  width: 90px;
  height: 12px;
  margin-bottom: 4px;

  border-radius: 5px;
  background-color: #e1ebf8;
}
