.calc-sliders {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 10px;

  @media (min-width: $desktop-width) {
    justify-content: space-between;
  }
}

.calc-sliders__item {
  display: flex;
  flex-direction: column;
}

.calc-sliders__label {
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;

  @media (min-width: $tablet-width) {
    font-size: 11px;
  }
}

// range
.calc-sliders__slider {
  width: 150px;
  height: 30px;

  @media (min-width: $desktop-width) {
    width: 210px;
    height: 34px;
  }
}

.calc-sliders__thumb {
  position: absolute;
  top: -4px;

  width: 20px;
  height: 20px;

  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  outline: none;
  cursor: pointer;

  @media (min-width: $desktop-width) {
    top: -5px;
    width: 25px;
    height: 25px;
  }
}

.calc-sliders__mark {
  position: absolute;
  top: 50%;
  left: 50%;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  transform: translate(-50%, -50%);

  @media (min-width: $desktop-width) {
    font-size: 14px;
  }
}

.calc-sliders__value {
  position: absolute;
  top: 20px;
  left: 50%;

  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;
  text-align: center;
  white-space: nowrap;

  transform: translate(-50%, 0);

  @media (min-width: $desktop-width) {
    font-size: 12px;
    top: 28px;
  }
}

// .calc-sliders__thumb.active {
//   background-color: #3b82f6;
// }

.calc-sliders__track {
  height: 12px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: inset 0 0 3px 0.2px rgba(179, 178, 178, 0.74);

  @media (min-width: $desktop-width) {
    height: 14px;
    border-radius: 7px;
  }
}

.calc-sliders__track-0 {
  background: #3b83f6f3;
  box-shadow: none;
}
