.okx-briefly__heading-inner {
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 10px;

  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 18px;
    line-height: 28px;
  }
}

.okx-briefly__wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    display: grid;
    grid-template-columns: minmax(354px, 1fr) minmax(354px, 1fr);
    grid-template-rows: minmax(230px, auto) minmax(280px, auto);
    gap: 30px;
  }
}

.okx-briefly__intro1 {
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }
}

.okx-briefly__paragraph {
  margin-top: 0;
  margin-bottom: 10px;

  font-size: 14px;
  line-height: 19px;
  color: #1a1a1a;
}

.okx-briefly__paragraph:last-of-type {
  margin-bottom: 0;
}

.okx-briefly__intro1__media {
  align-self: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;

  @media (min-width: $tablet-width) {
    justify-self: center;
    max-width: unset;
    width: 354px;
    height: 206px;
    margin-bottom: 0;
    margin-top: -30px;
  }

  @media (min-width: $desktop-width) {
    width: 431px;
    height: 251px;
  }
}

.okx-briefly__img {
  width: 100%;
  height: auto;
}

.okx-briefly__intro2 {
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    align-self: center;
    order: 3;
    margin-bottom: 0;
  }
}

.okx-briefly__intro2__media {
  align-self: center;
  position: relative;
  width: 290px;
  height: 263px;

  @media (min-width: $tablet-width) {
    justify-self: center;
    width: 354px;
  }

  @media (min-width: $desktop-width) {
    width: 451px;
    height: 261px;
  }
}

.okx-briefly__note {
  position: absolute;

  display: block;
  padding: 8px 12px 10px;
  box-sizing: border-box;

  font-size: 13px;
  line-height: 18px;
  color: #1a1a1a;
  text-align: center;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 4px;

  @media (min-width: $tablet-width) {
    padding: 8px 15px 10px;
  }

  @media (min-width: $desktop-width) {
    padding: 11px 10px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &--tools {
    left: 0;
    top: 24px;

    @media (min-width: $tablet-width) {
      width: 143px;
    }

    @media (min-width: $desktop-width) {
      top: 14px;
      width: 172px;
    }
  }

  &--speed {
    top: 51px;
    right: 0;

    @media (min-width: $tablet-width) {
      top: 41px;
      width: 148px;
      line-height: 16px;
    }

    @media (min-width: $desktop-width) {
      top: 33px;

      width: 172px;
    }
  }

  &--interface {
    bottom: 40px;
    left: 52px;

    @media (min-width: $tablet-width) {
      bottom: 48px;
      width: 166px;
    }

    @media (min-width: $desktop-width) {
      bottom: 30px;
      left: 65px;
      width: 183px;
    }
  }
}

.okx-briefly__disk {
  position: absolute;
  left: 50%;
  top: 50%;

  display: flex;
  width: 263px;
  height: 263px;

  transform: translate(-50%, -50%);

  @media (min-width: $desktop-width) {
    width: 261px;
    height: 261px;
  }
}

.okx-briefly__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 152px;
  height: 47px;
  transform: translate(-50%, -50%);

  @media (min-width: $desktop-width) {
    width: 193px;
    height: 59px;
  }

  &--bybit {
    margin-top: -5px;
  }
}
