.bitget-countdown {
  display: flex;
  flex-direction: column;
}

.bitget-countdown__list {
  @include list-reset;
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 30px);
  max-width: 500px;
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    width: 679px;
    max-width: unset;
    margin-bottom: 30px;
  }

  @media (min-width: $desktop-width) {
    width: 757px;
  }
}

.bitget-countdown__item {
  position: relative;
  width: 88px;
  height: 75px;

  @media (min-width: $tablet-width) {
    width: 143px;
    height: 140px;
  }

  @media (min-width: $desktop-width) {
    width: 159px;
    height: 159px;
  }
}

.bitget-countdown__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;

  @media (min-width: $tablet-width) {
    width: 140px;
    height: 140px;
  }

  @media (min-width: $desktop-width) {
    width: 159px;
    height: 159px;
  }
}

.bitget-countdown__number {
  position: absolute;
  top: -9px;
  left: 0;

  font-weight: 700;
  font-size: 37px;
  line-height: 50px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    top: -13px;
    font-size: 70px;
    line-height: 95px;
  }

  @media (min-width: $desktop-width) {
    top: -21px;
    left: 10px;
    font-size: 80px;
    line-height: 109px;
  }
}

.bitget-countdown__block {
  // чтобы перекрыть диск
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 88px;
  min-height: 35px;
  margin-top: 40px;
  padding: 11px 5px;
  box-sizing: border-box;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    width: 143px;
    min-height: 38px;
    margin-top: 81px;
    padding: 8px;
  }

  @media (min-width: $desktop-width) {
    width: 159px;
    min-height: 40px;
    margin-top: 89px;
    padding: 8px 15px;
  }
}

.bitget-countdown__info {
  margin: 0;

  font-size: 12px;
  line-height: 17px;
  color: #555555;
  text-align: center;

  @media (min-width: $tablet-width) {
    font-size: 13px;
    line-height: 19px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.bitget-countdown__btns {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}

.bitget-countdown__divide {
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    font-size: 50px;
    line-height: 95px;
  }

  @media (min-width: $desktop-width) {
    font-size: 60px;
    line-height: 109px;
  }
}
