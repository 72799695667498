.payments-ad {
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (min-width: $tablet-width) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    padding: 16px 16px 22px;
  }

  @media (min-width: $desktop-width) {
    min-height: 341.5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    box-sizing: border-box;
    padding: 16px;
  }
}

.payments-ad__block {
  @media (min-width: $tablet-width) {
    min-width: 200px;
    max-width: 300px;
    justify-self: center;
  }

  @media (min-width: $desktop-width) {
    min-width: auto;
    max-width: auto;
  }
}

.payments-ad__block:first-of-type {
  margin-bottom: 22px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 40px;
  }
}

.payments-ad__heading {
  display: flex;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $tablet-width) {
    margin-bottom: 8px;
  }

  @media (min-width: $desktop-width) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.payments-ad__topic {
  display: flex;
  margin-right: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    margin-right: 5px;
  }

  @media (min-width: $desktop-width) {
    margin-right: 0;
    margin-bottom: 5px;
  }

  &--fee {
    min-height: 29px;

    @media (min-width: $desktop-width) {
      min-height: 28px;
    }
  }
}

.payments-ad__amount {
  display: block;
  font-size: 18px;
  line-height: 28px;

  font-style: normal;

  @media (min-width: $tablet-width) {
    font-size: 22px;
  }

  &--fee {
    min-width: 38px;

    @media (min-width: $tablet-width) {
      min-width: 45px;
    }
  }
}

.payments-ad__note {
  margin-top: 1px;
  font-size: 14px;
  line-height: 28px;
  color: #555555;

  @media (min-width: $tablet-width) {
    margin-top: 2px;
  }

  @media (min-width: $desktop-width) {
    margin-top: 0;
    font-size: 16px;
  }
}

.payments-ad__info {
  margin: 0;
  font-size: 11px;
  line-height: 15px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}
