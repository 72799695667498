.chart-tooltip {
  padding: 10px;

  background-color: #2563eb;
  border-radius: 10px;
}

.chart-tooltip p {
  margin: 0;

  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: right;
  color: #ffffff;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}
