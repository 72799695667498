.disclaimers {
  @include doc-padding;
}

.disclaimers__heading {
  @include doc-heading;
}

.disclaimers__list {
  @include list-reset;
}

.disclaimers__item {
  padding-bottom: 10px;
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    margin-bottom: 13px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 15px;
  }
}

.disclaimers__item:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.disclaimers__question {
  margin-top: 0;
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 32px;
  }
}

.disclaimers__paragraph {
  @include doc-paragraph-font;

  margin-top: 0;
  margin-bottom: 10px;
  text-align: justify;
}

