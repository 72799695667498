.faq {
  @include inner-wrapper;

  &--landing {
    box-shadow: none;
    background-color: transparent;
  }
}

.faq__heading {
  @include inner-heading;

  align-self: center;
  margin: 0 0 20px;

  @media (min-width: $tablet-width) {
    margin-bottom: 25px;
  }
}

.faq__list {
  @include list-reset;
}

.faq__item:not(:last-child) {
  padding-bottom: 20px;

  @media (min-width: $tablet-width) {
    padding-bottom: 25px;
  }
}

.faq__inner-heading {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #555555;

  @media (min-width: $tablet-width) {
    line-height: 17px;
  }

  @media (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 26px;
  }
}

.faq__inner-list {
  @include list-reset;

  display: flex;
  flex-direction: column;
}
