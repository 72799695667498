.details-canvas {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.details-canvas__controls {
  position: relative;

  align-self: center;
  display: flex;
  justify-content: center;
}
