.connect-point {
  position: absolute;
  left: 16px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  transform: translate(0, -50%);

  @media (min-width: $tablet-width) {
    width: 25px;
    height: 25px;
  }

  &--active {
    background-color: #2bbc8b;
    // box-shadow: 0px 1px 10px rgba(43, 188, 139, 0.5);
  }
}

.connect-point__icon {
  display: flex;
  color: #fff;

  &--rhomb {
    width: 12px;
    height: 12px;

    @media (min-width: $tablet-width) {
      width: 15px;
      height: 15px;
    }
  }

  &--key {
    width: 10.5px;
    height: 10.5px;

    @media (min-width: $tablet-width) {
      width: 13px;
      height: 13px;
    }
  }

  &--dollar {
    width: 7px;
    height: 12px;
    margin-left: 1px;

    @media (min-width: $tablet-width) {
      width: 9px;
      height: 15px;
    }
  }

  &--bybit {
    width: 20px;
    height: 20px;

    @media (min-width: $tablet-width) {
      width: 20px;
      height: 20px;
    }
  }

  &--okx {
    width: 10px;
    height: 10px;

    @media (min-width: $tablet-width) {
      width: 12px;
      height: 12px;
    }
  }

  &--bitget {
    width: 11px;
    height: 11px;

    @media (min-width: $tablet-width) {
      width: 13px;
      height: 14px;
    }
  }
}
