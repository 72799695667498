.block-demo {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(111px, 1fr) 9px minmax(111px, 1fr) 9px minmax(111px, 1fr)
      9px minmax(112px, 1fr);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 80px 9px 89px 9px 100px 9px 121px 119px 135px;
  }
}

.block-demo__total-wrapper {
  justify-self: center;
  min-width: 115px;
  margin-left: 20px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.block-demo__total-wrapper:nth-of-type(1) {
  grid-column: 1/2;
  grid-row: 1/2;
}

.block-demo__total-wrapper:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}

.block-demo__btns-wrapper {
  grid-column: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 10px;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    justify-content: center;
    column-gap: 60px;
    margin-bottom: 10px;
  }

  @media (min-width: $desktop-width) {
    grid-column: 9/9;
    column-gap: 30px;
    justify-content: flex-end;
    align-self: center;
    margin: 0 16px 0 0;
  }
}

.block-demo__renew-btn-wrapper,
.block-demo__stop-btn-wrapper {
  position: relative;
}

.block-demo__renew-btn-wrapper {
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    order: 1;
  }
}

.block-demo__renew-hint {
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: 8px;
  margin-left: 65px;
  transform: translate(-50%, 0);

  @media (min-width: $desktop-width) {
    right: -16px;
    left: auto;
    transform: none;
  }
}

.block-demo__stop-hint {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 2px;
  margin-left: 45px;
  transform: translate(-50%, 0);

  @media (min-width: $desktop-width) {
    bottom: 2px;
    right: -16px;
    left: auto;
    transform: none;
  }
}

.block-demo__link {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;

  // @media (min-width: $desktop-width) {
  //   margin-left: -10px;
  // }
}

//

.block-demo .details-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      grid-column: 1/2;
    }
  }

  &--current {
    @media (min-width: $tablet-width) {
      grid-column: 7/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 6/6;
      margin-left: 5px;
    }
  }

  &--your-changes {
    @media (min-width: $tablet-width) {
      grid-column: 3/4;
    }

    @media (min-width: $desktop-width) {
      margin-left: 5px;
    }
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 5/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/6;
      margin-left: 5px;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 7/8;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/7;
      margin-left: 10px;
    }
  }
}

.block-demo .details-board__sign {
  &--calc {
    @media (min-width: $tablet-width) {
      grid-column: 4/5;
    }
  }

  &--equal {
    @media (min-width: $tablet-width) {
      grid-column: 6;
      grid-row: 1;
    }
  }
  &--your-changes-calc {
    @media (min-width: $tablet-width) {
      grid-column: 2/3;
      grid-row: 1;
    }
  }
}
