.end {
  @media (min-width: $tablet-width) {
    position: relative;
    min-height: 320px;
  }

  @media (min-width: $desktop-width) {
    min-height: 360px;
    padding-top: 41px;
  }
}

.end__intro {
  margin-top: 0;
  margin-bottom: 42px;
  font-size: 14px;
  line-height: 19px;

  @media (min-width: $tablet-width) {
    width: 243px;
    margin-bottom: 0;
  }

  @media (min-width: $desktop-width) {
    width: 406px;
  }
}

.end__img-wrapper {
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $tablet-width) {
    position: absolute;
    top: -20px;
    left: 52%;

    margin: 0;
  }

  @media (min-width: $desktop-width) {
    top: 0;
    left: 52%;
  }
}

.end__img {
  display: block;
  width: 290px;
  height: 290px;

  @media (min-width: $tablet-width) {
    width: 350px;
    height: 350px;
  }

  @media (min-width: $desktop-width) {
    width: 400px;
    height: 400px;
  }
}

.end__btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media (min-width: $tablet-width) {
    width: 369px;
    margin-top: 30px;

  }

  @media (min-width: $desktop-width) {
    width: 406px;
  }
}
