.coin-period-toggles {
  display: flex;
  justify-content: center;
}

.coin-period-toggles__item {
  width: 40px;
  height: 20px;
  margin: 0;

  @media (min-width: $tablet-width) {
    height: 25px;
  }
}

.coin-period-toggles__item:first-child {
  margin-right: 23px;
}

.coin-period-toggles__label {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 20px;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #2563eb;
  border: 1px solid #2563eb;
  box-sizing: border-box;

  border-radius: 7px;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    height: 25px;
    font-size: 12px;
  }
}

.coin-period-toggles__input:checked + .coin-period-toggles__label,
.coin-period-toggles__input:focus + .coin-period-toggles__label {
  color: #ffffff;
  background-color: #3b82f6;
  border: none;
}

.coin-period-toggles__input:hover + .coin-period-toggles__label {
  color: #ffffff;
  background-color: #2563eb;
  border: none;
}

.coin-period-toggles__input:active + .coin-period-toggles__label {
  background-color: #2563eb;
  border: none;
}
