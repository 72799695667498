.message {
  position: relative;
  min-width: 15px;
  min-height: 15px;
  margin: 0;
  padding-left: 17px;

  font-size: 11px;
  line-height: 15px;
  color: #828282;

  @media (min-width: $desktop-width) {
    min-width: 16px;
    min-height: 16px;

    font-size: 12px;
    line-height: 16px;
  }

  &--success {
    font-weight: 100;
    color: #2bbc8b;
    // color: #30e733;
  }

  &--error {
    color: #f93232;
  }

  &--neutral {
    padding-left: 0;
    color: #828282;
  }
}

.message__icon {
  position: absolute;
  top: 1.5px;
  left: 0;

  display: flex;
  width: 13px;
  height: 13px;
  margin-right: 5px;

  color: inherit;
  opacity: 0.8;
}

// .message--success .message__icon {
//   @media (min-width: $desktop-width) {
//     top: 1.5px;
//   }
// }

.message--error .message__icon {
  @media (min-width: $desktop-width) {
    top: 2px;
  }
}

.message__note {
  margin-left: 3px;
  margin-right: 3px;
  color: #828282;

  &--error {
    color: #f93232;
  }

  &--error1 {
    // Пройтись по всем изменить позже
    color: #f93232;
    margin: 0;
  }
}

.message__link {
  padding: 0;

  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: $desktop-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.message__micro {
  font-style: normal;
}