.sk-strategy {
  display: grid;
  grid-template-rows: 62px min-content;
  position: relative;

  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $desktop-width) {
    grid-template-columns: 115px 670px;
    grid-template-rows: min-content;

    padding: 30px 0;
  }
}

.sk-strategy__img-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 114px;
  height: 62px;
  padding-left: 15px;

  @media (min-width: $tablet-width) {
    height: 50px;
  }

  @media (min-width: $desktop-width) {
    height: 45px;
  }
}

.sk-strategy__img {
  width: 110px;
  height: 25px;
  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $desktop-width) {
    width: 80px;
  }
}

.sk-strategy__big-wrapper {
  display: grid;
  grid-template-columns: minmax(130px, 1fr) minmax(130px, 1fr);
  grid-template-rows: repeat(3, min-content);
  padding: 0 15px;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(125px, 1fr) minmax(95px, 1fr) minmax(110px, 1fr)
      minmax(113px, 1fr);
    grid-template-rows: repeat(2, min-content);
  }

  @media (min-width: $desktop-width) {
    column-gap: 15px;
    grid-template-columns: 85px 85px 85px 120px 85px 120px;
    grid-template-rows: min-content;
    padding: 0;
  }
}

.sk-strategy__small-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.sk-strategy__amount-wrapper {
  width: 130px;
  padding-left: 26px;
  box-sizing: border-box;

  @media (min-width: $tablet-width) {
    justify-self: center;
    display: flex;
    flex-direction: column;

    row-gap: 3px;
    width: 90px;
    padding-left: 0;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      justify-self: flex-start;
      width: 130px;
      padding-left: 26px;
    }

    @media (min-width: $desktop-width) {
      justify-self: flex-start;
      width: 100px;
      padding-left: 0;
    }
  }
}

.sk-strategy__amount {
  display: flex;
  place-items: center;
  width: 104px;
  height: 24px;

  @media (min-width: $tablet-width) {
    width: 90px;
  }
}

.sk-strategy__amount-inner {
  width: 80px;
  height: 20px;
  border-radius: 5px;
  background-color: #dde4ec;

  @media (min-width: $tablet-width) {
    width: 60px;
  }
}

.sk-strategy__note {
  display: flex;
  place-items: center;
  height: 18px;
  width: 104px;

  @media (min-width: $tablet-width) {
    width: 90px;
  }
}

.sk-strategy__note-inner {
  width: 100px;
  height: 14px;
  border-radius: 5px;
  background-color: #e1ebf8;

  @media (min-width: $tablet-width) {
    width: 80px;
  }
}

.sk-strategy__btn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 120px;
  height: 40px;
  transform: translate(-50%, 0);

  border-radius: 5px;
  background-color: #cbd5e1;

  @media (min-width: $desktop-width) {
    position: static;
    transform: none;
  }
}

.sk-strategy__chart {
  display: flex;
  width: 100%;
  height: 136px;

  @media (min-width: $desktop-width) {
    display: none;
  }
}

.sk-strategy__trending {
  display: none;

  @media (min-width: $desktop-width) {
    display: flex;
    place-items: center;
    width: 125px;
    height: 18px;
    margin-top: auto;
  }
}

.sk-strategy__trending-inner {
  width: 125px;
  height: 14px;

  border-radius: 5px;
  background-color: #e1ebf8;
}

.sk-strategy__hide-for-mobile {
  display: none;

  @media (min-width: $desktop-width) {
    display: block;
  }
}
