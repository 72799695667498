.change-balance-form {
  position: relative;
  padding-bottom: 16px;
}

.change-balance-form__intro {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.change-balance-form__fields-wrapper {
  display: flex;
  flex-direction: row;
  gap: 13px;
  padding-top: 4px;
  margin-bottom: 2px;
}

.change-balance-form__field {
  &--api-key {
    margin-bottom: 10px;

    @media (min-width: $desktop-width) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &--api-key,
  &--secret-key {
    flex-grow: 1;
    max-width: 421px;
  }

  &--amount {
    flex-grow: 1;
    border-right: none;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &--currency {
    position: relative;
    flex-grow: 0;
    width: 120px;
  }

  &--triple {
    @media (min-width: $desktop-width) {
      width: 350px;
    }
  }
}

.change-balance-form__btn-wrapper {
  width: fit-content;
  display: flex;
  margin-top: 13px;
  margin-bottom: 16px;
  gap: 6px;

  @media (min-width: $desktop-width) {
    margin-bottom: 0px;
  }

  &--start {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.change-balance-change-icon-wrapper {
  display: flex;
  align-items: center;
  width: 18px;
  height: 24px;
  cursor: pointer;
}

.change-balance-checkbox-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  input {
    display: none;
  }

  .change-balance-checkbox-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      background-color: transparent;
    }
  }
}
.change-balance-checkbox-note {
  margin: 0px;
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 20px;
  }
}

.change-balance__fields-amount-inner-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 1px;
  width: 421px;

  @media (min-width: $tablet-width) {
    width: 441px;
  }

  @media (min-width: $desktop-width) {
    width: 360px;
  }
}

.change-balance__amount-wrapper {
  display: flex;
  width: 258px;

  @media (min-width: $tablet-width) {
    width: 288px;
  }

  @media (min-width: $desktop-width) {
    width: 250px;
  }
}
.change-balance__currency-field {
  position: relative;
  flex-grow: 0;
  width: 159px;

  @media (min-width: $tablet-width) {
    width: 143px;
  }

  @media (min-width: $desktop-width) {
    width: 100px;
  }
}
