.demo {
  position: relative;
}

.demo__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 36px;

  color: #f93232;
  background-color: #feebea;
  border-radius: 10px;

  @media (min-width: $tablet-width) {
    width: 89px;
    height: 44px;
  }
}

.demo__icon {
  width: 53px;
  height: 14px;
  line-height: 0;
}

.demo__hint {
  position: absolute;
  top: 4px;
  right: -29px;

  @media (min-width: $tablet-width) {
    top: 8px;
  }
}
