.pricing {
  @include main-padding-no-heading;
}

.pricing-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    align-items: center;
  }
}

.pricing__slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;

  &--amount {
    align-items: center;
  }
}

.pricing__slider-button-group {
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: flex-start;
  flex-direction: column;
}

.pricing__heading {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    position: relative;
  }
}

.pricing__sliders-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $tablet-width) {
    flex-direction: column;
  }

  @media (min-width: $desktop-width) {
    flex-direction: row;
  }
}

.pricing__amounts-container {
  @extend .pricing__sliders-container;
  @media (min-width: $tablet-width) {
    flex-direction: row;
  }

  @media (min-width: $desktop-width) {
    flex-direction: row;
  }
}

.pricing__img {
  width: 288px;
  height: 215px;
  background-size: 288px 215px;
  background-image: url("../../img/pricing/pricing-mobile.png");
  background-repeat: no-repeat;

  @media (min-width: $tablet-width) {
    margin-top: 0;
    width: 386px;
    height: 246px;
    background-image: url("../../img/pricing/pricing-tablet.png");
    background-size: 368px 246px;
  }

  @media (min-width: $desktop-width) {
    width: 486px;
    height: 344px;
    background-size: 486px 344px;
    background-image: url("../../img/pricing/pricing-desktop.png");
  }
}

.pricing__main-description-block {
  width: unset;

  @media (min-width: $tablet-width) {
    width: 330px;
  }

  @media (min-width: $desktop-width) {
    width: 408px;
  }
}
.pricing__main-description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 19px;
  color: #555555;
}

.pricing__btns {
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}

.pricing-slider {
  gap: 25px;
  padding: 20px;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    max-width: unset;
  }

  @media (min-width: $desktop-width) {
    padding: 20px 30px;
  }
}

.pricing-slider__heading {
  font-weight: 600;
  font-size: 16px;
  color: #1a1a1a;

  @media (min-width: $tablet-width) {
    font-size: 18px;
  }

  @media (min-width: $desktop-width) {
    font-size: 18px;
  }
}

.pricing__slider {
  width: 260px;
  height: 30px;

  @media (min-width: $tablet-width) {
    width: 678px;
  }

  @media (min-width: $desktop-width) {
    width: 464px;
  }
}

.pricing__thumb {
  position: absolute;
  top: -4px;

  width: 20px;
  height: 20px;

  background: #ffffff;
  border-radius: 100%;

  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  outline: none;

  cursor: pointer;

  @media (min-width: $tablet-width) {
    top: -7px;

    width: 30px;
    height: 30px;
  }
}

.pricing__track {
  background: #ffffff;

  height: 12px;
  border-radius: 6px;
  box-shadow: inset 0 0 3px 0.2px rgba(179, 178, 178, 0.74);

  @media (min-width: $tablet-width) {
    height: 16px;
    border-radius: 8px;
  }
}
.pricing__track-0 {
  background: #3b83f6f3;
  box-shadow: none;
}

.pricing__mark {
  position: absolute;
  top: 50%;
  left: 50%;

  font-weight: 600;
  font-size: 11px;
  line-height: 20px;

  transform: translate(-50%, -50%);

  @media (min-width: $tablet-width) {
    font-size: 16px;
  }
}

.pricing__value {
  position: absolute;
  top: 20px;
  left: 50%;

  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #555555;
  text-align: center;
  white-space: nowrap;

  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    top: 33px;
    font-size: 13px;
  }
}

.pricing__amount {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 10px;
  min-width: 282px;
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  color: #3b82f6;

  @media (min-width: $tablet-width) {
    font-size: 40px;
    line-height: 54px;
  }

  @media (min-width: $desktop-width) {
    font-size: 55px;
    line-height: 75px;
  }
}

.pricing__amount-heading {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3b82f6;
  text-align: center;

  @media (min-width: $tablet-width) {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: $desktop-width) {
    font-size: 22px;
    line-height: 25px;
  }
}

.pricing__list {
  @include list-reset;

  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}

.pricing__item {
  margin-bottom: 13px;
  padding: 16px;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(37, 99, 235, 0.15);
  border-radius: 5px;

  @media (min-width: $tablet-width) {
    flex-grow: 1;
    margin-bottom: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
}

.pricing__item:first-child {
  @media (min-width: $tablet-width) {
    width: 135px;
    padding-right: 9px;
  }

  @media (min-width: $desktop-width) {
    width: 190px;
    padding-right: 29px;
  }
}

.pricing__item:nth-child(2) {
  @media (min-width: $tablet-width) {
    width: 135px;
    padding-right: 9px;
    padding-left: 9px;

    border-left: 1px solid #cbd5e1;
  }

  @media (min-width: $desktop-width) {
    width: 190px;
    padding-right: 37px;
    padding-left: 39px;
  }
}

.pricing__item:nth-child(3) {
  @media (min-width: $tablet-width) {
    width: 141px;
    padding-left: 9px;

    border-left: 1px solid #cbd5e1;
  }

  @media (min-width: $desktop-width) {
    width: 182px;
    padding-right: 1px;
    padding-left: 39px;
  }
}

.pricing__item-heading {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2563eb;
  text-align: center;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 25px;
  }
}

.pricing__item:nth-child(3) .pricing__item-heading {
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    margin-bottom: 26px;
  }

  @media (min-width: $desktop-width) {
    margin-bottom: 29px;
  }
}

.pricing__note {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
  text-align: left;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 19px;
  }
}

.pricing__description {
  margin: 0;
  max-width: 190px;
  font-size: 12px;
  line-height: 16px;

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 19px;
  }
}

.pricing__item:nth-child(3) .pricing__description {
  margin-bottom: 8px;
}

.pricing__payments-list {
  @include list-reset;

  @media (min-width: $tablet-width) {
    margin-bottom: 16px;
  }
}

.pricing__payments-item {
  display: flex;
  align-items: center;
  gap: 5px;

  margin-bottom: 3px;

  font-size: 12px;
  line-height: 16px;

  @media (min-width: $tablet-width) {
    margin-bottom: 6px;
  }

  @media (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 19px;
  }
}

.pricing__icon {
  display: flex;
  width: 15px;
  height: 12px;
  margin: 2px 0.5px 0.5px 0;
  color: #555;

  @media (min-width: $desktop-width) {
    width: 19px;
    height: 14px;
  }
}

.pricing__btn {
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (min-width: $tablet-width) {
    margin-bottom: 20px;
  }
}

.pricing__fees-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }

  @media (min-width: $desktop-width) {
    flex-direction: row;
  }
}

.pricing__fees-item-container {
  background: #ffffff;
  box-shadow: 0px 1.77px 35.33px 0px #2563eb26;
  border-radius: 5px;

  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex: 1;

  @media (min-width: $tablet-width) {
    padding: 25px;
  }

  @media (min-width: $desktop-width) {
    padding: 25px 35px;
  }
}

.pricing__fees-item-header {
  font-weight: 600;
  font-size: 18px;
  margin: 0px;
}

.pricing__fees-item-number-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  flex: 1;

  @media (min-width: $tablet-width) {
    text-align: center;
    flex-direction: column;
  }

  @media (min-width: $desktop-width) {
    text-align: unset;
    flex-direction: row;
  }
}

.pricing__fees-item-number {
  font-size: 80px;
  font-weight: 700;
  line-height: 57px;
  color: #3b82f6;
}

.pricing__fees-item-number-note {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #828282;
  white-space: nowrap;

  @media (min-width: $tablet-width) {
    width: 91px;
    white-space: unset;
  }

  @media (min-width: $desktop-width) {
    width: 91px;
    white-space: unset;
  }
}

.pricing__fees-item-note {
  text-align: center;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 400;
  margin: 0px;
  line-height: 19px;
}
