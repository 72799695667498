.tooltip-error {
  position: relative;
}

.tooltip-error__btn {
  width: 16px;
  height: 16px;
  padding: 0;

  background-image: url("../../img/response/info-red.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tooltip-error__message {
  display: none;
  position: absolute;

  width: 150px;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #828282;

  border-radius: 5px;
  background-color: #fce9e5;
}

.tooltip-error__message::before {
  content: "";
  position: absolute;

  width: 17px;
  height: 7px;

  background-image: url("../../img/arrow/hint-arrow-up-pink.svg");
  background-position: center;
  background-size: 17px 7px;
}

.tooltip-error__btn:hover + .tooltip-error__message,
.tooltip-error__message:hover {
  display: block;
}

// number
.card-form__input-wrapper--number .tooltip-error__message {
  top: 21px;
  right: -15px;

  @media (min-width: $tablet-width) {
    top: 21px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.card-form__input-wrapper--number .tooltip-error__message::before {
  top: -4px;
  right: 14px;

  @media (min-width: $tablet-width) {
    top: -4px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

// expiry
.card-form__input-wrapper--expiry .tooltip-error__message {
  top: 21px;
  left: 50%;
  transform: translate(-50%, 0);
}

.card-form__input-wrapper--expiry .tooltip-error__message::before {
  top: -4px;
  left: 50%;
  transform: translate(-50%, 0);
}

// cvc
.card-form__input-wrapper--cvc .tooltip-error__message {
  top: 21px;
  right: -15px;

  @media (min-width: $tablet-width) {
    top: 21px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.card-form__input-wrapper--cvc .tooltip-error__message::before {
  top: -4px;
  right: 14px;

  @media (min-width: $tablet-width) {
    top: -4px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
