@mixin main-padding {
  padding: 5px 15px 20px;

  @media (min-width: $tablet-width) {
    padding: 93px 15px 50px;
  }
}

@mixin main-padding-no-heading {
  padding: 5px 15px 20px;

  @media (min-width: $tablet-width) {
    padding: 135px 15px 20px;
  }
}

@mixin doc-padding {
  padding: 11px 15px 20px;

  @media (min-width: $tablet-width) {
    padding: 144px 15px 0;
  }

  @media (min-width: $desktop-width) {
    padding: 137px 15px 0;
  }
}
