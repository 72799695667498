.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

html {
  height: 100%;

  @media (min-width: $desktop-width) {
    margin-left: calc(100vw - 100%);
  }
}

body {
  min-height: 100%;
  min-width: $mobile-width;
  margin: 0;
  padding: 0;

  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  color: #555555;

  &:after {
    content:
      // charts-bg
      url("../../img/charts-bg/chart-skeleton-mobile.svg")
      url("../../img/charts-bg/chart-skeleton-tablet.svg") //
      // cell-bg
      url("../../img/cell-bg/cell-bg-tablet.jpg")
      url("../../img/cell-bg/cell-bg-desktop.jpg")
      url("../../img/cell-bg/cell-calling-bg-tablet.jpg")
      url("../../img/cell-bg/cell-calling-bg-desktop.jpg") //
      // action
      url("../../img/action/copy-blue-dark.svg") //
      // sign
      url("../../img/sign/minus-red.svg") url("../../img/sign/plus-black.svg")
      url("../../img/sign/minus-black.svg") url("../../img/sign/plus-green.svg")
      // response
      url("../../img/response/info-red.svg") //
      // popup
      url("../../img/popup/okx-popup-desktop.jpg")
      url("../../img/popup/okx-popup-tablet.jpg") //
      // url("../../img/popup/bitget-popup-desktop.jpg")
      // url("../../img/popup/bitget-popup-tablet.jpg") //
;

    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }
}

#root {
  min-height: 100vh;
}

a {
  text-decoration: none;
}
