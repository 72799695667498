.referrals {
  @include doc-padding;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.referrals__heading {
  @include doc-heading;
  margin-bottom: 25px;
}

.referrals__emphasis {
  font-style: normal;
  color: #3b82f6;
}

.referrals__main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;

  &--no-intro {
    @media (min-width: $tablet-width) {
      margin-top: -8px;
    }

    @media (min-width: $desktop-width) {
      margin-top: -2px;
    }
  }
}

.referrals__table-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  @media (min-width: $desktop-width) {
    display: grid;
    grid-template-columns: 400px 370px;
    column-gap: 15px;
  }
}
