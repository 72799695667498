.payments-failed__intro {
  margin-top: 0;
  margin-bottom: 10px;

  font-size: 11px;
  line-height: 15px;
  color: #f93232;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.payments-failed__table {
  @include list-reset;
}

.payments-failed__row {
  display: grid;
  grid-template-columns:
    minmax(61px, 72px) minmax(135px, 3fr)
    minmax(62px, 1fr);
  align-items: center;
  height: 40px;

  @media (min-width: $tablet-width) {
    grid-template-columns: 72px minmax(289px, 3fr) minmax(80px, 1fr);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 112px 470px 170px;
  }
}

.payments-failed__row:not(:last-of-type) {
  border-bottom: 1px solid #cbd5e1;
}

.payments-failed__row-heading {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #828282;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }

  &--reason {
    padding-left: 5px;

    @media (min-width: $tablet-width) {
      padding-left: 16px;
    }
  }

  &--amount {
    text-align: right;
  }
}

.payments-failed__date {
  flex-shrink: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.payments-failed__date-full {
  margin: 0;
}

.payments-failed__date-time {
  margin: 0;
  color: #cbd5e1;
}

.payments-failed__reason {
  padding-left: 5px;

  @media (min-width: $tablet-width) {
    padding-left: 16px;
  }
}

.payments-failed__reason-text {
  margin: 0;
  align-self: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #555555;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }
}

.payments-failed__absolute {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #f93232;

  @media (min-width: $tablet-width) {
    font-size: 12px;
  }

  &--minus:before {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
  }

  &--minus:before {
    width: 5px;
    height: 5px;
    margin-top: 2px;
    background-image: url("../../img/sign/minus-red.svg");
    background-size: 5px 1.5px;
  }
}

.payments-failed__btns-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 18px;

  padding: 6px 0 16px;

  @media (min-width: $tablet-width) {
    column-gap: 10px;
  }
}

.payments-failed__btn {
  &--try-again {
    margin-left: auto;
  }
}
