.details-board {
  @include block-shadow;
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-rows: 62px min-content;
  padding-bottom: 30px;

  @media (min-width: $desktop-width) {
    grid-template-columns: 110px 670px;
    grid-template-rows: min-content;
    padding: 30px 0;
  }
}

.details-board__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 115px;
  height: 62px;
  padding-left: 15px;
  box-sizing: border-box;

  @media (min-width: $desktop-width) {
    height: 45px;
  }
}

.details-board__logo {
  display: block;

  @media (min-width: $desktop-width) {
    height: auto;
  }

  &--binance-spot {
    width: 80px;
  }

  &--binance-futures {
    width: 80px;
  }

  &--okx {
    width: 58px;
  }

  &--kucoin {
    width: 85px;
  }

  &--bing-x {
    width: 75px;
  }

  &--kraken {
    width: 85px;
  }

  &--dydx {
    width: 50px;
  }

  &--phemex {
    width: 80px;
  }

  &--bitget-futures {
    width: 75px;
  }

  &--bybit {
    width: 55px;
  }

  &--deribit {
    width: 85px;
  }
}

.details-board__block {
  @media (min-width: $desktop-width) {
    height: 45px;
  }
}

.details-board__total {
  padding: 5px 0;
  line-height: 0;
  font-size: 0;

  @media (min-width: $tablet-width) {
    justify-self: center;
    padding: 0;
  }

  @media (min-width: $desktop-width) {
    justify-self: flex-start;
  }
}

.details-board__amount-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.details-board__amount {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  white-space: nowrap;

  @media (min-width: $tablet-width) {
    margin-bottom: 3px;
    font-size: 17px;
  }
}

.details-board__total--initial .details-board__amount {
  color: #828282;
}

.details-board__total--your-changes .details-board__amount {
  color: #828282;
}

.details-board__total--up .details-board__amount {
  color: #2bbc8b;
}

.details-board__total--down .details-board__amount {
  color: #f93232;
}

.board-sign + .details-board__number {
  margin-left: 2px;
}

.details-board__text {
  display: block;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.details-board__sign {
  display: none;

  @media (min-width: $tablet-width) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9px;
    height: 24px;
    margin-left: -15px;
  }
}

.details-board__alert {
  position: absolute;
  top: 0;
  right: 20px;
}

.details-board__hint-alert {
  position: absolute;
  z-index: 10;
  right: -5px;
  top: -4px;
}

.detail-board__link {
  display: flex;
  align-items: center;
  padding: 0;

  font-size: 10px;
  line-height: 14px;
  color: #3b82f6;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    font-size: 12px;
    line-height: 16px;
  }
}

.detail-board__link-icon {
  display: flex;
  margin-left: 1px;
  margin-right: 6px;

  &--text {
    width: 10px;
    height: 11px;
  }
}
