.block-stopping {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: $tablet-width) {
    grid-template-columns:
      minmax(111px, 1fr) 9px minmax(111px, 1fr) 9px minmax(111px, 1fr)
      9px minmax(112px, 1fr);
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 80px 9px 89px 9px 100px 9px 121px 119px 135px;
  }
}

.block-stopping__total-wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
  min-width: 115px;
  margin-left: 20px;

  @media (min-width: $tablet-width) {
    display: contents;
  }
}

.block-stopping__total-wrapper:nth-of-type(1) {
  grid-column: 1/2;
  grid-row: 1/2;
}

.block-stopping__total-wrapper:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}

.block-stopping__note-wrapper {
  grid-column: 1/-1;
  justify-self: center;

  margin-top: 30px;

  @media (min-width: $desktop-width) {
    grid-column: 8/10;
    margin-top: 0;
  }
}

//
.block-stopping .details-board__total {
  @media (min-width: $tablet-width) {
    grid-row: 1/2;
  }

  &--initial {
    @media (min-width: $tablet-width) {
      grid-column: 1/2;
    }
  }

  &--current {
    @media (min-width: $tablet-width) {
      grid-column: 7/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 6/6;
      margin-left: 5px;
    }
  }

  &--your-changes {
    @media (min-width: $tablet-width) {
      grid-column: 3/4;
    }

    @media (min-width: $desktop-width) {
      margin-left: 5px;
    }
  }

  &--from-start {
    @media (min-width: $tablet-width) {
      grid-column: 5/7;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/6;
      margin-left: 5px;
    }
  }

  &--last-month {
    @media (min-width: $tablet-width) {
      grid-column: 7/8;
    }

    @media (min-width: $desktop-width) {
      grid-column: 5/7;
      margin-left: 10px;
    }
  }
}

.block-stopping .details-board__sign {
  &--calc {
    @media (min-width: $tablet-width) {
      grid-column: 4/5;
    }
  }

  &--equal {
    @media (min-width: $tablet-width) {
      grid-column: 6;
      grid-row: 1;
    }
  }
  &--your-changes-calc {
    @media (min-width: $tablet-width) {
      grid-column: 2/3;
      grid-row: 1;
    }
  }
}
