.chart {
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #828282;
  font-size: 10px;

  @media (min-width: $tablet-width) {
    font-size: 10px;
  }

  &--overview {
    height: 136px;

    @media (min-width: $desktop-width) {
      z-index: -1;
      position: absolute;
      width: 415px;
      left: 0;
      top: 0;
      height: 75px;
    }
  }

  &--table {
    height: 80px;
  }

  &--coin {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 286px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }

  &--coins {
    height: 40px;

    @media (min-width: $desktop-width) {
      height: 22px;
    }
  }

  &--demo {
    height: 156px;

    @media (min-width: $tablet-width) {
      height: 201px;
    }

    @media (min-width: $desktop-width) {
      height: 288px;
    }
  }

  &--faq {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 286px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }

  &--details {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 286px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }

  &--calc {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 200px;
    }

    @media (min-width: $desktop-width) {
      height: 200px;
    }
  }

  &--relative {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 220px;
    }

    @media (min-width: $desktop-width) {
      height: 200px;
    }
  }

  &--binance-landing-binance-spot,
  &--binance-landing-binance-futures {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 220px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }

  &--binance-landing-binance-spot-binance-futures {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 220px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }

  &--empty {
    height: 200px;

    @media (min-width: $tablet-width) {
      height: 286px;
      margin-bottom: 10px;
    }

    @media (min-width: $desktop-width) {
      height: 270px;
    }
  }
}

.chart__block {
  position: relative;
  height: calc(100% - 40px);
  width: calc(100% - 90px);
  margin: 10px 45px 30px 45px;

  background-color: rgba(252, 252, 252, 0.868);
  border: dashed 1.5px lightgray;
}

.chart__start {
  position: absolute;
  width: 40px;
  left: -50px;
  top: 50%;

  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: right;
  color: #828282;

  transform: translate(0, -50%);

  @media (min-width: $tablet-width) {
    font-size: 10px;
  }
}

.chart__info {
  position: absolute;
  left: 50%;
  bottom: 10px;

  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(100% - 20px);
  max-width: 350px;
  margin: 0;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #828282;
  letter-spacing: 0;

  transform: translate(-50%, 0);

  @media (min-width: $tablet-width) {
    bottom: 20px;
    max-width: unset;
    width: 390px;

    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: $desktop-width) {
    width: 370px;
  }
}

.chart__icon {
  position: absolute;
  bottom: 0;
  left: 0;

  height: 100%;
}
